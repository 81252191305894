var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.toggleUpdate)?_c('div',{staticClass:"box",class:{
        operatorAnd: _vm.node.getCaption() === _vm.operatorTypes[0],
        operatorOr: _vm.node.getCaption() === _vm.operatorTypes[1],
        dragOver: _vm.getCurrent() === _vm.node.getID() || _vm.contextMenu,
     },on:{"drop":(evt) => _vm.onDrop(evt, _vm.node),"dragover":function($event){$event.preventDefault();},"dragenter":(evt) => _vm.onDragEnter(evt, _vm.node),"contextmenu":_vm.showContextMenu}},[_vm._l((_vm.node.getChildNodes()),function(n,index){return [(n.isOperator())?_c('NodeElement',{key:n.getID(),attrs:{"tree":_vm.tree,"node":n}}):_vm._e(),(!n.isOperator())?_c('div',{key:n.getID() + '_filter',staticClass:"filter",class:{
                dragOver: _vm.getCurrent() === n.getID(),
             },on:{"drop":(evt) => _vm.onDrop(evt, n),"dragenter":(evt) => _vm.onDragEnter(evt, n),"contextmenu":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(n.getCaption())+" ")]):_vm._e(),(index < _vm.node.getChildNodes().length-1)?_c('div',{key:n.getID() + '_operator',staticClass:"operatorCaption"},[_vm._v(" "+_vm._s(_vm.node.getCaption())+" ")]):_vm._e()]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.contextMenu),expression:"contextMenu"}],ref:"contextMenu",staticClass:"contextMenu",on:{"mouseleave":_vm.closeContextMenu}},[_c('span',[_vm._v("Aktionen:")]),_c('ul',[_c('li',{on:{"click":_vm.changeOperatorType}},[_vm._v("Change Operator Type")]),_c('li',{on:{"click":_vm.deleteNode}},[_vm._v("Delete Node")])])])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }