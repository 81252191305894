<template>
    <div class="mainDiv" @dragenter="onDragEnter" @mouseout="onDragEnter">
        <div>
            <div v-for="operator in operatorTypes" :key="operator"
                 class="operator"
                 draggable="true"
                 @dragstart="startDrag($event, operator)"
                 @click="createTree(operator)"
            >
                {{operator}}
            </div>
            <hr>
        </div>
        <div class="rootDiv" :class="{dragOver: dragOver}">
            <NodeElement v-if="tree" :node="tree.getRoot()" :tree="tree"></NodeElement>
        </div>
        <hr>
        <button class="button" @click="clearTree">Search!</button>
    </div>
</template>

<script>
import {Tree} from "@/components/MainFilterSelectors/LogicTree";
import NodeElement from "@/components/MainFilterSelectors/NodeElement";
import {mapGetters, mapMutations} from "vuex";

export default {
    name: "MainFilter",
    components: {NodeElement},
    data: () => ({
        dragOver: false,
        tree: null,
        toggleUpdate: true,
    }),
    computed: {
        updater() {
            return this.getUpdater();
        },
        operatorTypes(){
            return Tree.types;
        },
        ...mapGetters({
            isTreeIsEmpty: 'mainFilterStore/isEmptyTree',
        })
    },
    watch: {
        updater() {
            this.toggleUpdate = false;
            this.toggleUpdate = true;
        },
        isTreeIsEmpty(value){
            if(value) {
                this.tree = null;
            }
        }
    },
    methods: {
        ...mapGetters({
            getUpdater: 'mainFilterStore/getUpdater',
        }),
        ...mapMutations({
            setUpdater: 'mainFilterStore/setUpdater',
            setCurrent: 'mainFilterStore/setCurrent',
            setTreeIsEmpty: "mainFilterStore/setTreeIsEmpty",
        }),
        startDrag(evt, operator) {
            const data = {type: "operator", value: operator};
            evt.dataTransfer.setData("tree", JSON.stringify(data));
        },
        onDragEnter(evt){
            this.setCurrent(null);
            evt.stopPropagation();
        },
        createTree(operator){
            if(!this.tree){
                this.tree = new Tree(operator);
                this.setTreeIsEmpty(false);
            }
        },
        clearTree(){
            this.tree = null;
        }
    },
}
</script>

<style scoped>
hr{
    margin-top: 5px;
}
.mainDiv{
    width: 100%;
    min-width: 400px;
    padding: 5px;
    max-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.operator{
    color: black;
    background-color: lightgray;
    border: 1px solid black;
    padding: 6px 12px 4px 12px;
    display: inline-block;
    margin: 4px 2px;
    font-family: "Univers LT Std", Sans-serif, serif;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: grab;
}
.operator:hover{
    outline: 2px solid orangered;
}
.rootDiv{
    background-color: white;
    border: 1px solid black;
    width: 100%;
    min-height: 100px;
    overflow-y: auto;
}
.dragOver {
    outline: 3px solid red;
    background: repeating-linear-gradient(45deg, #fb8c00, #fb8c00 10px, #e53935 10px, #e53935 20px);
}
</style>