<template>
    <div class="infoPanel" style="padding: 5px">
        <span class="typeSpan">Selektierte Koordinaten:</span>
        <span class="typeSpan">(lat: {{clickedCoords.lat}}, long: {{clickedCoords.long}})</span>
        <hr>
        <div ref="content" class="content">
            <div v-if="dwdFeatures.length>0">
                <button class="collapsible" @click="collapsibleClick" style="font-size: larger">Counties ({{dwdFeatures.length}})</button>
                <div class="collapsibleContent">
                    <template v-for="(f, index) in dwdFeatures">
                        <div :key="index" class="containerDiv" style="padding-left: 15px">
                            <button class="collapsible" @click="collapsibleClick">{{f.get('name')}}</button>
                            <div class="collapsibleContent">
                                <table>
                                    <tr>
                                        <td>WarnCellID:</td>
                                        <td>{{f.get('WarnCellID')}}</td>
                                    </tr>
                                    <tr>
                                        <td>WarnCenter:</td>
                                        <td>{{f.get('WarnCenter')}}</td>
                                    </tr>
                                </table>
                                <div v-if="f.get('events')">
                                    <template v-for="(type, index) in Object.keys(f.get('events'))">
                                        <div :key="index">
                                            <button class="collapsible" @click="collapsibleClick">{{type}} ({{f.get('events')[type].length}})</button>
                                            <div class="collapsibleContent">
                                                <template v-for="(entry, i) in f.get('events')[type]">
                                                    <div :key="i">
                                                        <div class="collapsible" @click="collapsibleClick">
                                                            <div class="levelDiv" :class="{level0: entry[1] === 0, level1: entry[1] === 1, level2: entry[1] === 2, level3: entry[1] === 3, level4: entry[1] === 4}"></div>
                                                            <span style="padding-left: 5px;">Limit: </span>
                                                            <span>{{getLimitFromEventAndLevel(type)}}</span>
                                                        </div>
                                                        <div class="collapsibleContent">
                                                            <table>
                                                                <tr>
                                                                    <td>General Type:</td>
                                                                    <td>{{entry[6]}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Time:</td>
                                                                    <td>{{entry[0]}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Level:</td>
                                                                    <td>{{entry[1]}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Description:</td>
                                                                    <td>{{entry[2]}}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Instruction:</td>
                                                                    <td>{{entry[3]}}</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <button class="collapsible" @click="collapsibleClick($event); loadIntersectionCounter(f.get('___id'))">Überlappende Features</button>
                                <div class="collapsibleContent">
                                    <FeatureLayerPanel v-if="extraFeatureMap.intersections[f.get('___id')]" :intersections="extraFeatureMap.intersections[f.get('___id')]" :parentFeatureId="f.get('___id')">
                                        <span>Keine Features vorhanden.</span>
                                    </FeatureLayerPanel>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <FeatureLayerPanel ref="mainFeaturePanel"></FeatureLayerPanel>
        </div>
    </div>
</template>

<script>
import {getClickedCoordsObj} from "@/modules/MapData";
import FeatureLayerPanel from "@/components/FeatureLayerPanel";
import * as CypherQueries from "@/modules/CypherQueries";
import Vue from "vue";

export default {
    name: "InfoPanel",
    components: {FeatureLayerPanel},
    data: () => ({
        featureMap: null,
        dwdFeatures: [],
        latCoord: "",
        longCoord: "",
        clickedCoords: getClickedCoordsObj(),
        extraFeatureMap: {
            // TODO: Duplikat in FeatureLayerPanel!
            intersections: {},
        },
    }),
    methods: {
        displayFeatures(featureMap){
            // TODO: Name der Methode passend?
            // TODO: features immer undefined?
            //const old = this.dwdFeatures;
            //this.dwdFeatures = [];

            /*
            if(!featureMap){
                // update on change, but same features
                featureMap = old;
            }*/

            this.dwdFeatures = featureMap['dwd'] ? featureMap['dwd'].features : [];

            this.featureMap = featureMap;

            this.extraFeatureMap.intersections = {};
            this.$refs.mainFeaturePanel.setData(this.featureMap);


        },
        collapsibleClick(e){
            const el = e.target.classList.contains('collapsible') ? e.target : e.target.parentElement;
            el.classList.toggle("active");
            const content = el.nextElementSibling;
            if(content.style.display === "block"){
                content.style.display = "none";
            } else {
                content.style.display = "block";
            }
        },
        getLimitFromEventAndLevel(event){
            const e = event.toUpperCase();

            if(e === "Windböen".toUpperCase()){
                return "> 50km/h";
            } else if(e === "Sturmböen".toUpperCase()){
                return "> 65km/h";
            } else if(e === "Schwere Sturmböen".toUpperCase()){
                return "> 90km/h";
            } else if(e === "Orkanartige Böen".toUpperCase()){
                return "> 105km/h";
            } else if(e === "Orkanböen".toUpperCase()){
                return "> 120km/h";
            } else if(e === "Extreme Orkanböen".toUpperCase()){
                return "> 140km/h";
            } else if(e === "Starkregen".toUpperCase()){
                return "> 15l/m² in 1h";
            } else if(e === "Heftiger Starkregen".toUpperCase()){
                return "> 25l/m² in 1h";
            } else if(e === "Extrem heftiger Starkregen".toUpperCase()){
                return "> 40l/m² in 1h";
            } else if(e === "Dauerregen".toUpperCase()){
                return "> 25l/m² in 12h";
            } else if(e === "Ergiebiger Dauerregen".toUpperCase()){
                return "> 40l/m² in 12h";
            } else if(e === "Extrem ergiebiger Dauerregen".toUpperCase()){
                return "> 70l/m² in 12h";
            } else if(e === "Leichter Schneefall".toUpperCase()){
                return "bis 5cm in 6h";
            } else if(e === "Schneefall".toUpperCase()){
                return "> 5cm in 6h";
            } else if(e === "Starker Schneefall".toUpperCase()){
                return "> 10cm in 6h";
            } else if(e === "Extrem starker Schneefall".toUpperCase()){
                return "> 20cm in 6h";
            } else if(e === "Schneeverwehung".toUpperCase()){
                return "5-10cm Neuschnee + 6-7 Bft";
            } else if(e === "Starke Schneeverwehung".toUpperCase()){
                return "10cm Neuschnee + 8 Bft";
            } else if(e === "Extrem starke Schneeverwehung".toUpperCase()){
                return "25cm Neuschnee + 8 Bft";
            } else if(e === "Frost".toUpperCase()){
                return "< 0°C";
            } else if(e === "Strenger Frost".toUpperCase()){
                return "< -10°C";
            } else if(e === "Nebel".toUpperCase()){
                return "Sichtweite < 150m";
            } else if(e === "Tauwetter".toUpperCase()){
                return "> 25l/m² in 12h";
            } else if(e === "Starkes Tauwetter".toUpperCase()){
                return "> 40l/m² in 12h";
            } else if(e === "Starke Wärmebelastung".toUpperCase() || e === "Starke Hitze".toLocaleUpperCase()){
                return "> 32°C";
            } else if(e === "Extreme Wärmebelastung".toUpperCase() || e === "Extreme Hitze".toLocaleUpperCase()){
                return "> 38°C";
            } else if(e === "erhöhte UV-Intensität".toUpperCase() || e === "UV"){
                return "UV-Index > 5.00";
            }
            // TODO: Gewitter
            return "<nicht angegeben>";
        },
        loadIntersectionCounter(id){
            // TODO: Duplikat in FeatureLayerPanel!
            if(!this.extraFeatureMap.intersections[id]) {
                CypherQueries.loadIntersectionsCounterForFeature(id).then(map => {
                    // TODO: Wird in jeder Rekursionstufe von FeatureLayerPanel geladen, besser store dafür!
                    Vue.set(this.extraFeatureMap.intersections, id, map);
                });
            }
        },
    }
}
</script>

<style scoped>
    .infoPanel{
        font-family: var(--font-family);
        color: var(--color-primary);
        font-weight: bold;
        box-sizing: border-box;
        background-color: #880e4f;
        height: 100%;
        /* TODO: dynamisch! */
        /* max-height: 850px; */
        overflow: auto;
    }
    .content {
        color: black;
        font-weight: normal;
        padding-left: 5px;
    }
    .containerDiv {

    }
    .typeSpan {
        margin-left: auto;
        margin-right: auto;
        width: max-content;
        display: block;
        color: var(--color-text)
    }
    .levelDiv {
        width: 15px;
        height: 15px;
        background-color: red;
        display: inline-block;
        border: 1px solid black;
        box-sizing: border-box;
    }
    .levelDiv:hover{
        border: 2px solid var(--color-secondary);
    }
    .level4 {
        background-color: #880e4f;
    }
    .level3{
        background-color: #e53935;
    }
    .level2 {
        background-color: #fb8c00;
    }
    .level1{
        background-color: #ffeb3b;
    }
    .level0 {
        background-color: gray;
    }
</style>