<template>
    <div>
        <div v-show="namesLoaded">
            <div class="selectParent">
                <div class="personsDiv">
                    <p>Selected Persons:</p>
                    <select size="5" multiple @keydown="removePersonFromList">
                        <option v-for="value in selectedPersons.list" :value="value" :key="value">
                            {{value}}
                        </option>
                    </select>
                    <br>
                    <input type="text" id="autoCompletePersons">
                </div>
                <div class="companiesDiv">
                    <p>Selected Companies:</p>
                    <select size="5" multiple @keydown="removeCompaniesFromList">
                        <option v-for="value in selectedCompanies.list" :value="value" :key="value">
                            {{value}}
                        </option>
                    </select>
                    <br>
                    <input type="text" id="autoCompleteCompanies">
                </div>
            </div>
            <br>
            <LoadingButton @onLoadingButtonClicked="searchDocuments" :is-loading="isLoading" caption="Search for Documents!"/>
            <br>
            <div>
                <p>Found {{getDocumentsWithPersonsAndCompanies.dIds.size}} entries!</p>
            </div>
            <br>
        </div>
        <div v-show="!namesLoaded">
            <br>
            <button class="button" @click="loadData">Load Data!</button>
            <br>
            <br>
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import LoadingButton from "@/components/LoadingButton";

export default {
    name: "DocumentFilterNames",
    components: {
        LoadingButton
    },
    data: () => ({
        autoCompleteElements: {
            persons: null,
            companies: null,
        },
        selectedPersons: {
            list: [],
            set: new Set(), // Set nicht von Vue2 unterstützt, daher Umweg über Array!
        },
        selectedCompanies: {
            list: [],
            set: new Set(),
        },
        isLoading: false, // TODO: use it!
    }),
    mounted() {

    },
    computed: {
        ...mapGetters('neo4jStore', [
            'getPersonsAndCompanies',
            'getDocumentsWithPersonsAndCompanies'
        ]),
        namesLoaded(){
            if (this.getPersonsAndCompanies.persons.length > 0 && this.getPersonsAndCompanies.companies.length > 0){
                this.initAutoComplete(this.autoCompleteElements.persons, this.selectedPersons, 'autoCompletePersons', this.getPersonsAndCompanies.persons);
                this.initAutoComplete(this.autoCompleteElements.companies, this.selectedCompanies, 'autoCompleteCompanies', this.getPersonsAndCompanies.companies);
                return true;
            }
            return false;
        }
    },
    methods: {
        ...mapActions('neo4jStore', [
            'loadPersonsAndCompanies',
            'loadDocumentIdsWithPersonsAndCompanies'
        ]),
        initAutoComplete(element, dataObj, tag, values){
            if (element !== null){
                element.unInit();
            }
            element = new this.$autoComplete({
                selector: "#" + tag,
                placeHolder: "Search for Names...",
                data: {
                    src: values,
                },
                resultItem: {
                    highlight: true,
                },
                resultsList: {
                    element: (list, data) => {
                        const info = document.createElement("p");
                        if (data.results.length > 0) {
                            info.innerHTML = `Displaying <strong>${data.results.length}</strong> out of <strong>${data.matches.length}</strong> results.`;
                        } else {
                            info.innerHTML = `Found <strong>0</strong> matching results for <strong>"${data.query}"</strong>.`;
                        }
                        list.prepend(info);
                    },
                    noResults: true,
                    maxResults: 1000,
                    tabSelect: true
                },
                events: {
                    input: {
                        focus: function(){
                            if (element.input.value.length) element.start();
                        }.bind(this),
                    },
                },
            });
            /*
            this.autoCompleteEl.input.addEventListener("results", event => {
                event.detail.results.sort( (a,b) => {
                    return a.value.localeCompare(b.value);
                });
            });
            */

            element.input.addEventListener("selection", function (event) {
                const value = event.detail.selection.value;
                dataObj.set.add(value);
                dataObj.list = Array.from(dataObj.set);
                element.start();
            }.bind(this));
        },
        loadData(){
            this.loadPersonsAndCompanies();
        },
        removePersonFromList(event){
            if(event.key === "Delete"){
                const options = event.target.selectedOptions;
                for (let i=0; i<options.length; i++){
                    this.selectedPersons.set.delete(options[i].value);
                }
                this.selectedPersons.list = Array.from(this.selectedPersons.set);
            }
        },
        removeCompaniesFromList(event){
            if(event.key === "Delete"){
                const options = event.target.selectedOptions;
                for (let i=0; i<options.length; i++){
                    this.selectedCompanies.set.delete(options[i].value);
                }
                this.selectedCompanies.list = Array.from(this.selectedCompanies.set);
            }
        },
        searchDocuments(){
            this.loadDocumentIdsWithPersonsAndCompanies({persons: this.selectedPersons.list, companies: this.selectedCompanies.list});
        },
    },
}
</script>

<style scoped>
.selectParent{
    display: flex;
}
.personsDiv, .companiesDiv {
    flex: 1 1 0px;
    padding-left: 5px;
    padding-right: 5px;
}
.companiesDiv{
    border-left: 1px solid var(--color-text);
}
select{
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 130px;
}
</style>