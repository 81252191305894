import { render, staticRenderFns } from "./NodeElement.vue?vue&type=template&id=24fd2130&scoped=true&"
import script from "./NodeElement.vue?vue&type=script&lang=js&"
export * from "./NodeElement.vue?vue&type=script&lang=js&"
import style0 from "./NodeElement.vue?vue&type=style&index=0&id=24fd2130&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24fd2130",
  null
  
)

export default component.exports