import mainFilterStore from "@/store/mainFilterStore";

class Node {

    static #nodeIdCounter = 0;
    #isOperator = false;
    #caption = "";
    #parentNode = null;
    #childNodes = [];
    #nodeID = 0;

    constructor(isOperator, caption) {
        this.#isOperator = isOperator;
        this.#caption = caption;
        if(caption === "?"){
            this.#caption = Node.#nodeIdCounter;
        }
        this.#nodeID = Node.#nodeIdCounter++;
    }
    getID(){
        return this.#nodeID;
    }
    getCaption(){
        return this.#caption;
    }
    isOperator(){
        return this.#isOperator;
    }
    addChildNode(node){
        this.#childNodes.push(node);
        node.setParent(this);
    }
    removeChildNode(node){
        const index = this.#childNodes.indexOf(node);
        if(index >= 0){
            node.setParent(null);
            this.#childNodes.splice(index, 1);
        }
    }
    getChildNodes(){
        return this.#childNodes;
    }
    setParent(node){
        this.#parentNode = node;
    }
    getParent(){
        return this.#parentNode;
    }
    replaceChild(node, rep){
        const index = this.#childNodes.indexOf(node);

        if(index >= 0){
            this.#childNodes[index].setParent(null);
            rep.setParent(this);
            this.#childNodes.splice(index, 1, rep);
        }
    }
    setCaption(caption){
        this.#caption = caption;
    }
    printAll(){
        if(!this.isOperator()){
            return;
        }
        console.log(this.#caption + ":");
        this.#childNodes.forEach(n => {
            console.log("-", n.getCaption());
        });
        this.#childNodes.forEach(n => {
           n.printAll();
        });
    }
}

class Tree{
    #root = null;

    constructor(operator) {
        this.#root = new Node(true, operator);
        const node1 = new Node(false, "?");
        const node2 = new Node(false, "?");
        this.#root.addChildNode(node1);
        this.#root.addChildNode(node2);
        /*
        const root = new Node(true, "ODER");
        this.#root = root;

        const und1 = new Node(true, "UND");
        const und2 = new Node(true, "UND");
        root.addChildNode(und1);
        root.addChildNode(und2);

        const oder = new Node(true, "ODER");

        const a = new Node(false, "A");
        const b = new Node(false, "B");
        const c = new Node(false, "C");
        const d = new Node(false, "D");
        const e = new Node(false, "E");
        const f = new Node(false, "F");

        und1.addChildNode(d);
        und1.addChildNode(e);

        und2.addChildNode(a);
        und2.addChildNode(oder);
        und2.addChildNode(c);

        oder.addChildNode(b);
        oder.addChildNode(f);

        //root.printAll();
         */
    }
    getRoot(){
        return this.#root;
    }
    static get types(){
        return ["UND", "ODER"];
    }
    addChildOperator(node, operator){
        const getters = mainFilterStore.getters;
        const mutations = mainFilterStore.mutations;
        const state = mainFilterStore.state;

    }
    deleteNode(node){
        const parent = node.getParent();
        if(parent){
            parent.removeChildNode(node);
        } else {
            this.#root = null;
            return true;
        }
        return false;
        // TODO: only one filter left?
    }
    addOperator(operator, node){
        // Fall 1: node === operator
        if(node.isOperator()) {
            if(node.getCaption() === operator){
                const node2 = new Node(false, "?");
                node.addChildNode(node2);
            } else {
                const op = new Node(true, operator);
                const node2 = new Node(false, "?");
                const node3 = new Node(false, "?");
                node.addChildNode(op);
                op.addChildNode(node2);
                op.addChildNode(node3);
            }
        } else {
            // Fall 2: node === filter
            // 1. parent holen
            const parent = node.getParent();
            // 1.1 Operator und Parent sind gleich?
            if(operator === parent.getCaption()){
                // Keinen neuen Operator anlegen, sondern ein neues child zum parent
                const node2 = new Node(false, "?");
                parent.addChildNode(node2);
            } else {
                // 2. Operator anlegen und mit child im Tree tauschen
                const op = new Node(true, operator);
                parent.replaceChild(node, op);
                // 4. node als Child von Operator
                op.addChildNode(node);
                // 5. node? als Child von Operator anlegen
                const node2 = new Node(false, "?");
                op.addChildNode(node2);
            }
        }
    }
    toggleOperatorType(node){
        const childNodes = node.getChildNodes();
        const parent = node.getParent();
        if(!parent){
            if (node.getCaption() === Tree.types[0]){
                node.setCaption(Tree.types[1]);
            } else {
                node.setCaption(Tree.types[0]);
            }
            const toRemove = [];
            childNodes.forEach(child => {
                if(child.isOperator() && child.getCaption() === node.getCaption()){
                    const childChildNodes = child.getChildNodes();
                    //node.removeChildNode(child);
                    toRemove.push(child);
                    childChildNodes.forEach(cChild => {
                        node.addChildNode(cChild);
                    });
                }
            });
            toRemove.forEach(rem => {
                node.removeChildNode(rem);
            });
        } else {
            parent.removeChildNode(node);
            childNodes.forEach(child => {
                parent.addChildNode(child); // TODO: Am besten an diese Position einfügen, wo vorher node war!
                if (child.isOperator() && child.getCaption() === parent.getCaption()) {
                    this.toggleOperatorType(child);
                }
            });
        }
    }
}

export {
    Tree,
    Node,
}