<template>
    <div class="screen" :class="{'fadeout': !isLoading}">
        <div class="login">
            <div class="header">
                <h1>Sardine GIS</h1>
            </div>
            <div v-if="!login">
                <p class="credentialsMSG glow" ref="msg">{{msg}}</p>
                <form class="loginForm">
                    <input type="text" ref="username" name="user" placeholder="Username" value="" required v-on:keyup.enter="setLogin">
                    <input type="password" ref="password" name="password" placeholder="Password" value="" required v-on:keyup.enter="setLogin">
                </form>
                <br>
                <button class="button loginButton" id="connectToDatabase" @click="setLogin">Login</button>
            </div>
            <div v-if="login" class="loadingDiv">
                <div class="loadingIcon">
                    <img  src="favicon.ico">
                    <div :class="{'loadingAnimation': isLoading}"></div>
                </div>
                <div class="updateText">
                    <span v-for="text in updateText" :key="text">
                        <span>{{text}}</span>
                        <br>
                    </span>
                    <div class="loadingDotsDiv">
                        <div class="loadingDot1"></div>
                        <div class="loadingDot2"></div>
                        <div class="loadingDot3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {test as Neo4jControllerTest} from "@/modules/Neo4jController";
import {checkConnectivity as Neo4jControllerCon} from "@/modules/Neo4jController";
import * as CypherQueries from "@/modules/CypherQueries";
import {mapMutations} from "vuex";

export default {
    name: "LoginScreen",
    data: () => ({
        //isLoading: true,
        login: false,
        msg: "Please enter your Credentials",
    }),
    props: {
        isLoading: {
            required: true,
            type: Boolean,
        },
        updateText: {
            required: true,
            type: Array,
        }
    },
    mounted(){

    },
    methods: {
        ...mapMutations({
            setLoading: "neo4jStore/setLoading",
        }),
        setLogin(){
            this.$refs.msg.classList.remove("glow");
            Neo4jControllerTest(this.$refs.username.value, this.$refs.password.value);
            Neo4jControllerCon().then(res => {
                if(res){
                    this.login = true;
                    this.$refs.username.value = "";
                    this.$refs.password.value = "";
                    CypherQueries.loadLayerNames().then(() => {
                        CypherQueries.loadLayerData().then(() => {
                            CypherQueries.loadRestrictionsNames("Verbot").then(() => {
                                CypherQueries.loadRestrictionsLayerData("Verbot").then(() => {
                                    CypherQueries.loadRestrictionsNames("Gebot").then(() => {
                                        CypherQueries.loadRestrictionsLayerData("Gebot").then(() => {
                                            CypherQueries.loadCountyRestrictionsIds().then(() => {
                                                //this.isLoading = false;
                                                this.setLoading(false);
                                            });
                                        });
                                    });
                                });
                            });
                            //Senergy.createConnection("", "", (e) => console.log(e));
                        });
                    });
                } else {
                    this.msg = "Invalid Credentials, please try again.";
                    this.$refs.password.value = "";
                    this.$refs.msg.classList.add("glow");

                }
            });
        },
    },
}
</script>

<style scoped>
    .login {
        align-self: center;
        background-color: white;
        background-image: linear-gradient(
            40deg,
            hsl(240deg 100% 20%) 0%,
            hsl(225deg 100% 24%) 0%,
            hsl(219deg 100% 26%) 1%,
            hsl(214deg 100% 27%) 3%,
            hsl(208deg 100% 25%) 8%,
            hsl(198deg 100% 22%) 17%,
            hsl(184deg 100% 18%) 43%,
            hsl(166deg 100% 18%) 89%,
            hsl(150deg 100% 20%) 98%,
            hsl(137deg 100% 21%) 100%
        );
        border: 1px solid white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 6px;
        padding: 5px;
        min-width: 360px;
    }
    .login input {
        border: 1px solid gray;
    }
    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        margin: 16px;
    }
    .credentialsMSG {
        text-align: center;
        color:white;
        font-size: smaller;
        margin: 16px;
    }
    .loginForm{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .loginForm input {
        border: none;
        background: none;
        box-shadow: 0px 2px 0px 0px white;
        width: 80%;
        color: white;
        font-size: 1em;
        outline: none;
        margin-bottom: 15px;
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
    }
    .loginForm input::placeholder{
        color: darkgrey;
    }
    .loginButton {
        margin-bottom: 15px;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        border: 1px solid white;
    }
    .loadingDiv {
        display: flex;
        flex-direction: column;
    }
    .screen{
        background-color: hsl(223deg 66% 15% / 90%);
        position: absolute;
        height: 100vh;
        width: 100vw;
        z-index: 1000000;
        /*opacity: 0.8;*/
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 2px solid black;
        box-sizing: border-box;
    }

    .updateText{
        color: white;
        align-self: center;
        margin-top: 15px;
        text-align: center;
    }

    .fadeout{
        animation: fadeout 1s linear forwards;
    }

    .loadingIcon{
        align-self: center;
        display: grid;
    }

    .loadingIcon > * {
        grid-row: 1;
        grid-column: 1;
    }

    .loadingAnimation {
        background: linear-gradient(to bottom, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0.4) 100%);
        animation: loadingIcon 3s ease-in infinite;
    }

    @keyframes fadeout {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            visibility: hidden;
        }
    }

    @keyframes loadingIcon {
        0% {
            height: 100%;
        }
        100% {
            height: 0;
        }
    }

    .loadingDotsDiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 5px;
        margin-bottom: 15px;
        margin-top: 5px;
    }
    .loadingDotsDiv div {
        background-color: white;
        width: 2px;
        height: 2px;
        border: none;
    }
    .loadingDot1 {
        animation: loadingDots 2s linear infinite;
        animation-delay: .1s;
    }

    .loadingDot2 {
        animation: loadingDots 2s linear infinite;
        animation-delay: .3s;
    }

    .loadingDot3 {
        animation: loadingDots 2s linear infinite;
        animation-delay: .5s;
    }

    @keyframes loadingDots {
        0% {
            opacity: .2;
        }
        20% {
            opacity: 1;
        }
        100% {
            opacity: .2;
        }
    }

    .glow {
        animation: glow 1.3s linear forwards;
    }
    @keyframes glow {
        40% {
            text-shadow: 0 0 10px #000000;
            color: darkgrey;
        }
    }
</style>