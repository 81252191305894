<template>
    <div class="mainDiv">
        <div v-show="isConnectionReady">
            <div class="content-header">
                <div class="icons-left">
                    <img class="filter-img gap" :class="{'activeFilter-img' : filterStates['frost']}" width="50" height="50" src="@/assets/icons/warn_icons_frost.png" title="Frost" alt="filter frost" @click="setFilter('frost')">
                    <img class="filter-img" :class="{'activeFilter-img' : filterStates['glatteis']}" width="50" height="50" src="@/assets/icons/warn_icons_glatteis.png" title="Glatteis" alt="filter glatteis" @click="setFilter('glatteis')">
                    <br>
                    <img class="filter-img gap" :class="{'activeFilter-img' : filterStates['schnee']}" width="50" height="50" src="@/assets/icons/warn_icons_schnee.png" title="Schnee" alt="filter schnee" @click="setFilter('schnee')">
                    <img class="filter-img" :class="{'activeFilter-img' : filterStates['tauwetter']}" width="50" height="50" src="@/assets/icons/warn_icons_tauwetter.png" title="Tauwetter" alt="filter tauwetter" @click="setFilter('tauwetter')">
                    <br>
                    <img class="filter-img" :class="{'activeFilter-img' : filterStates['regen']}" width="50" height="50" src="@/assets/icons/warn_icons_regen.png" title="Regen" alt="filter regen" @click="setFilter('regen')">
                </div>
                <div class="icons-middle">
                    <ul class="content-ul">
                        <!--
                        <li class="prev"><button>&#10094;</button></li>
                        <li class="next"><button>&#10095;</button></li>
                        -->
                        <li>
                            <span ref="year" class="glow" style="font-size:26px">{{currentYear}}</span><br>
                            <span ref="month" style="font-size:18px">{{currentMonth}}</span><br>
                            <span ref="week" style="font-size:14px">{{currentWeek}}</span>
                            <br>
                            <br>
                            <input ref="datepicker" type="date" value="2023-02-03" @change="dateChanged">
                        </li>
                    </ul>
                </div>
                <div class="icons-right">
                    <img class="filter-img gap" :class="{'activeFilter-img' : filterStates['hitze']}" width="50" height="50" src="@/assets/icons/warn_icons_hitze.png" title="Hitze" alt="filter hitze" @click="setFilter('hitze')">
                    <img class="filter-img" :class="{'activeFilter-img' : filterStates['uv']}" width="50" height="50" src="@/assets/icons/warn_icons_uv.png" title="UV" alt="filter uv" @click="setFilter('uv')">
                    <br>
                    <img class="filter-img gap" :class="{'activeFilter-img' : filterStates['gewitter']}" width="50" height="50" src="@/assets/icons/warn_icons_gewitter.png" title="Gewitter" alt="filter gewitter" @click="setFilter('gewitter')">
                    <img class="filter-img" :class="{'activeFilter-img' : filterStates['sturm']}" width="50" height="50" src="@/assets/icons/warn_icons_sturm.png" title="Sturm" alt="filter sturm" @click="setFilter('sturm')">
                    <br>
                    <img class="filter-img" :class="{'activeFilter-img' : filterStates['nebel']}" width="50" height="50" src="@/assets/icons/warn_icons_nebel.png" title="Nebel" alt="filter nebel" @click="setFilter('nebel')">
                </div>
            </div>
            <div class="content-week" v-if="filteredWeekEvents">
                <div class="column" :class="{selectedColumn: selectedWeekDay === 1}" v-on:click="selectColumn(1)">
                    <span>Mo</span>
                    <template v-if="filteredWeekEvents[1]">
                        <img v-for="value in filteredWeekEvents[1]" :key="value" width="50" height="50" :title="value[0].toUpperCase() + value.substr(1)" :alt="value" :src="'icons/warn_icons_' + value + '.png'">
                    </template>
                </div>
                <div class="column" :class="{selectedColumn: selectedWeekDay === 2}" v-on:click="selectColumn(2)">
                    <span>Di</span>
                    <template v-if="filteredWeekEvents[2]">
                        <img v-for="value in filteredWeekEvents[2]" :key="value" width="50" height="50" :title="value[0].toUpperCase() + value.substr(1)" :alt="value" :src="'icons/warn_icons_' + value + '.png'">
                    </template>
                </div>
                <div class="column" :class="{selectedColumn: selectedWeekDay === 3}" v-on:click="selectColumn(3)">
                    <span>Mi</span>
                    <template v-if="filteredWeekEvents[3]">
                        <img v-for="value in filteredWeekEvents[3]" :key="value" width="50" height="50" :title="value[0].toUpperCase() + value.substr(1)" :alt="value" :src="'icons/warn_icons_' + value + '.png'">
                    </template>
                </div>
                <div class="column" :class="{selectedColumn: selectedWeekDay === 4}" v-on:click="selectColumn(4)">
                    <span>Do</span>
                    <template v-if="filteredWeekEvents[4]">
                        <img v-for="value in filteredWeekEvents[4]" :key="value" width="50" height="50" :title="value[0].toUpperCase() + value.substr(1)" :alt="value" :src="'icons/warn_icons_' + value + '.png'">
                    </template>
                </div>
                <div class="column" :class="{selectedColumn: selectedWeekDay === 5}" v-on:click="selectColumn(5)">
                    <span>Fr</span>
                    <template v-if="filteredWeekEvents[5]">
                        <img v-for="value in filteredWeekEvents[5]" :key="value" width="50" height="50" :title="value[0].toUpperCase() + value.substr(1)" :alt="value" :src="'icons/warn_icons_' + value + '.png'">
                    </template>
                </div>
                <div class="column" :class="{selectedColumn: selectedWeekDay === 6}" v-on:click="selectColumn(6)">
                    <span>Sa</span>
                    <template v-if="filteredWeekEvents[6]">
                        <img v-for="value in filteredWeekEvents[6]" :key="value" width="50" height="50" :title="value[0].toUpperCase() + value.substr(1)" :alt="value" :src="'icons/warn_icons_' + value + '.png'">
                    </template>
                </div>
                <div class="column" :class="{selectedColumn: selectedWeekDay === 7}" v-on:click="selectColumn(7)">
                    <span>So</span>
                    <template v-if="filteredWeekEvents[0]">
                        <img v-for="value in filteredWeekEvents[0]" :key="value" width="50" height="50" :title="value[0].toUpperCase() + value.substr(1)" :alt="value" :src="'icons/warn_icons_' + value + '.png'">
                    </template>
                </div>
            </div>
        </div>
        <div v-show="!isConnectionReady">
            <p>Connection not ready!</p>
            <div class="login">
                <input type="text" ref="username" name="user" placeholder="Benutzername" value="">
                <input type="password" ref="password" name="password" placeholder="Passwort" value="">
            </div>
            <br>
            <button class="button" @click="setDatabaseConnection">set Connection</button>
            <br>
            <br>
        </div>
    </div>
</template>

<script>
import * as Senergy from "@/modules/Senergy";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "DWDFilter",
    data: () => ({
        selectedWeekDay: 3,
        currentYear: '',
        currentMonth: '',
        currentWeek: '',
        firstDateOfWeek: '',
        lastDateOfWeek: '',
        weekEvents : {},
        filterStates: {frost: false, glatteis: false, schnee: false, tauwetter: false,
            regen: false, hitze: false, uv: false, gewitter: false, sturm: false, nebel: false},
    }),
    computed: {
        isConnectionReady(){
            const state = this.$store.getters["dwdStore/isSenergyReady"];
            console.log(this.$refs.datepicker)
            //TODO datepicker ist beim 2. öffnen undefined
            if (state && this.$refs.datepicker){
                this.dateChanged({target: this.$refs.datepicker});
                this.clearLoginData();
            }
            return state;
        },
        filteredWeekEvents(){
            const keys = Object.keys(this.filterStates);
            let all = true;
            keys.forEach(key => {
                if(this.filterStates[key]){
                    all = false;
                }
            });
            if(all){
                return this.weekEvents;
            }
            const events = {};
            Object.keys(this.weekEvents).forEach(key => {
                events[key] = [];
                this.weekEvents[key].forEach(val => {
                    if (this.filterStates[val]){
                        events[key].push(val);
                    }
                });
            });
            return events;
        },
    },
    methods: {
        ...mapActions('dwdStore', {
            setConnection: 'setConnection',
            storeSetCurrentFilters: 'setCurrentFilters',
            storeLoadWarningsForDate: 'loadWarningsForDate',
            storeLoadNiederschlagForDate: 'loadNiederschlagForDate',
            storeLoadWetterForDate: 'loadWetterForDate',
            storeLoadUBAForDate: 'loadUBAForDate',
            storeLoadPegelForDate: 'loadPegelForDate',
        }),
        clearLoginData(){
            this.$refs.username.value = "";
            this.$refs.password.value = "";
        },
        setDatabaseConnection(){
            this.setConnection({username: this.$refs.username.value, password: this.$refs.password.value});
        },
        setFilter(type){
            this.filterStates[type] = !this.filterStates[type];
            const currentFilters = [];

            Object.keys(this.filterStates).forEach(key => {
                if(this.filterStates[key]){
                    currentFilters.push(key);
                }
            });

            this.storeSetCurrentFilters(currentFilters)
        },
        dateChanged(event){
            const date = event.target.value;

            if(date) {
                this.loadData(date);
            }

            this.setDate(event.target);
        },
        loadData(date){
            // TODO: Kann eine Weile dauern, besser Ladeanimation anzeigen
            // TODO: Kann nur erfolgreich geladne werden, wenn vorher alles andere geladen wurde, also besser eine Art Warteliste einfügen?
            this.storeLoadWarningsForDate(date);
        },
        loadWeekData(){
            Senergy.getWarningsForWeek(this.firstDateOfWeek + "T00:00:00Z", this.lastDateOfWeek + "T23:59:59Z").then(data => {
                this.weekEvents = data;
            });
        },
        setDate(picker){
            const date = new Date(picker.value);
            this.selectedWeekDay = date.getDay() > 0 ? date.getDay() : 7;
            const yearText = date.getFullYear().toString();
            const monthText = date.toLocaleString('default', {month: 'long'});

            this.$refs.month.classList.remove('glow');
            this.$refs.month.offsetHeight; // trigger animation...

            const week = this.getWeekOfMonth(picker.value);
            if(week.mo.day.toString().length < 2){
                week.mo.day = "0" + week.mo.day;
            }
            if(week.mo.month.toString().length < 2){
                week.mo.month = "0" + week.mo.month;
            }
            if(week.su.day.toString().length < 2){
                week.su.day = "0" + week.su.day;
            }
            if(week.su.month.toString().length < 2){
                week.su.month = "0" + week.su.month;
            }
            this.firstDateOfWeek = `${week.mo.year}-${week.mo.month}-${week.mo.day}`;
            this.lastDateOfWeek = `${week.su.year}-${week.su.month}-${week.su.day}`;
            const weekText = `(${week.mo.day}.${week.mo.month} - ${week.su.day}.${week.su.month})`;
            if(this.currentWeek !== weekText || this.currentMonth !== monthText || this.currentYear !== yearText) {
                this.currentWeek = weekText;
                this.loadWeekData();
            }

            this.currentYear = yearText;
            this.currentMonth = monthText;

            this.$refs.month.classList.add('glow');
        },
        getWeekOfMonth(dateString){
            const date = new Date(dateString);
            // days: 0-Sun - 6-Sat
            let day = date.getDay() || 7;
            let mon = {day: date.getDate(),  month: date.getMonth() + 1, year: date.getFullYear()};
            let sun = {day: date.getDate(),  month: date.getMonth() + 1, year: date.getFullYear()};

            if( day !== 1 ) {
                const monDate = new Date(dateString);
                monDate.setHours(-24 * (day - 1));
                mon = {day: monDate.getDate(),  month: monDate.getMonth() + 1, year: monDate.getFullYear()};
            }
            if( day !== 7 ) {
                date.setHours(+24 * (7 - day));
                sun = {day: date.getDate(),  month: date.getMonth() + 1, year: date.getFullYear()};
            }

            return {mo: mon, su: sun};
        },
        selectColumn(nr){
            const diff = nr - this.selectedWeekDay;
            const date = new Date(this.$refs.datepicker.value);
            date.setDate(date.getDate() + diff);
            this.$refs.datepicker.valueAsDate = date;
            this.selectedWeekDay = nr;
            this.loadData(this.$refs.datepicker.value);
        }
    }
}
</script>

<style scoped>
    .login input{
        border: 1px solid gray;
    }
    .login {
        padding: 5px;
    }
    .content-header {
        background-color: white;
        color: var(--color-text);
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 100%;
        user-select: none;
    }
    .content-ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    .icons-left {
        padding-left: 5px;
    }
    .icons-right {
        padding-right: 5px;
    }
    .icons-middle, .icons-left, .icons-right {
        padding-top: 5px;
        padding-bottom: 2px;
    }
    .activeFilter-img {
        outline: 3px solid var(--color-primary);
        outline-offset: -2px;
        border: 1px solid white;
        box-sizing: content-box;
    }
    .filter-img:not(.activeFilter-img):hover {
        outline: 3px solid var(--color-secondary);
        outline-offset: -2px;
        border: 1px solid white;
        box-sizing: content-box;
    }
    .mainDiv{
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
    .content-week {
        display: flex;
        border-top: 1px solid var(--color-text);
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        background-color: white;
        user-select: none;
    }
    .column {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 50%;
        padding: 10px;
        box-sizing: border-box;
        width: 50px;
    }
    .column:nth-of-type(1) {
        border-bottom-left-radius: 25px;
    }
    .column:nth-last-child(1) {
        border-bottom-right-radius: 25px;
    }
    .selectedColumn {
        outline: 2px solid var(--color-primary);
        outline-offset: -1px;
    }
    .column:not(.selectedColumn):hover{
        outline: 2px solid var(--color-secondary);
        outline-offset: -1px;
    }
    img {
        width: 40px;
        height: 40px;
    }
    .icons-left img, .icons-right img {
        border: 1px solid black;
        background-color: white;
    }
    .content-week img:hover{
        outline: 1px solid lightgray;
    }
    .gap {
        margin-right: 3px;
    }
    .glow {
        animation: glow 1.3s linear forwards;
    }
    @keyframes glow {
        40% {
            text-shadow: 0 0 4px #000000;
        }
    }
</style>