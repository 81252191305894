<template>
    <div class="filter-root">
        <button class="button" @click="showDialog">{{buttonMsg}}</button>
        <div v-show="isVisible" class="dialog" ref="dialog">
            <div class="modal-header" @mousedown="dragMouseDown" ref="modal-header" :class="{isDragging: isDragging}">
                <span class="close" @click="closeDialog">&times;</span>
                <p class="modal-header-text">{{headerMsg}}</p>
            </div>
            <div class="content-header">
                <slot v-if="isVisible" name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Dialog",
    data: () => ({
        positions: {
            clientX: undefined,
            clientY: undefined,
            movementX: 0,
            movementY: 0,
        },
        isVisible: false,
        isDragging: false
    }),
    props: {
        headerMsg: {
            type: String,
            required: true,
        },
        buttonMsg: {
            type: String,
            required: true,
        }
    },
    methods: {
        showDialog(){
            this.isVisible = true;
        },
        closeDialog(){
            this.isVisible = false;
        },
        dragMouseDown(evt){
            if(evt.target.classList.contains('close')){
                return;
            }
            evt.preventDefault();
            this.positions.clientX = evt.clientX;
            this.positions.clientY = evt.clientY;
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
            this.isDragging = true;
        },
        elementDrag(evt){
            evt.preventDefault();
            this.positions.movementX = this.positions.clientX - evt.clientX;
            this.positions.movementY = this.positions.clientY - evt.clientY;
            this.positions.clientX = evt.clientX;
            this.positions.clientY = evt.clientY;
            let topNew = this.$refs.dialog.offsetTop - this.positions.movementY;
            let leftNew = this.$refs.dialog.offsetLeft - this.positions.movementX;

            if(leftNew < 0){
                leftNew = 0;
            } else if(leftNew > document.body.clientWidth - this.$refs.dialog.offsetWidth){
                leftNew = document.body.clientWidth - this.$refs.dialog.offsetWidth;
            }

            if(topNew < 0){
                topNew = 0;
            } else if(topNew > document.body.clientHeight - this.$refs.dialog.offsetHeight){
                topNew = document.body.clientHeight - this.$refs.dialog.offsetHeight;
            }

            this.$refs.dialog.style.top = topNew + 'px';
            this.$refs.dialog.style.left = leftNew + 'px';
        },
        closeDragElement(){
            document.onmouseup = null;
            document.onmousemove = null;
            this.isDragging = false;
        },
    }
}
</script>

<style scoped>
.filter-root {
    display: inline-block;
}
.dialog {
    background-color: var(--color-primary);
    position: absolute;
    /*width: 200px;*/
    /*height: 200px;*/
    z-index: 2000;
    border-radius: 25px;
    border-style: double;
    left: 400px;
    top: 300px;
}
.modal-header {
    padding: 2px 16px;
    background-color: white;
    border-bottom: 1px solid var(--color-text);
    cursor: grab;
    user-select: none;
    text-align: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.close {
    color: var(--color-primary);
    float: right;
    font-size: 24px;
    /*font-weight: bold;*/
}
.close:hover,
.close:focus {
    color: var(--color-secondary);
    text-decoration: none;
    cursor: pointer;
}
.modal-header-text {
    /*margin-right: 10px;*/
    display: inline-block;
    color: var(--color-text);
    font-weight: bold;
    margin-bottom: 0;
    padding-top: 8px;
    padding-bottom: 8px;
}
.isDragging {
    cursor: grabbing;
}
.content-header {
    background-color: white;
    color: var(--color-text);
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    user-select: none;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}
</style>