<template>
    <div ref="popup" class="popup"></div>
</template>

<script>
import {Feature, Overlay} from "ol";
import {Point} from "ol/geom";
import {Vector} from "ol/source";
import {Vector as VectorLayer} from "ol/layer/";
import * as SatelliteTracker from "../modules/SatelliteTracker";
import * as olEasing from 'ol/easing';

import ol_featureAnimation_Zoom from "ol-ext/featureanimation/Zoom";
import {Icon, Stroke, Style} from "ol/style";
import CircleStyle from "ol/style/Circle";

export default {
    name: "MapChildSatellites",
    props: {
        map: {
            required: true,
        }
    },
    data: () => ({
        popupOverlay: null,
        satelliteMap: new Map(),
        onPointerMoveFnc: null,
        satelliteLayer: null,
    }),
    mounted() {
        this.satelliteLayer = this.createSatelliteLayer();
        this.map.addLayer(this.satelliteLayer);

        const satelliteIDs = [25544, 20580, 37846, 41861, 37847, 41862, 38857,
            43055, 38858, 43056, 43057, 40128, 43058, 40129, 43564, 40544, 43565,
            40545, 43566, 40889, 43567, 40890
        ];


        satelliteIDs.forEach((id, index) => {
            setTimeout(function(){
                this.addSatelliteTracker(id);
            }.bind(this), 500*index);
        });

        this.popupOverlay = new Overlay({
            element: this.$refs['popup'],
            offset: [9, 9]
        });
        this.map.addOverlay(this.popupOverlay);

        this.onPointerMoveFnc = function(event){
            if(!(this.map && this.$refs['popup'])){
                return;
            }
            let anyFeature = false;
            this.map.forEachFeatureAtPixel(event.pixel,
                (feature, layer) => {
                    anyFeature = true;
                    this.$refs['popup'].innerHTML = feature.getProperties()['name'];
                    this.$refs['popup'].hidden = false;
                    this.popupOverlay.setPosition(event.coordinate);
                },
                { layerFilter: (layer) => {
                        return layer.get('l_name') === "satellite";
                    }, hitTolerance: 6 }
            );
            if (!anyFeature) {
                this.$refs['popup'].innerHTML = '';
                this.$refs['popup'].hidden = true;
            }
        }.bind(this);

        this.map.on('pointermove', this.onPointerMoveFnc);

    },
    destroyed() {
        this.satelliteMap.forEach((value, key) => {
            SatelliteTracker.removeCallbackFromSatelliteTracker(key, value);
        });
        this.map.un('pointermove', this.onPointerMoveFnc);
        this.map.removeLayer(this.satelliteLayer);
    },
    methods: {
        createSatelliteLayer(){
            const layer = new VectorLayer({
                source: new Vector({
                    wrapX: false,
                }),
            });
            layer.set('l_name', 'satellite');
            return layer;
        },
        pulseFeature(coords){
            const f = new Feature (new Point(coords));
            f.setStyle (new Style({
                image: new CircleStyle({
                    radius: 30,
                    points: 4,
                    //src: "../data/smile.png",
                    stroke: new Stroke ({ color: 'red', width:2 })
                })}));

            this.map.animateFeature (f, new ol_featureAnimation_Zoom({
                fade: olEasing.easeOut,
                duration: 1000
            }));
        },
        addSatelliteTracker(satelliteId){
            const marker = new Feature({
                geometry: new Point([]),
            });
            marker.setStyle(new Style({
                image: new Icon({
                    src: '/satellite.svg',
                    anchor: [0.5, 0.5],
                    scale: [0.5, 0.5],
                    anchorXUnits: 'fraction',
                }),
            }));

            const callback = function(pos){
                marker.setGeometry(new Point([pos.lng, pos.lat]));
                marker.getGeometry().transform('EPSG:4326', 'EPSG:3857');
            };

            SatelliteTracker.getPropertiesOfSatellite(satelliteId, name => {
                // callback is called? -> no (fetch) error occurred
                marker.setProperties({name: name});

                SatelliteTracker.getPositionOfSatellite(satelliteId, pos => {
                    // callback is called? -> no error occurred
                    callback(pos);
                    this.pulseFeature(marker.getGeometry().getCoordinates());

                    // no error occurred (received a position)? -> add feature to layer
                    this.satelliteLayer.getSource().addFeature(marker);
                    this.satelliteMap.set(satelliteId, callback);

                    SatelliteTracker.addCallbackToSatelliteTracker(satelliteId, 1000, callback);
                });
            });
        },
    }
}
</script>

<style scoped>
    .popup {
        border-radius: 5px;
        border: 1px solid grey;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 2px;
    }
</style>