import {getLatLngObj} from "tle.js";

const baseURL = 'https://tle.ivanstanojevic.me/api/tle/';
const trackerCallbacks = new Map();
const fetchedTLEs = new Map();

function fetchTLEStrings(id){
    // cached? -> return
    if (fetchedTLEs.has(id)){
        return new Promise(resolve => {
            resolve(fetchedTLEs.get(id));
        });
    }
    // id: Spacetrack Directory Number / Norad Id
    const url = baseURL + id;

    return fetch(url).then(
        function(resp){
            if(resp.status === 200 && resp.ok){
                return resp.json()
                    .then(data => {
                        const tle = data["name"] + "\n" + data["line1"] + "\n" + data["line2"];
                        fetchedTLEs.set(id, tle);
                        return tle;
                    })
                    .catch(error => {
                        console.log("fetch response error:", resp);
                        console.log(error);
                        return null;
                    });
            } else {
                console.log("fetch response error:", resp);
            }
        },
        function(error){
            console.log(`fetch failed for given satelliteID ${id} using this url: ${baseURL}`);
            console.log(error);
        }
    );
}

function addCallbackToSatelliteTracker(satelliteId, msec, callback){
    if(trackerCallbacks.has(satelliteId)){
        // add only once
        if(trackerCallbacks.get(satelliteId)['callbacks'].indexOf(callback) === -1){
            trackerCallbacks.get(satelliteId)['callbacks'].push(callback);
        }
        return;
    }

    fetchTLEStrings(satelliteId).then(function(tle){
        if(tle) {
            const intervalID = setInterval(function () {
                trackerCallbacks.get(satelliteId)['callbacks'].forEach(c => {
                    c(getLatLngObj(tle));
                });
            }, msec);

            trackerCallbacks.set(satelliteId, {callbacks: [callback], intervallID: intervalID});
        }
    });
}

function getPositionOfSatellite(satelliteId, callback){
    fetchTLEStrings(satelliteId).then(function(tle){
        if(tle) {
            callback(getLatLngObj(tle));
        }
    });
}

function getPropertiesOfSatellite(satelliteId, callback){
    fetchTLEStrings(satelliteId).then(tle => {
        if(tle){
            callback(tle.split('\n')[0]);
        }
    });
}

function removeCallbackFromSatelliteTracker(satelliteId, callback){
    if(trackerCallbacks.has(satelliteId)){
        const trackerObj = trackerCallbacks.get(satelliteId);
        // Remove Callback from List
        const callbacks = trackerObj['callbacks'];
        const index = callbacks.indexOf(callback);
        if(index > -1){
            callbacks.splice(index, 1);
        }
        // List is empty? -> stop Interval
        if(callbacks.length === 0){
            clearInterval(trackerObj['intervallID']);
            // remove Entry from Map
            trackerCallbacks.delete(satelliteId);
        }
    }
}

export {addCallbackToSatelliteTracker, removeCallbackFromSatelliteTracker, getPropertiesOfSatellite, getPositionOfSatellite};