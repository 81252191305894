<template>
    <div>
        <div v-for="(catName, catKey) in featureLayers" :key="catKey">
        <button class="collapsible" @click="collapsibleClick($event); loadIntersectionFeatures(catName)" style="font-size: larger">
            {{catName}}
            <span v-if="featureMap[catName].layer" :style="{color: featureMap[catName].layer.get('defaultColor')}">[{{featureMap[catName].counter}}]</span>
        </button>
        <div class="collapsibleContent">
            <div v-for="(feature, index) in featureMap[catName].features" :key="index">
                <button class="collapsible" @click="collapsibleClick">{{feature.get('___id')}} Attribute</button>
                <div class="collapsibleContent">
                    <table>
                        <tr v-if="parentFeatureId"> <!-- TODO: Bug, wenn feature in Selektion ist und wieder eingefügt/entfernt wird (durch Rekursion im Baum)! -->
                            <td>Add to Selection:</td>
                            <td><input type="checkbox" :id="'f_' + feature.get('___id')" @click="addFeatureToSelection($event.target.checked, feature, catName)"></td>
                        </tr>
                        <tr v-if="feature.get('___mainCategory') && !catName.startsWith(feature.get('___mainCategory'))">
                            <td>Feature Category:</td>
                            <td>{{feature.get('___mainCategory')}}</td>
                        </tr>
                        <tr v-for="(fVal, fKey) in getDisplayableProperties(feature)" :key="fKey">
                            <td>{{fVal}}:</td>
                            <td>{{feature.get(fVal)}}</td>
                        </tr>
                    </table>
                    <div v-for="docType in Object.keys(feature.get('___documents'))" :key="docType">
                        <button class="collapsible" @click="collapsibleClick">
                            {{docType + " [" + feature.get('___documents')[docType].length + "]"}}
                            <div class="popup">
                                ({{getCounterForDocumentsWithDate(feature, docType)}})
                                <span class="popuptext">Anzahl Dokumente mit Datum.</span>
                            </div>
                            <div class="popup">
                                ({{getCounterForDocumentsWithPersonsAndCompanies(feature, docType)}})
                                <span class="popuptext">Anzahl Dokumente mit Person oder Firma.</span>
                            </div>
                            <div class="popup">
                                ({{getCounterForDocumentsWithSearchString(feature, docType)}})
                                <span class="popuptext">Anzahl Dokumente mit gesuchter Zeichenkette.</span>
                            </div>
                        </button>
                        <div class="collapsibleContent">
                            <table class="documentsTable">
                                <template v-for="(type, tKey) in feature.get('___documents')[docType]">
                                    <tr :key="tKey" :class="{documentWithDate: getDocumentsWithDate.dIds.has(type.id) || getDocumentsWithPersonsAndCompanies.dIds.has(type.id) || getDocumentsWithSearchString.dIds.has(type.id)}"> <!-- TODO: class als computedProperty?-->
                                        <td class="hasTooltip">
                                            <button class="openPDFButton" @click="openPDF(type.filename);">
                                                <span>{{type.filename.substr(type.filename.lastIndexOf('.') + 1) + " (" + type.filetime + ")"}}:</span>
                                                <span class="toolTip">Click to View Document.</span>
                                            </button>
                                        </td>
                                        <td  class="hasTooltip">
                                            <span>{{type.description}}</span>
                                            <table class="toolTip">
                                                <tr>
                                                    <td>Class Probability:</td>
                                                    <td>{{type.firstTypeProbability}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Second Class:</td>
                                                    <td>{{type.secondType}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Second Class Probability:</td>
                                                    <td>{{type.secondTypeProbability}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Uncertainty:</td>
                                                    <td>{{type.uncertainty}}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </template>
                            </table>
                        </div>
                    </div>
                    <br>
                    <button class="collapsible" @click="collapsibleClick($event); loadIntersectionCounter(feature.get('___id'))">Überlappende Features</button>
                    <div class="collapsibleContent">
                        <FeatureLayerPanel v-if="extraFeatureMap.intersections[feature.get('___id')]" :intersections="extraFeatureMap.intersections[feature.get('___id')]" :parentFeatureId="feature.get('___id')">
                            <span>Keine Features vorhanden.</span>
                        </FeatureLayerPanel>
                    </div>
                    <br>
                    <button class="collapsible" @click="collapsibleClick($event); loadRestrictions(feature.get('___id'), 'verbot')">Verbote</button>
                    <div class="collapsibleContent">
                        <!-- TODO: verbot und gebot sind weitestgehend gleich! -->
                        <span v-if="!extraFeatureMap.verbote[feature.get('___id')] || Object.keys(extraFeatureMap.verbote[feature.get('___id')]).length === 0">Keine Verbote gefunden.</span>
                        <div v-if="extraFeatureMap.verbote[feature.get('___id')]">
                            <div v-for="(value, vKey) of extraFeatureMap.verbote[feature.get('___id')]" :key="vKey">
                                <button class="collapsible" @click="collapsibleClick">
                                    {{vKey}} [{{value.length}}]
                                </button>
                                <div class="collapsibleContent">
                                    <table class="hTable" style="border-collapse: collapse">
                                        <template v-for="(entry, index) in value">
                                            <tr :key="index + '_row0'" class="copyBtn">
                                                <td class="copyOrPdfBtn"><button @click="openPDF(entry.filename, entry.sentence, entry.pageNum);">Open PDF</button></td>
                                                <td class="copyOrPdfBtn"><button @click="copyTextToClipboard(entry.filename, entry.description, entry.type, entry.sentence);">Copy to Clipboard</button></td>
                                            </tr>
                                            <tr :key="index + '_row1'" :class="{activeRestrictionTop: isFeatureWithActiveRestriction(entry.wTypes) && test.includes(feature)}">
                                                <td>{{entry.description}}</td>
                                                <td>{{entry.type}}</td>
                                            </tr>
                                            <tr :key="index + '_row2'" :class="{activeRestrictionBottom: isFeatureWithActiveRestriction(entry.wTypes) && test.includes(feature)}">
                                                <td colspan="2">{{entry.sentence}} - Seite: {{entry.pageNum}}</td>
                                            </tr>
                                        </template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <button class="collapsible" @click="collapsibleClick($event); loadRestrictions(feature.get('___id'), 'gebot')">Gebote</button>
                    <div class="collapsibleContent">
                        <span v-if="!extraFeatureMap.gebote[feature.get('___id')] || Object.keys(extraFeatureMap.gebote[feature.get('___id')]).length === 0">Keine Gebote gefunden.</span>
                        <div v-if="extraFeatureMap.gebote[feature.get('___id')]">
                            <div v-for="(value, vKey) of extraFeatureMap.gebote[feature.get('___id')]" :key="vKey">
                                <button class="collapsible" @click="collapsibleClick">
                                    {{vKey}} [{{value.length}}]
                                </button>
                                <div class="collapsibleContent">
                                    <table class="hTable" style="border-collapse: collapse">
                                        <template v-for="(entry, index) in value">
                                            <tr :key="index + '_row0'" class="copyBtn">
                                                <td class="copyOrPdfBtn"><button @click="openPDF(entry.filename, entry.sentence, entry.pageNum);">Open PDF</button></td>
                                                <td class="copyOrPdfBtn"><button @click="copyTextToClipboard(entry.filename, entry.description, entry.type, entry.sentence);">Copy to Clipboard</button></td>
                                            </tr>
                                            <tr :key="index + '_row1'" :class="{activeRestrictionTop: isFeatureWithActiveRestriction(entry.wTypes) && test.includes(feature)}">
                                                <td>{{entry.description}}</td>
                                                <td>{{entry.type}}</td>
                                            </tr>
                                            <tr :key="index + '_row2'" :class="{activeRestrictionBottom: isFeatureWithActiveRestriction(entry.wTypes) && test.includes(feature)}">
                                                <td colspan="2">{{entry.sentence}} - Seite: {{entry.pageNum}}</td>
                                            </tr>
                                        </template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog id="line-dialog" v-if="checkIfSensor(catName)" headerMsg="LineChart: Sensordaten" buttonMsg="SHOW LineChart">
                        <template v-slot:content>
                            <LineChart :station_type="catName" :feature="feature.getProperties()"></LineChart>
                        </template>
                    </Dialog>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import * as CypherQueries from "@/modules/CypherQueries";
import Vue from "vue";
import * as MapData from "../modules/MapData";
import {mapGetters, mapMutations} from "vuex";
import PDFViewer from "@/components/PDFViewer";
import Dialog from "@/components/Dialog";
import LineChart from "@/components/LineChart"

export default {
    name: "FeatureLayerPanel",
    components: {LineChart, Dialog},
    data: () => ({
        featureMap: null,
        extraFeatureMap: {
            intersections: {},
            verbote: {},
            gebote: {},
        },
        activeFeatures: null,
    }),
    props: [
        'intersections',
        'parentFeatureId'
    ],
    computed: {
        ...mapGetters('neo4jStore', [
            'getDocumentsWithDate',
            'getDocumentsWithPersonsAndCompanies',
            'getDocumentsWithSearchString',
        ]),
        featureLayers(){
            if(this.featureMap){
                return Object.keys(this.featureMap).filter((name) => {
                    return !(name === "dwd" || name === "rain");
                });
            } else {
                return [];
            }
        },
        test(){
            // TODO: Funktion benötigt? Umbenennen?
            //console.log("test called!"); // Nicht immer aufgerufen, wenn sich was geändert hat, aber funktioniert dennoch?
            return CypherQueries.getActiveFeaturesLayer().layer.getSource().getFeatures();
        }
    },
    mounted() {
        if(this.featureMap === null){
            this.featureMap = this.intersections;
        }
        this.activeFeatures = CypherQueries.getActiveFeaturesLayer();
    },
    methods: {
        ...mapMutations({
            addPDFPath: 'componentsStore/addPDFPath',
            addHighlightText: 'componentsStore/addHighlightText',
            addPageNum: 'componentsStore/addPageNumber',
            addFeatureToSelectionStore: 'mapStore/addFeatureToSelection',
            removeFeatureFromSelectionStore: 'mapStore/removeFeatureFromSelection'
        }),
        openPDF(path, sentence = null, page = 1) {
            // TODO: URL zentral verwalten!
            //let finalPath = 'http://127.0.0.1:3030/static/pdfs/' + path;
            //let finalPath = 'http://seneca.informatik.uni-leipzig.de:3030/static/' + path;
            let finalPath = 'https://documents.vda-group.de/static/' + path;
            if (typeof page === 'object' && page !== null && page.low !== undefined) {
                page = page.low
            }
            this.addPDFPath(finalPath)
            this.addHighlightText(sentence)
            this.addPageNum(page)
            let routeData = this.$router.resolve({name: 'PDFViewer', params: {pdfID: this.$store.state.componentsStore.pdfPath.length -1}});
            window.open(routeData.href, '_blank');
        },
        checkIfSensor(cat) {
          // TODO: Zentrale Stelle dafür? MapLayerControl macht auch diese Unterscheidung.
            let sensors = ["uba_sensor", "pegel_sensor", "wetter_sensor", "solar_sensor"]
            return sensors.indexOf(cat) !== -1
        },
        getCounterForDocumentsWithPersonsAndCompanies(feature, docType){
            let counter = 0;
            feature.get('___documents')[docType].forEach(d => {
                if(this.getDocumentsWithPersonsAndCompanies.dIds.has(d.id)){
                    counter += 1;
                }
            });
            return counter;
        },
        getCounterForDocumentsWithSearchString(feature, docType){
            let counter = 0;
            feature.get('___documents')[docType].forEach(d => {
                if(this.getDocumentsWithSearchString.dIds.has(d.id)){
                    counter += 1;
                }
            });
            return counter;
        },
        getCounterForDocumentsWithDate(feature, docType){
            let counter = 0;
            feature.get('___documents')[docType].forEach(d => {
                if(this.getDocumentsWithDate.dIds.has(d.id)){
                    counter += 1;
                }
            });
            return counter;
        },
        copyTextToClipboard(filename, description, type, sentence){
            let msg = "###############"
            msg += "\nDocument:\n" + filename;
            msg += "\n\nLabeled as:\n" + type;
            msg += "\n\nShort Description:\n" + description;
            msg += "\n\nRestriction:\n" + sentence;
            navigator.clipboard.writeText(msg);
        },
        addFeatureToSelection(state, feature, category){
            // TODO: Name der Fkt überdenken!
            if(state){
                MapData.addFeatureToSelection(feature, category, CypherQueries.getLayer(category));
                this.addFeatureToSelectionStore(feature);
            } else {
                MapData.removeFeatureFromSelection(feature, category);
                this.removeFeatureFromSelectionStore(feature);
            }
        },
        isFeatureWithActiveRestriction(wTypes){
            if(!wTypes){
                return false;
            }
            const activeFeatures = CypherQueries.getActiveCounties().activeFeatures;
            const types = Object.keys(activeFeatures);

            for(let type of types){
                if(wTypes.includes(type)){
                    return true;
                }
            }
            return false;
        },
        loadRestrictions(featureId, type){
            // TODO: if ...
            CypherQueries.loadRestrictions(featureId, type).then(data => {
                if(type === 'verbot') {
                    Vue.set(this.extraFeatureMap.verbote, featureId, data);
                } else {
                    Vue.set(this.extraFeatureMap.gebote, featureId, data);
                }
            });
        },
        loadIntersectionFeatures(catName){
            if(this.featureMap[catName].features.length !== this.featureMap[catName].counter){
                CypherQueries.loadIntersectionsForFeatureInCategory(this.parentFeatureId, catName).then(data => {
                    // TODO: Wird in jeder Rekursionstufe von FeatureLayerPanel geladen, besser store dafür!
                    this.featureMap[catName].features = data;
                });
            }
        },
        loadIntersectionCounter(id){
            if(!this.extraFeatureMap.intersections[id]) {
                CypherQueries.loadIntersectionsCounterForFeature(id).then(map => {
                    // TODO: Wird in jeder Rekursionstufe von FeatureLayerPanel geladen, besser store dafür!
                    Vue.set(this.extraFeatureMap.intersections, id, map);
                });
            }
        },
        setData(featureMap){
            this.featureMap = featureMap;
            this.extraFeatureMap.intersections = {};
            this.extraFeatureMap.verbote = {};
        },
        collapsibleClick(e){
            // TODO: copy von parent!
            const el = e.target.classList.contains('collapsible') ? e.target : e.target.parentElement;
            el.classList.toggle("active");
            const content = el.nextElementSibling;
            if(content.style.display === "block"){
                content.style.display = "none";
            } else {
                content.style.display = "block";
            }
        },
        getDisplayableProperties(feature){
            const props = [];
            Object.keys(feature.getProperties()).forEach(prop => {
                if(prop !== 'geometry' && !prop.startsWith("___")){
                    props.push(prop);
                }
            });
            return props;
        }
    },
}
</script>

<style scoped>
.hTable .copyBtn:hover + tr + tr, .hTable .copyBtn:hover + tr, .copyOrPdfBtn:hover{
    background-color: lightgray;
}
.openPDFButton {
    color: #1E3F93;
}
.activeRestrictionTop{
    border-top: 3px solid red;
    border-left: 3px solid red;
    border-right: 3px solid red;
}
.activeRestrictionBottom{
    border-bottom: 3px solid red;
    border-left: 3px solid red;
    border-right: 3px solid red;
}
.documentsTable{
    border-collapse: collapse;
}
.documentWithDate{
    border: 3px solid var(--color-secondary);
}
.hasTooltip .toolTip{
    display: none;
    padding: 3px;
}
.hasTooltip:hover .toolTip{
    display: block;
    position: absolute;
    border: 1px solid gray;
    background-color: white;
}
.hasTooltip:hover{
    background-color: lightgray;
}
.popup{
    position: relative;
    display: inline-block;
}
.popup .popuptext{
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    width: 250px;
    margin-left: -125px;
    font-size: small;
}
.popup .popuptext::after{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}
.popup:hover .popuptext {
    visibility: visible;
}

#line-dialog {
    display: inherit;
}
</style>