<template>
    <div class="root">
        <LoginScreen :isLoading="isLoading" :update-text="updateText"></LoginScreen>
        <div class="mainApp">
            <div class="globalMenu">
                <v-layout grid-list-xl>
                    <v-flex d-flex mt-2>
                        <div>
                            <button class="button" @click="addMap">Add Map</button>
                            <button class="button" @click="addPotree">Add Potree</button>
                            <button class="button" @click="remove">Remove</button>
                            <button class="button" @click="addTimeline">Add Timeline</button>
                        </div>
                        <Dialog headerMsg="LineChart: Sensordaten" buttonMsg="SHOW LineChart">
                            <template v-slot:content>
                                <LineChart></LineChart>
                            </template>
                        </Dialog>
                        <Dialog headerMsg="Kalender: Wetterdaten" buttonMsg="SHOW DWD Dialog">
                            <template v-slot:content>
                                <DWDFilter></DWDFilter>
                            </template>
                        </Dialog>                        
                        <Dialog headerMsg="Filter: Documents by Time" buttonMsg="Show Document Filter">
                            <template v-slot:content>
                                <DocumentFilter></DocumentFilter>
                            </template>
                        </Dialog>
                        <Dialog button-msg="Show Document Filter (Names)" header-msg="Filter: Documents By Names">
                            <template v-slot:content>
                                <DocumentFilterNames></DocumentFilterNames>
                            </template>
                        </Dialog>
                        <Dialog button-msg="ElasticSearch" header-msg="Search for Keywords">
                            <template v-slot:content>
                                <ElasticSearchFilter></ElasticSearchFilter>
                            </template>
                        </Dialog>
                        <Dialog button-msg="MainFilter" header-msg="Filter Menu">
                            <template v-slot:content>
                                <MainFilter></MainFilter>
                            </template>
                        </Dialog>
                        <Dialog button-msg="IFrame Test" header-msg="IFrame Test">
                            <template v-slot:content>
                                <IFrameTest></IFrameTest>
                            </template>
                        </Dialog>
                    </v-flex>
                </v-layout>
            </div>
            <SplitPanel class="heightCorrection">
                <template v-slot:first>
                    <div class="mapGrid" ref="mapGrid">
                        <template v-for="(map, index) in mapCounter">
                            <MapContainer v-if="map === 'map'" ref="mapContainer" :key="index" :mkey="index" :style="'grid-area: map' + (index+1)"></MapContainer>
                            <LidarMapContainer v-else-if="map === 'potree'" ref="lidarMapContainer" :key="index" :style="'grid-area: map' + (index+1)"></LidarMapContainer>
                        </template>
                        <Timeline v-if="timelineAdded" :style="'grid-area: timeline'" ref="timelineContainer" :mkey="mapCounter.length + 1"></Timeline>
                    </div>
                </template>
                <template v-slot:second>
                    <InfoPanel ref="infoPanel"></InfoPanel>
                </template>
            </SplitPanel>
        </div>
    </div>
</template>


<script>
import MapContainer from './MapContainer.vue';
import Timeline from './Timeline'
import {test as Neo4jControllerTest} from "../modules/Neo4jController";
import SplitPanel from './SplitPanel';
import LoginScreen from "./LoginScreen";
import * as CypherQueries from "../modules/CypherQueries";
import * as Senergy from "../modules/Senergy";
import InfoPanel from "./InfoPanel";
import DWDFilter from "./DWDFilter";
import {mapGetters, mapMutations} from 'vuex'
import DocumentFilter from "@/components/DocumentFilter";
import Dialog from "@/components/Dialog";
import DocumentFilterNames from "@/components/DocumentFilterNames";
import ElasticSearchFilter from "@/components/ElasticSearchFilter";
import MainFilter from "@/components/MainFilter";
import LidarMapContainer from "@/components/LidarMapContainer";
import IFrameTest from "@/components/IFrameTest";
import LineChart from "@/components/LineChart"

export default {
    name: 'StartPage',
    components: {
        IFrameTest,
        LidarMapContainer,
        MainFilter,
        DocumentFilterNames,
        Dialog,
        DWDFilter,
        DocumentFilter,
        ElasticSearchFilter,
        InfoPanel,
        LoginScreen,
        MapContainer,
        Timeline,
        SplitPanel,
        LineChart
    },
    data() {
        return {
            mapCounter: ["map"],
            //isLoading: true,
            updateText: ["Hallo, wie geht es Dir?", ""], // TODO: in Store speichern!
            timelineAdded: false,
        }
    },
    mounted(){
        //Neo4jControllerTest();
        CypherQueries.setTextUpdateReceiver({
            // TODO: in store auslagern, isLoading gleich mit!
            //  CypherQueries.js muss dieses Array auch im Store speichern!
            setText: function (text1, text2){
                this.updateText = [text1, text2];
            }.bind(this)
        });
/*
        CypherQueries.loadLayerNames().then(() => {
            CypherQueries.loadLayerData().then(() => {
                CypherQueries.loadRestrictionsNames("Verbot").then(() => {
                    CypherQueries.loadRestrictionsLayerData("Verbot").then(() => {
                        CypherQueries.loadRestrictionsNames("Gebot").then(() => {
                            CypherQueries.loadRestrictionsLayerData("Gebot").then(() => {
                                CypherQueries.loadCountyRestrictionsIds().then(() => {
                                    //this.isLoading = false;
                                    this.setLoading(false);
                                });
                            });
                        });
                    });
                });
                Senergy.createConnection("", "", (e) => console.log(e));
            });
        });
 */
    },
    computed: {
        ...mapGetters({
            isLoading: "neo4jStore/isLoading",
        }),
    },
    methods: {
        ...mapMutations({
            setLoading: "neo4jStore/setLoading",
        }),        
        addMap() {
            if(this.mapCounter.length === 9){
                return;
            }
            this.mapCounter.push('map');
            this.manageMaps();
        },
        addPotree(){
            if(this.mapCounter === 9){
                return;
            }
            this.mapCounter.push('potree');
            this.manageMaps();
        },
        remove(){
            if(this.mapCounter.length === 1){
                return;
            }
            this.mapCounter.pop();
            this.manageMaps();
        },
        addTimeline() {
            this.timelineAdded = true
            this.manageMaps();
        },        
        manageMaps(){
            const rowNum = Math.sqrt(this.mapCounter.length);
            const isQuadratic = rowNum % 1 === 0;

            let areas = '';
            if(isQuadratic){
                let grid = "";
                for(let y=1; y<=rowNum; y++){
                    let line = "";
                    for(let x=1; x<=rowNum; x++){
                        line += "map" + (x+(rowNum*(y-1))) + " ";
                    }
                    grid += "'" + line.trim() + "' ";
                }
                areas = grid;
            } else if(this.mapCounter.length === 2){
                areas = '"map1" "map2"';
                if (this.timelineAdded) {
                    areas = '"map1 map2"';
                }
            } else if(this.mapCounter.length === 3) {
                areas = '"map1 map2" "map3 map3"';
            } else {
                const even = this.mapCounter.length % 2 === 0;
                // map1 map2
                // map3 map4
                for(let i=1, j=1; i<=this.mapCounter.length/2; i++, j=j+2){
                    areas += '"map' + j + ' map' + (j+1) + '"';
                }
                if(!even){
                    // map1 map2
                    // map3 map4
                    // map5 map5
                    areas += ' "map' + this.mapCounter.length + ' map' + this.mapCounter.length + '"';
                }
            }                

            if (this.timelineAdded) {
                if(isQuadratic){
                    areas += "'"
                    for (let i = 0; i < rowNum; i++) {
                        areas += "timeline "
                    }
                    areas = areas.trim()
                    areas += "'"
                } else {
                    areas += ' "timeline timeline"'                    
                }
            }
            this.$refs.mapGrid.style.gridTemplateAreas = areas;
        }
    },
}
//TODO grid auto rows does not update
</script>



<style scoped>
    .root{
        height: 100vh; /* Damit Infopanel nicht größer als Parent wird! */
    }

    .globalMenu, .infoPanel {
        border: 1px solid black;
        background-color: white;/*#164194;*/
    }

    .mapGrid{
        display: grid;
        grid-template-areas: "map1";
        grid-gap: 5px;
        height: 100%;
        grid-auto-rows: 1fr;
        grid-auto-columns: 1fr;
    }

    .mainApp {
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        gap: 5px;
        height: 100%;
        padding: 8px;
    }

    .globalMenu{
        padding: 5px;
    }

    .heightCorrection{
        /* TODO: Muss auch anders gehen, also ohne explizit 74px als Wert! */
        /*max-height: calc(100vh - 74px);*/
        overflow-y: auto; /* Damit Infopanel nicht größer als Parent wird! */
    }

</style>
