<template>
    <div class="parentDiv ol-control">
        <button class="button" ref="button" @click="handleButtonClick">»</button>
        <div class="layerDiv" :class="{'isVisible': !isVisible}">
            <br>
            <p>Ebenen:</p>
            <ul class="layerUl">
                <li v-for="obj in featureLayers" :key="obj.name">
                    <input :id="$id(obj.name)" type="checkbox" @change="toggleLayer(obj.name, $event.target.checked)">
                    <label :for="$id(obj.name)">
                        {{obj.name}}
                        <span :style="{color: obj.color}">[{{obj.count}}]</span>
                    </label>
                </li>
            </ul>
            <hr>
            <p>Wetterdaten:</p>
            <ul class="layerUl">
                <li v-for="obj in sensorLayers" :key="obj.name">
                    <input :id="$id(obj.name)" type="checkbox" @change="toggleLayer(obj.name, $event.target.checked)" :style="{accentColor: obj.color}">
                    <label :for="$id(obj.name)">
                        {{obj.name}}
                        <span :style="{color: obj.color}">[{{obj.count}}]</span>
                    </label>
                </li>
            </ul>
            <hr>
            <p>Verbote:</p>
            <ul class="layerUl">
                <li v-for="obj in restrictionFeatureLayersVerbote" :key="obj.name">
                    <input :id="$id(obj.name)+'verbot'" type="checkbox" @change="toggleRestrictionLayer('Verbot', obj.name, $event.target.checked)">
                    <label :for="$id(obj.name)+'verbot'">
                        {{obj.name}}
                        <span :style="{color: obj.color}">[{{obj.count}}]</span>
                    </label>
                </li>
            </ul>
            <hr>
            <p>Gebote:</p>
            <ul class="layerUl">
                <li v-for="obj in restrictionFeatureLayersGebote" :key="obj.name">
                    <input :id="$id(obj.name)" type="checkbox" @change="toggleRestrictionLayer('Gebot', obj.name, $event.target.checked)">
                    <label :for="$id(obj.name)">
                        {{obj.name}}
                        <span :style="{color: obj.color}">[{{obj.count}}]</span>
                    </label>
                </li>
            </ul>
            <hr>
            <p>Features mit aktiven Einschränkungen:</p>
            <input id="activeWeatherFeatures" type="checkbox" @change="toggleActiveWeatherLayer($event.target.checked)">
            <label for="activeWeatherFeatures">
                Wetter
                <span>[{{activeWeatherFeatureLayer}}]</span>
            </label>
            <br>
            <input id="documentsWithDates" type="checkbox" @change="toggleDocumentsWithDates($event.target.checked)">
            <label for="documentsWithDates">
                Documents (Dates)
                <span>[{{getDocumentsWithDate.fCounter}}]</span>
            </label>
            <br>
            <input id="documentsWithPersonsAndCompanies" type="checkbox" @change="toggleDocumentsWithPersonsAndCompanies($event.target.checked)">
            <label for="documentsWithPersonsAndCompanies">
                Documents (Persons, Companies)
                <span>[{{getDocumentsWithPersonsAndCompanies.fCounter}}]</span>
            </label>
            <br>
            <input id="documentsWithSearchString" type="checkbox" @change="toggleDocumentsWithSearchString($event.target.checked)">
            <label for="documentsWithSearchString">
                Documents (Search String)
                <span>[{{getDocumentsWithSearchString.fCounter}}]</span>
            </label>
            <!--
            <hr>
            <p>Map-Configs:</p>
            -->
        </div>
        <div>  
            <v-switch @change="changeMode" :v-model="drawMode" label="Draw Mode"></v-switch>
        </div>
        <div v-if="drawMode">
            <v-switch @change="changeIntersectMode" :v-model="intersectMode" label="Intersect or Contains?" active></v-switch>
        </div>
    </div>
</template>

<script>
import * as CypherQueries from "../modules/CypherQueries";
import {mapGetters, mapMutations} from 'vuex'
export default {
    name: "MapLayerControl",
    data: () => ({
        isVisible: false,
        layerData: {},
        drawMode: false,
        intersectMode: true,
        restrictionLayerData: {},
    }),
    computed: {
        ...mapGetters('neo4jStore', [
            'getDocumentsWithDate',
            'getDocumentsWithPersonsAndCompanies',
            'getDocumentsWithSearchString',
        ]),
        activeWeatherFeatureLayer(){
            // TODO: Counter wird nicht aktualisiert!
            return CypherQueries.getActiveFeaturesLayer().length.value;
        },
        featureLayers(){
            if(!this.layerData || !this.layerData.map){
                return [];
            }

            let ret = [];
            Object.keys(this.layerData.map).forEach(key => {
                if(key !== 'dwd' && key !== 'rain' && !key.endsWith("_sensor") && this.layerData.map[key].layer){
                    ret.push({
                        name: key,
                        color: this.layerData.map[key].layer.get('defaultColor'),
                        count: this.layerData.map[key].layer.getSource().getFeatures().length,
                    });
                }
            });
            return ret;
        },
        restrictionFeatureLayersVerbote(){
            const type = "Verbot";
            if(!this.restrictionLayerData || !this.restrictionLayerData.map || !this.restrictionLayerData.map[type]){
                return [];
            }
            let ret = [];
            Object.keys(this.restrictionLayerData.map[type]).forEach(key => {
                if(this.restrictionLayerData.map[type][key].layer){
                    ret.push({
                        name: key,
                        color: this.restrictionLayerData.map[type][key].layer.get('defaultColor'),
                        count: this.restrictionLayerData.map[type][key].layer.getSource().getFeatures().length,
                    });
                }
            });
            return ret;
        },
        restrictionFeatureLayersGebote(){
            const type = "Gebot";
            if(!this.restrictionLayerData || !this.restrictionLayerData.map || !this.restrictionLayerData.map[type]){
                return [];
            }
            let ret = [];
            Object.keys(this.restrictionLayerData.map[type]).forEach(key => {
                if(this.restrictionLayerData.map[type][key].layer){
                    ret.push({
                        name: key,
                        color: this.restrictionLayerData.map[type][key].layer.get('defaultColor'),
                        count: this.restrictionLayerData.map[type][key].layer.getSource().getFeatures().length,
                    });
                }
            });
            return ret;
        },
        sensorLayers() {
            if(!this.layerData || !this.layerData.map){
                return [];
            }
            let ret = [];
            Object.keys(this.layerData.map).forEach(key => {
                if((key === 'dwd' || key === 'rain' || key.endsWith("_sensor")) && this.layerData.map[key].layer){
                    ret.push({
                        name: key,
                        color: this.layerData.map[key].layer.get('defaultColor'),
                        count: this.layerData.map[key].layer.getSource().getFeatures().length,
                    });
                }
            });
            return ret;
        },
    },
    mounted() {
        //TODO handle drawmode for each map seperatly -> store same way as multiple pdfs
        this.setDrawMode(false) 
        this.setPolygonIntersect(true) 
        this.layerData = CypherQueries.getLayerData();
        this.restrictionLayerData = CypherQueries.getRestrictionLayerData();
    },
    methods: {
        ...mapMutations({
            setDrawMode: 'componentsStore/setDrawMode',
            setPolygonIntersect: 'componentsStore/setPolygonIntersect',
        }),          
        handleButtonClick(){
            const button = this.$refs['button'];
            button.classList.toggle("buttonPosition");
            if(button.innerText === '»'){
                this.isVisible = true;
                button.innerText = '«';
            } else {
                button.innerText = '»';
                this.isVisible = false;
            }
        },
        toggleLayer(name, state){
            const layer = CypherQueries.getLayer(name);
            if(layer) {
                if(state){
                    this.$parent.map.addLayer(layer);
                } else {
                    this.$parent.map.removeLayer(layer);
                }
            }
            if (name === 'solar'){
                this.$parent.showSolarLegend = state;
            }
        },
        toggleRestrictionLayer(type, name, state){
            const layer = CypherQueries.getRestrictionLayer(type, name);

            if(layer) {
                if(state){
                    this.$parent.map.addLayer(layer);
                } else {
                    this.$parent.map.removeLayer(layer);
                }
            }
        },
        toggleActiveWeatherLayer(state){
            const layer = CypherQueries.getActiveFeaturesLayer().layer;
            if(layer) {
                if(state){
                    this.$parent.map.addLayer(layer);
                } else {
                    this.$parent.map.removeLayer(layer);
                }
            }
        },
        toggleDocumentsWithDates(state){
            const layer = this.getDocumentsWithDate.layer;
            if(layer) {
                if(state){
                    this.$parent.map.addLayer(layer);
                } else {
                    this.$parent.map.removeLayer(layer);
                }
            }
        },
        toggleDocumentsWithPersonsAndCompanies(state){
            const layer = this.getDocumentsWithPersonsAndCompanies.layer;
            if(layer) {
                if(state){
                    this.$parent.map.addLayer(layer);
                } else {
                    this.$parent.map.removeLayer(layer);
                }
            }
        },
        toggleDocumentsWithSearchString(state){
            const layer = this.getDocumentsWithSearchString.layer;
            if(layer) {
                if(state){
                    this.$parent.map.addLayer(layer);
                } else {
                    this.$parent.map.removeLayer(layer);
                }
            }
        },
        changeMode() {
            this.drawMode = !this.drawMode
            this.setDrawMode(this.drawMode) 
        },
        changeIntersectMode() {
            this.intersectMode= !this.intersectMode
            this.setPolygonIntersect(this.intersectMode) 
        }   
    },
}
</script>

<style scoped>

.layerDiv{
    border: 1px solid gray;
    height: inherit;
    padding: 5px;
    overflow: auto;
}

.isVisible{
    display: none;
}

.parentDiv{
    --top: 0.5em;
    --left: 0.5em;
    left: var(--left);
    top: var(--top);
    max-height: calc(100% - var(--top));
    max-width: calc(100% - var(--left));
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 1000;
}

.button{
    margin: 0;
    flex-shrink: 0;
}

.buttonPosition {
    position: absolute;
}

.layerUl{
    list-style-type: none;
    margin: 0;
    padding: 0;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

span {
    font-weight: bold;
}

</style>