<template>
    <div>
        <p>Documents from:</p>
        <div class="dateDiv">
            <label for="dayFrom">Day:</label>
            <input ref="dayFrom" type="number" id="dayFrom" name="day" min="1" max="31" class="dateInput">
            <label for="monthFrom">Month:</label>
            <input ref="monthFrom" type="number" id="monthFrom" name="month" min="1" max="12" class="dateInput">
            <label for="yearFrom">Year:</label>
            <input ref="yearFrom" type="number" id="yearFrom" name="year" min="1900" class="dateInput">
            <p>to:</p>
            <label for="dayTo">Day:</label>
            <input ref="dayTo" type="number" id="dayTo" name="day" min="1" max="31" class="dateInput">
            <label for="monthTo">Month:</label>
            <input ref="monthTo" type="number" id="monthTo" name="month" min="1" max="12" class="dateInput">
            <label for="yearTo">Year:</label>
            <input ref="yearTo" type="number" id="yearTo" name="year" min="1900" class="dateInput">
        </div>
        <br>
        <p>Should contain a Restriction of Type:</p>
        <div class="restrictionsDiv">
            <div>
                <h4>Gebote</h4>
                <ul class="layerUl" ref="listGebote">
                    <li v-for="item in listRestrictionLayerNamesGebote" :key="'gebot_' + item">
                        <input :id="'gebot_' + item" type="checkbox">
                        <label :for="'gebot_' + item">{{item}}</label>
                    </li>
                </ul>
            </div>
            <div>
                <h4>Verbote</h4>
                <ul class="layerUl" ref="listVerbote">
                    <li v-for="item in listRestrictionLayerNamesVerbote" :key="'verbot_' + item">
                        <input :id="'verbot_' + item" type="checkbox">
                        <label :for="'verbot_' + item">{{item}}</label>
                    </li>
                </ul>
            </div>
        </div>
        <br>
        <LoadingButton @onLoadingButtonClicked="searchForDocumentsInRange" :is-loading="isLoading" caption="Search!"/>
        <br>
        <div v-show="dateTo">
            <p>Searched Documents from {{dateFrom}} to {{dateTo}}.</p>
            <p>Found {{getDocumentsWithDate.dIds.size}} entries!</p>
        </div>
        <br>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import LoadingButton from "@/components/LoadingButton";
import * as CypherQueries from "@/modules/CypherQueries";

export default {
    name: "DocumentFilter",
    components: {LoadingButton},
    data: () => ({
        dateFrom: "",
        dateTo: "",
        isLoading: false, // TODO: Use it!
        restrictionLayer: {},
    }),
    computed: {
        ...mapGetters('neo4jStore', [
            'getDocumentsWithDate'
        ]),
        listRestrictionLayerNamesGebote(){
            if(this.restrictionLayer && this.restrictionLayer.map && this.restrictionLayer.map.Gebot)
                return Object.keys(this.restrictionLayer.map.Gebot);
            return [];
        },
        listRestrictionLayerNamesVerbote(){
            if(this.restrictionLayer && this.restrictionLayer.map && this.restrictionLayer.map.Verbot)
                return Object.keys(this.restrictionLayer.map.Verbot);
            return [];
        },
    },
    mounted() {
        this.restrictionLayer = CypherQueries.getRestrictionLayerData();
    },
    methods: {
        ...mapActions('neo4jStore', [
            'loadDocumentsWithDate'
        ]),
        formateDate(day, month, year){
            if (day && day.length<2){
                day = "0" + day;
            }
            if (month && month.length<2){
                month = "0" + month;
            }
            if (year && year.length<4){
                year = "0" + year;
            }
            return [day, month, year];
        },
        validateInputDate(day, month, year){
            if(!year || year === ""){
                return null;
            }
            if(month && month !== ""){
                if(day && day !== ""){
                    // check date with dd.mm.yyyy
                    const dStr = year+"-"+month+"-"+day;
                    const d = new Date(dStr);
                    return isNaN(d.getTime()) ? null : dStr;
                } else {
                    // check date with mm.yyyy
                    const dStr = year+"-"+month+"-01";
                    const d = new Date(dStr);
                    return isNaN(d.getTime()) ? null : dStr;
                }
            }
            // check date with yyyy
            const dStr = year+"-01-01";
            const d = new Date(dStr);
            return isNaN(d.getTime()) ? null : dStr;
        },
        searchForDocumentsInRange(){
            const verbote = this.$refs.listVerbote.getElementsByTagName("input");
            const verboteSize = verbote.length;
            const verboteLabels = [];
            for (let i=0; i<verboteSize; i++) {
                const el = verbote[i];
                if (el.checked){
                    verboteLabels.push(el.labels[0].innerText);
                }
            }

            const gebote = this.$refs.listGebote.getElementsByTagName("input");
            const geboteSize = gebote.length;
            const geboteLabels = [];
            for (let i=0; i<geboteSize; i++) {
                const el = gebote[i];
                if(el.checked){
                    geboteLabels.push(el.labels[0].innerText);
                }
            }

            let [dayFrom, monthFrom, yearFrom] = this.formateDate(
                this.$refs['dayFrom'].value,
                this.$refs['monthFrom'].value,
                this.$refs['yearFrom'].value
            );
            let [dayTo, monthTo, yearTo] = this.formateDate(
                this.$refs['dayTo'].value,
                this.$refs['monthTo'].value,
                this.$refs['yearTo'].value
            );

            let dateFrom = this.validateInputDate(dayFrom, monthFrom, yearFrom);
            let dateTo = this.validateInputDate(dayTo, monthTo, yearTo);

            if(!dateFrom && !dateTo){
                this.dateFrom = "Invalid Date!";
                this.dateTo = "";
                return;
            }
            if (dateFrom && !dateTo){
                dateTo = dateFrom;
            }
            this.dateFrom = dateFrom;
            this.dateTo = dateTo;
            this.loadDocumentsWithDate({to: dateTo, from: dateFrom, geboteLabels: geboteLabels, verboteLabels: verboteLabels});
        },
    },
}
</script>

<style scoped>
.dateInput{
    max-width: 60px;
    margin-right: 4px;
    margin-left: 4px;
}
.dateDiv{
    padding-left: 10px;
    padding-right: 10px;
}
.layerUl{
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
.restrictionsDiv{
    text-align: left;
    display: flex;
    /*align-items: center;*/
    justify-content: center;
}
</style>