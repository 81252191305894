<template>
    <div class="mainDiv">
        <div class="headerDiv">
            <input type="text" ref="searchString" placeholder="Search for..." class="searchStr" v-model="searchStr" :disabled="isLoading">
            <div class="bestN">
                <label for="bestN">Nur die besten n Treffer anzeigen:</label>
                <input id="bestN" type="number" min="1" max="1000" step="100" v-model="maxResults" :disabled="isLoading">
            </div>

        </div>
        <LoadingButton @onLoadingButtonClicked="search" :is-loading="isLoading" caption="Search!" :is-disabled="searchStr.trim() === ''"/>
        <br>
        <div v-if="error">
            <hr>
            <p>Fehler bei der Datenabfrage!</p>
        </div>
        <div v-if="results.length > 0 && !error">
            <hr>
            <p>Found {{maxHits}} hits, displaying {{hits}} (gathered from {{docsPerPage}} different documents)!</p>
            <div class="tableDiv">
                <table class="resultsTable">
                    <tr class="tableHeader">
                        <td>Link</td>
                        <td>Karte</td>
                        <td>Text im Dokument</td>
                    </tr>
                    <template v-for="(entry, index) in results">
                        <template v-for="(text, tin) in entry.highlights.text">
                            <tr :key="index + '---' + entry.doc + '---' + tin" :class="{trHighlight: index % 2}">
                                <td>
                                    <button class="button" @click="openPDF(entry.doc, text, entry.page);">Open</button>
                                </td>
                                <td><input type="checkbox" :value="entry.doc" v-model="selectedDocIds"></td>
                                <!-- TODO: Text nicht direkt als HTML verwenden, Text splitten und <mark> einfügen! -->
                                <td v-html="text.replace(/[\n\r]/g, '')"></td>
                            </tr>
                        </template>
                    </template>
                </table>
            </div>
            <hr>
            <LoadingButton ref="prevButton" caption="Prev" :is-loading="isLoading" :isDisabled="isPrevButtonDisabled" @onLoadingButtonClicked="changePage(false)" />
            <span>Page {{page}} of {{maxPages}}</span>
            <LoadingButton caption="Next" :is-loading="isLoading" :isDisabled="page === maxPages" @onLoadingButtonClicked="changePage(true)" />
        </div>
    </div>
</template>

<script>

import {mapActions, mapMutations} from "vuex";
import * as ElasticSearchController from "@/modules/ElasticSearchController";
import PDFViewer from "@/components/PDFViewer";
import LoadingButton from "@/components/LoadingButton";

export default {
    name: "DocumentFilter",
    components: {LoadingButton},
    data: () => ({
        results: [],
        selectedDocIds: [],
        docsPerPage: 0,
        maxHits: 0,
        hits: 0,
        searchStr: "",
        searchedStr: "",
        maxResults: 1000,
        maxResultsUsed: 1000,
        page: 1,
        maxPages: 1,
        error: false,
        isLoading: false,
        isPrevButtonDisabled: true,
    }),
    watch: {
        selectedDocIds: function(){
            if(this.selectedDocIds.length === 0) {
                this.loadFeaturesByDocumentIDs(this.selectedDocIds);
            } else {
                this.isLoading = true;
                this.loadFeaturesByDocumentIDs(this.selectedDocIds).then(() => {
                    this.isLoading = false;
                });
            }
        },
    },
    methods: {
        ...mapActions('neo4jStore', [
            'loadFeaturesByDocumentIDs'
        ]),
        ...mapMutations({
            addPDFPath: 'componentsStore/addPDFPath',
            addHighlightText: 'componentsStore/addHighlightText',
            addPageNum: 'componentsStore/addPageNumber'
        }),
        openPDF(path, sentence=null, page = 1) {
            // TODO: Funktion in Store packen, sonst Inhalt immer als Codeduplikat?
            //let finalPath = 'http://127.0.0.1:3030/static/pdfs/' + path;
            //let finalPath = 'http://seneca.informatik.uni-leipzig.de:3030/static/' + path;
            let finalPath = 'https://documents.vda-group.de/static/' + path;
            this.addPDFPath(finalPath);
            if(sentence){
                sentence = sentence.replaceAll("<mark>", "").replaceAll("</mark>", "")
            }
            this.addHighlightText(sentence); // TODO: use Page!
            this.addPageNum(page);
            let routeData = this.$router.resolve({name: 'PDFViewer', params: {pdfID: this.$store.state.componentsStore.pdfPath.length -1}});
            window.open(routeData.href, '_blank');
        },
        changePage(direction){
            if(direction){
                this.page += 1;
                this.isPrevButtonDisabled = false;
            } else {
                this.page -= 1;
                if(this.page <= 1){
                    this.page = 1;
                    this.isPrevButtonDisabled = true;
                }
            }
            let from = (this.page-1) * this.maxResultsUsed;
            this.maxResults = this.maxResultsUsed;
            this.searchInDocuments(this.searchedStr, from, this.maxResultsUsed);
        },
        searchInDocuments(searchStr, from, size){
            this.isLoading = true;
            ElasticSearchController.searchInDocuments(searchStr, from, size).then(data => {
                this.isLoading = false;
                this.results = [];
                this.maxHits = 0;
                this.hits = 0;
                this.docsPerPage = 0;

                if(!data.error) {
                    this.error = false;
                    this.maxHits = data.hits.total.value;
                    this.hits = data.hits.hits.length;
                    this.maxPages = Math.ceil(this.maxHits / size);
                    const docIds = new Set();

                    // [{doc, page, [text]}, {...}]
                    data.hits.hits.forEach(h => {
                        this.results.push({doc: h._source.filename, page: h._source.page, highlights: h.highlight});
                        docIds.add(h._source.filename);
                    });
                    this.docsPerPage = docIds.size;

                } else {
                    this.error = true;
                }
            });
        },
        search(){
            this.searchedStr = this.searchStr;
            this.maxResultsUsed = this.maxResults;
            this.page = 1;
            this.selectedDocIds = [];
            this.searchInDocuments(this.searchedStr, 0, this.maxResultsUsed);
        },
    },
}
</script>

<style scoped>
.searchStr{
    border: 1px solid gray;
}
.mainDiv{
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    padding: 3px;
    max-width: 800px;
}
.trHighlight{
    background-color: #cbcbcb;
}
.tableHeader{
    background-color: darkgray !important;
}
.resultsTable td{
    border: 1px solid #ddd;
}
.resultsTable{
    border-collapse: collapse;
    margin: auto;
}
.resultsTable th{
    background-color: #3F3E3E;
}
.tableDiv{
    overflow-y: scroll;
    max-height: 400px;
    margin-bottom: 3px;
}
.headerDiv {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 600px;
    margin: auto;
}
.bestN {
    display: flex;
    gap: 5px;
}
#bestN {
    flex-grow: 1;
}
</style>