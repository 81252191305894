<template>
    <div v-if="toggleUpdate"
         class="box"
         :class="{
            operatorAnd: node.getCaption() === operatorTypes[0],
            operatorOr: node.getCaption() === operatorTypes[1],
            dragOver: getCurrent() === node.getID() || contextMenu,
         }"
         @drop="(evt) => onDrop(evt, node)"
         @dragover.prevent
         @dragenter="(evt) => onDragEnter(evt, node)"
         @contextmenu="showContextMenu"
    >
        <template v-for="(n, index) in node.getChildNodes()">
            <NodeElement v-if="n.isOperator()" :tree="tree" :node="n" :key="n.getID()"></NodeElement>
            <div v-if="!n.isOperator()" :key="n.getID() + '_filter'"
                 class="filter"
                 @drop="(evt) => onDrop(evt, n)"
                 @dragenter="(evt) => onDragEnter(evt, n)"
                 :class="{
                    dragOver: getCurrent() === n.getID(),
                 }"
                 @contextmenu.prevent
            >
                {{n.getCaption()}}
            </div>
            <div v-if="index < node.getChildNodes().length-1" :key="n.getID() + '_operator'" class="operatorCaption">
                {{node.getCaption()}}
            </div>
        </template>
        <div v-show="contextMenu" ref="contextMenu" class="contextMenu" @mouseleave="closeContextMenu">
            <span>Aktionen:</span>
            <ul>
                <li @click="changeOperatorType">Change Operator Type</li>
                <li @click="deleteNode">Delete Node</li>
            </ul>
        </div>
    </div>
</template>

<script>
import {Node, Tree} from "@/components/MainFilterSelectors/LogicTree";
import {mapGetters, mapMutations} from "vuex";
export default {
    name: "NodeElement",
    data: () => ({
        toggleUpdate: true,
        isDragOver: false,
        contextMenu: false,
    }),
    props: {
        node: {
            type: Node,
            required: true,
        },
        tree: {
            type: Tree,
            required: true,
        },
    },
    computed: {
        updater() {
            return this.getUpdater();
        },
        operatorTypes(){
            return Tree.types;
        }
    },
    watch: {
        updater() {
            this.toggleUpdate = false;
            this.toggleUpdate = true;
        }
    },
    methods: {
        ...mapGetters({
            getUpdater: 'mainFilterStore/getUpdater',
            getCurrent: "mainFilterStore/getCurrent",
        }),
        ...mapMutations({
            setUpdater: 'mainFilterStore/setUpdater',
            setCurrent: 'mainFilterStore/setCurrent',
            setTreeIsEmpty: 'mainFilterStore/setTreeIsEmpty',
        }),
        onDrop(evt, n){
            const data = evt.dataTransfer.getData("tree");
            if(data) {
                const obj = JSON.parse(data);
                this.setCurrent(null);
                this.tree.addOperator(obj.value, n);
                this.setUpdater();
            }
            evt.stopPropagation();
        },
        onDragEnter(evt, n){
            const data = evt.dataTransfer.getData("tree");
            if(data) {
                const obj = JSON.parse(data);
                if(!n.isOperator() && n.getParent().getCaption() === obj.value){
                    this.setCurrent(this.node.getID());
                } else {
                    this.setCurrent(n.getID());
                }
            }
            evt.stopPropagation();
        },
        showContextMenu(evt){
            if(evt.target.classList.contains("box")) {
                this.contextMenu = true;
                //this.$refs.contextMenu.style.left = (evt.target.parentNode.offsetLeft + evt.layerX - 15) + "px";
                //this.$refs.contextMenu.style.top = (evt.target.parentNode.offsetTop + evt.layerY - 15) + "px";
                var rect = evt.target.getBoundingClientRect();
                var x = evt.clientX - rect.left; //x position within the element.
                var y = evt.clientY - rect.top;  //y position within the element.
                this.$refs.contextMenu.style.left = (x - 10) + "px";
                this.$refs.contextMenu.style.top = (y - 10) + "px";
            }
            evt.preventDefault();
            evt.stopPropagation();
        },
        closeContextMenu(){
            this.contextMenu = false;
        },
        changeOperatorType(){
            this.tree.toggleOperatorType(this.node);
            this.contextMenu = false;
            this.setUpdater();
        },
        deleteNode(){
            const deletedRoot = this.tree.deleteNode(this.node);
            if(deletedRoot){
                 this.setTreeIsEmpty(true);
            }
            this.contextMenu = false;
            this.setUpdater();
        }
    }
}
</script>

<style scoped>
.operatorAnd {
    background-color: lightgray !important;
}
.operatorOr {
    background-color: lightyellow !important;
}
.operatorCaption{
    font-weight: bolder;
    color: black;
    pointer-events: none;
}
.filter {
    background-color: darkgray;
    font-weight: bold;
    color: black;
    border: 1px solid black;
    width: max-content;
    min-width: 100px;
}
.box{
    padding: 20px;
    border: 1px solid black;
    background-color: #880e4f;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    position: relative;
}
.dragOver {
    outline: 3px solid red;
    background: repeating-linear-gradient(45deg, #fb8c00, #fb8c00 10px, #e53935 10px, #e53935 20px);
}
.contextMenu{
    display: block;
    position: absolute;
    border: 1px solid darkgray;
    background-color: snow;
    width: max-content;
    padding: 5px;
}
.contextMenu > ul{
    list-style: disclosure-closed;
}
li:hover{
    background-color: lightblue;
    cursor: pointer;
}
</style>