<template>
    <div>
        <v-overlay absolute v-if="loading"> loading ...</v-overlay>
        <v-flex>
            <button class="button" @click="filterByRegion">Filter By Region</button>
            <button class="button" @click="filterByTime">Filter By Time</button>
        </v-flex>
        <div id='timeline-container' class="timeline-container">
            <span id="filter-menu"></span>

        </div>
    </div>
</template>

<script>
import * as d3 from 'd3'
import 'datejs'
import {mapActions, mapGetters} from "vuex";
/*
zeige was im akutellen Kartenausschnitt vorkommt - erstmal für eine Karten

Visualisiere:
  - Dokumente
  - Kategorien

-> wann wurden Verbote/Gebote angezeigt
-> wann tretten Personen auf

-> kommuniziere Duplikate und Dokumente ohne Datum
-> Editor für automatisch Generierte Daten in der Rechten Ansicht
  Topics, Personen, Zeit etc.
*/
export default {
    name: 'Timeline',
    props: ["mkey"],
    data () {
        return {
            minYear: 1900,
            maxYear: 2100,
            maxValue: 0,
            legendYOffset: 10,
            margin: {top: 80, right: 20, bottom: 20, left: 20},
            zoomScaleExtent: [1, 365],
            timeScale: {},
            originaltimeScale: {},
            filterRange: [],
            currentFilter: {},
            brush: null,
            loading: true,
            MS_PER_DAY: 86400000,
            MS_PER_HOUR: 3600000,
            MS_PER_YEAR: 31556736000
        }
    },
    //TODO prüfen ob am rand alles selektiert werden kann
    mounted () {
        this.width = document.getElementById('timeline-container').offsetWidth
        this.height = document.getElementById('timeline-container').clientHeight
        //this.loadDates().then(() => {
        this.loadDatesEnvirVis().then(() => {
            this.loading = false
            this.prepareData(this.getDates)
        })
    },
    computed: {
        ...mapGetters('neo4jStore', [
            'getDates',
            'getDocumentsWithDate'
        ]),
        ...mapGetters('mapStore', [
            'getView',
            'getMap'
        ])
    },
    methods: {
        ...mapActions('neo4jStore', [
            'loadDates',
            'loadDatesEnvirVis',
            'loadDocumentsWithDateById',
            'loadFeaturesInRegion',
            'loadFeaturesInRegionEnvirVis'
        ]),
        // handle metadata like persons and companies
        buildData(data) {
            let res = {}
            let personDict = {}
            let companyDict = {}
            let gebote = {}
            let verbote = {}
            let types = {}
            for (let entry of data) {
                let doc = entry.doc
                let dates = entry.dates
                let des = entry.description
                let tags = entry.tags
                let neighbors = entry.neighbors
                for (let n of neighbors) {
                    if (n.label === "Person") {
                        if (personDict[n.name]) {
                            personDict[n.name] += 1
                        } else {
                            personDict[n.name] = 1
                        }
                    } else if(n.label === "Company") {
                        if (companyDict[n.name]) {
                            companyDict[n.name] += 1
                        } else {
                            companyDict[n.name] = 1
                        }
                    } else {
                        if (n.type === "gebot") {
                            if (gebote[n.label]) {
                                gebote[n.label] += 1
                            } else {
                                gebote[n.label] = 1
                            }
                        } else if (n.type === "verbot") {
                            if (verbote[n.label]) {
                                verbote[n.label] += 1
                            } else {
                                verbote[n.label] = 1
                            }
                        } else {
                            if (types[n.label]) {
                                types[n.label] += 1
                            } else {
                                types[n.label] = 1
                            }
                        }
                    }
                }
            }
            let tags = {"Gebote": gebote, "Verbote": verbote, "Person": personDict, "Companies": companyDict, "Document Types": types}
            //console.log(Object.entries(personDict), Object.entries(companyDict), Object.entries(gebote), Object.entries(verbote), Object.entries(types))
            return tags
        },
        countDates(data) {
            let freqDict = {}
            for (let d of data) {
                for (let entry of d["dates"]) {
                    let key = entry["year"] + "-" + entry["month"] + "-" + entry["day"]
                    if (key in freqDict) {
                        freqDict[key]["count"] += 1
                    } else {
                        freqDict[key] = {"count": 1, "docs": [], "uncertainDays": 0, "uncertainMonths": 0}
                    }
                    if (entry.uncertainDay) {
                        freqDict[key]["uncertainDays"] += 1
                    }
                    if (entry.uncertainMonth) {
                        freqDict[key]["uncertainMonths"] += 1
                    }
                    freqDict[key]["docs"].push({"doc": d.doc, "des": d.des, "neighbors": d.neighbors, "tags": d.tags})
                }
            }
            return freqDict
        },
        // TODO checkbox, layer filter, viewport filter und ein filter button
        filterByRegion() {
            let view = this.getView
            let map = this.getMap
            let layers = map.getLayers()
            let layerNames = []
            for(let i=0;i<layers.getLength();i++){
                let name = layers.item(i).get('name')
                if (name) {
                    layerNames.push(layers.item(i).get('name'))
                }
            }
            //TODO wirklich neu bauen oder einfach filtern?
            this.loadFeaturesInRegionEnvirVis({"extent": view.calculateExtent(map.getSize()), "layerNames": layerNames}).then(() => {
                this.loading = false
                d3.select("#timeline").remove()
                //apply gebot verbot filter
                let data = this.applyRestrictions(this.getDates, Object.keys(this.currentFilter))
                this.prepareData(data)
            })
        },
        filterByTime() {
            let newMinYear = this.filterRange[0]
            let newMaxYear = this.filterRange[1]
            d3.select("#timeline").remove()
            this.buildTimeline(newMinYear, newMaxYear)
        },
        prepareData(data, minYear=null, maxYear=null) {
            let vm = this
            if (data.length === 0) {
                return
            }
            let tags = this.buildData(data)
            let freqDict = this.countDates(data)
            let d3Data = Object.entries(freqDict).map(x => ({"date": new Date(x[0]), "docs": x[1].docs, "count": x[1].count, "uncertainDays": x[1].uncertainDays, "uncertainMonths": x[1].uncertainMonths}))
            this.data = d3Data
            this.tags = tags
            this.freqDict = freqDict
            this.buildTimeline()
        } ,
        buildTimeline(minYear=null, maxYear=null) {
            let vm = this
            let d3Data = this.data
            if (minYear && maxYear) {
                //time filter
                vm.minYear = minYear
                vm.maxYear = maxYear
                d3Data = d3Data.filter(x => x.date >= minYear && x.date <= maxYear)
            } else {
                let years = Object.keys(this.freqDict).sort(function(a, b) {
                    return a.localeCompare(b);
                })
                vm.minYear = years[0]
                vm.maxYear = years[years.length-1]
            }

            vm.filterRange = [new Date(vm.minYear), new Date(vm.maxYear)]
            //console.log(d3Data.filter(x => x.uncertainMonths > 0))
            //console.log(d3Data.filter(x => x.uncertainDays > 0))

            let svg = d3.select("#timeline-container")
                .append("svg")
                .attr("id", "timeline")
                .attr("viewBox", [0, 0, this.width, this.height])
            vm.buildZoomLegend(d3Data)
        },
        buildFilterLegend() {
            //TODO baue kleine histogramme oder etwas was die auswirkung beim filter zeigt?
            let vm = this
            let tags = this.tags
            //with scroll bar -> wie map
            let gebote = Object.entries(tags["Gebote"])
            for (let g of gebote) {
                g.push("Gebot")
            }
            let verbote = Object.entries(tags["Verbote"])
            for (let v of verbote) {
                v.push("Verbot")
            }

            d3.select("#filter-menu")
                .style("width", this.width + "px")
                .style("max-width", this.width + "px")
                .selectAll(".filter-labels")
                .data(gebote.concat(verbote))
                .enter()
                .append('label')
                .attr('for',function(d,i){ return 'a'+i; })
                .attr("class", "filter-labels")
                .text(function(d) {
                    return d[2] + ": " +  d[0] + " (" + d[1] + ")";
                })
                .append("input")
                .attr("type", "checkbox")
                .attr("id", function(d,i) { return 'a'+i; })
                .attr("class", "filter-boxes")
                .on("click", function(e,d) {
                    vm.change(d, this)
                })
                .style("margin-left", "3px")
                .style("margin-right", "8px")

        },
        //TODO union oder intersect? -> akutell union
        applyRestrictions(data, filters) {
            //console.log(data)
            if (filters.length === 0) {
                return data
            }
            data = data.filter(function(x) {
                if (x.neighbors.length === 0) {
                    return false
                }
                let labels = x.neighbors.map(x => x.label)
                if (labels.some(r=> filters.indexOf(r) >= 0)) {
                    return true
                }
                return false
            })
            //console.log(data)
            return data
        },
        change(d, el) {
            let key = d[0]
            //console.log(el.checked, key)
            if (el.checked) {
                this.currentFilter[key] = 1
            } else {
                delete this.currentFilter[key]
            }
            let filters = Object.keys(this.currentFilter)
            let data = this.getDates
            d3.select("#timeline").remove()
            if (filters.length > 0) {
                //apply filter
                data = this.applyRestrictions(data, filters)
                this.prepareData(data)
                // TODO andere Methoden anpassen (prepare und build) -> einheitlicher daten fluss
            } else {
                this.prepareData(data, this.filterRange[0], this.filterRange[1])
                //this.buildTimeline(this.filterRange[0], this.filterRange[1])
            }
            // -> einfacher anders herm, filter nach allem was nicht ausgewählt ist und entferne das?

        },
        buildScale(minYear, maxYear) {
            const nowDate = new Date(minYear);
            const untilDate = new Date(maxYear);

            this.timeScale = d3.scaleUtc().domain([nowDate, untilDate]).range([this.margin.left, this.width - this.margin.right]);
        },
        buildZoomLegend(data) {
            let vm = this
            this.buildScale(vm.minYear, vm.maxYear)
            let axis = {};
            let nodes = {};
            this.originaltimeScale = this.timeScale.copy();

            this.zoomScaleExtent = [1, Math.round((Math.abs(this.timeScale.domain()[0] - this.timeScale.domain()[1]) / vm.MS_PER_DAY) * 0.033)]

            const parts = ["yearly","daily", "grid", "yearlyGrid"];
            const findDensityConfig = (map, value) => {
                for (const [limit, config] of map) {
                    if (value < limit) {
                        return config;
                    }
                }
                return [];
            };

            const ensureTimeFormat = (value = "") => {
                return typeof value !== "function" ? d3.utcFormat(value) : value;
            };

            axis["yearly"] = (parentNode, density) => {
                //density threshold, tick-function/interval, string format
                const densityMap = [
                    [3,[d3.utcMonth,
                        (d) => {
                            const startOfTheYear =
                                d.getUTCMonth() === 0 && d.getUTCDate() === 1;
                            const format = startOfTheYear ? "%Y – %B" : "%B";

                            return d3.utcFormat(format)(d);
                        },
                    ],
                    ],
                    [250, [d3.utcYear, "%Y"]],
                    [1000, [ d3.utcYear.every(5), "%Y"]],
                    [2000, [ d3.utcYear.every(10), "%Y"]],
                    [6000, [ d3.utcYear.every(50), "%Y"]],
                    [Infinity, [d3.utcYear.every(100), "%Y"]],
                ];

                let [interval, format] = findDensityConfig(densityMap, density);
                format = ensureTimeFormat(format);

                const el = parentNode
                    .attr("transform", `translate(0,${this.margin.top - 48 + this.legendYOffset})`)
                    .call(
                        d3
                            .axisTop(this.timeScale)
                            .ticks(interval)
                            .tickFormat(format)
                            .tickSizeOuter(0)
                    );

                el.select(".domain").remove();

                el.selectAll("text")
                    .attr("y", 0)
                    .attr("x", 6)
                    .style("text-anchor", "start");

                el.selectAll("line").attr("y1", -7).attr("y2", 12)
            };

            axis["daily"] = (parentNode, density) => {
                //density threshold, tick-function/interval, string format
                const densityMap = [
                    [1, [d3.utcDay, "%-d"]],
                    [3, [d3.utcDay, ""]],
                    [8, [d3.utcMonth, "%B"]],
                    [13, [d3.utcMonth, "%b"]],
                    [22, [d3.utcMonth, (d) => d3.utcFormat("%B")(d).charAt(0)]],
                    [100, [d3.utcMonth.every(3), "Q%q"]],
                    [250, [d3.utcMonth.every(3), ""]],
                    [500, [ d3.utcYear.every(2), ""]],
                    [1000, [ d3.utcYear.every(5), ""]],
                    [2000, [ d3.utcYear.every(10), ""]],
                    [4000, [ d3.utcYear.every(25), ""]],
                    [6000, [ d3.utcYear.every(50), ""]],
                    [Infinity, [d3.utcYear.every(100), ""]],
                ];

                let [interval, format] = findDensityConfig(densityMap, density);
                format = ensureTimeFormat(format);

                const el = parentNode
                    .attr("transform", `translate(0,${this.margin.top - 28 + this.legendYOffset})`)
                    .call(
                        d3
                            .axisTop(this.timeScale)
                            .ticks(interval)
                            .tickFormat(format)
                            .tickSizeOuter(0)
                    );

                el.select(".domain").remove();

                el.selectAll("text")
                    .attr("y", 0)
                    .attr("x", 6)
                    .style("text-anchor", "start");

                el.selectAll("line").attr("y1", 0).attr("y2", 12).attr("id", (d,i) => "legend-line-" + i)
            };

            axis["grid"] = (parentNode, density) => {
                const densityMap = [
                    [1, [d3.utcDay]],
                    [8, [d3.timeMonday]], //TODO das wochen level noch nehmen?
                    [22, [d3.utcMonth]],
                    [100, [d3.utcMonth.every(3)]],
                    [250, [d3.utcMonth.every(3)]],
                    [500, [ d3.utcYear.every(2)]],
                    [1000, [ d3.utcYear.every(5)]],
                    [2000, [ d3.utcYear.every(10)]],
                    [4000, [ d3.utcYear.every(25)]],
                    [6000, [ d3.utcYear.every(50)]],
                    [Infinity, [d3.utcYear.every(100),]],
                ];

                const [interval] = findDensityConfig(densityMap, density);

                const el = parentNode
                    .attr("transform", `translate(0,${this.margin.top + this.legendYOffset})`)
                    .call(d3.axisTop(this.timeScale).ticks(interval).tickSizeOuter(0));

                el.select(".domain").remove();
                el.selectAll("text").remove();

                el.selectAll("line")
                    .attr("y1", 0)
                    .attr("y2", this.height - this.margin.top - this.margin.bottom - this.legendYOffset);
            }

            axis["yearlyGrid"] = (parentNode, density) => {
                const densityMap = [
                    [3, [d3.utcMonth, "%B"]],
                    [250, [d3.utcYear, "%Y"]],
                    [1000, [ d3.utcYear.every(5), "%Y"]],
                    [2000, [ d3.utcYear.every(10), "%Y"]],
                    [6000, [ d3.utcYear.every(50), "%Y"]],
                    [Infinity, [d3.utcYear.every(100), "%Y"]],
                ];

                let [interval, format] = findDensityConfig(densityMap, density);
                format = ensureTimeFormat(format);

                const el = parentNode
                    .attr("transform", `translate(0,${this.margin.top + this.legendYOffset})`)
                    .call(
                        d3
                            .axisTop(this.timeScale)
                            .ticks(interval)
                            .tickFormat(format)
                            .tickSizeOuter(0)
                    );

                el.select(".domain").remove();
                el.selectAll("text").remove();

                el.selectAll("line")
                    .attr("y1", 0)
                    .attr("y2", this.height - this.margin.top - this.margin.bottom - this.legendYOffset)
                    .attr("stroke-opacity", function(d) {
                        let year = d.getFullYear()
                        if (density >= 2000 && year % 100 != 0) {
                            return 0
                        }
                        if (density >= 250 && year % 10 != 0) {
                            return 0
                        }
                        return 1
                    });
            };

            const setup = () => {

                vm.buildFilterLegend()
                const svg = d3.select("#timeline")
                const element = svg.node();
                const rootNode = svg.append("g").classed("timeline-axis", true);

                parts.forEach((part) => {
                    nodes[part] = rootNode.append("g").classed(part, true);
                });

                let g = svg.append("g").attr("id", "g-timeline");

                const update = () => {
                    const density = Math.abs(this.timeScale.invert(0) - this.timeScale.invert(1)) / vm.MS_PER_HOUR; // in pixels per hour
                    parts.forEach((part) => {
                        nodes[part].call(axis[part], density);
                    });

                    //filter data based on current range
                    let currentRange = this.timeScale.domain()
                    let localData = data.filter(x => x.date >= currentRange[0] && x.date <= currentRange[1] )
                    // cluster dates based on density
                    if (density >= 8) {
                        localData = vm.clusterBasedOnDensity(localData, density).sort(function(a,b){
                            return a.date - b.date
                        })
                        if (density < 100 ) {
                            //console.log(localData)
                            localData.forEach(function(x) {
                                x.count = x.count - x.uncertainMonths
                            })
                            //console.log(localData)
                        }
                    } else {
                        localData.forEach(function(x) {
                            x.count = x.count - x.uncertainDays
                        })
                        //TODO nach doc duplikaten prüfen?
                    }
                    let maxValue = Math.max(...localData.map(x => x.count))
                    //console.log(maxValue, Math.max(...localData.map(x => x.count + x.uncertainMonths + x.uncertainDays)))

                    let rectScale = d3.scaleLinear()
                        .range([vm.height-vm.margin.bottom, vm.margin.top + vm.legendYOffset])
                        .domain([0, maxValue])

                    // build timeline
                    d3.select("#g-timeline").selectAll("*").remove()
                    d3.select("#brush-timeline").remove()
                    g.append('g').call(d3.axisRight(rectScale).ticks(3))
                        .attr('transform', `translate(${this.timeScale.range()[0]},0)`)
                        .selectAll("text")
                        .style("stroke", "white")
                        .style("fill", "black")
                        .style("stroke-width", "3px")
                        .style("paint-order", "stroke")

                    vm.buildRectTimeline(g, localData, maxValue, rectScale, density)
                    vm.buildBrush(svg)
                };

                const { zoom, reset } = this.zoomPlugin(svg,
                    (e) => {
                        if (! (e.sourceEvent.target instanceof HTMLDocument)) {
                            let className = d3.select(e.sourceEvent.target).attr("class")
                            // disable zoom for brushing area - prevent weird behaviour in combination with the brushing
                            if (className && (className == "selection" || className == "overlay" || className.indexOf("handle") !== -1)) {
                                return
                            }
                        }
                        this.timeScale = e.transform.rescaleX(this.originaltimeScale);
                        update();
                    }
                );
                update();
                return {
                    element,
                    update,
                    reset,
                };
            };
            setup();
        },
        // TODO better in backend?
        clusterBasedOnDensity(data, density) {
            let timeDict = {}
            let docDict = {}
            let quartalMap = {"1": "1", "2": "1", "3": "1","4": "4","5": "4","6": "4","7": "7","8": "7","9": "7","10": "10","11": "10","12": "10"}
            if (density >= 6000) {
                // jahrhundert
                // merge all einträge aus dem gleichen jahrzehnt
                for (let entry of data) {
                    let targetKey = Math.round(entry.date.getFullYear() / 100) * 100
                    targetKey += "-1-1"
                    if (!timeDict[targetKey]) {
                        docDict[targetKey] = {}
                        let tempDocs = entry.docs.filter(function(value, index, self) {
                            docDict[targetKey][value.doc] = 1
                            return index === self.findIndex((t) => (
                                t.doc === value.doc
                            ))
                        })
                        timeDict[targetKey] = {"docs": tempDocs, "count": tempDocs.length}
                    } else {
                        // zähle jedes dokument nur einmal pro zeiteinheit
                        let newDocs = entry.docs.filter(function(x) {
                            if (docDict[targetKey][x.doc]) {
                                return false
                            }
                            docDict[targetKey][x.doc] = 1
                            return true
                        })
                        timeDict[targetKey]["docs"] = timeDict[targetKey]["docs"].concat(newDocs)
                        timeDict[targetKey]["count"] = timeDict[targetKey]["docs"].length
                    }
                }
            } else if (density >= 1000) {
                // decade
                // merge all einträge aus dem gleichen jahrzehnt
                for (let entry of data) {
                    let targetKey = Math.round(entry.date.getFullYear() / 10) * 10
                    targetKey += "-1-1"
                    if (!timeDict[targetKey]) {
                        docDict[targetKey] = {}
                        let tempDocs = entry.docs.filter(function(value, index, self) {
                            docDict[targetKey][value.doc] = 1
                            return index === self.findIndex((t) => (
                                t.doc === value.doc
                            ))
                        })
                        timeDict[targetKey] = {"docs": tempDocs, "count": tempDocs.length}
                    } else {
                        // zähle jedes dokument nur einmal pro zeiteinheit
                        let newDocs = entry.docs.filter(function(x) {
                            if (docDict[targetKey][x.doc]) {
                                return false
                            }
                            docDict[targetKey][x.doc] = 1
                            return true
                        })
                        timeDict[targetKey]["docs"] = timeDict[targetKey]["docs"].concat(newDocs)
                        timeDict[targetKey]["count"] = timeDict[targetKey]["docs"].length
                    }
                }
            } else if( density >= 100) {
                //year
                for (let entry of data) {
                    let targetKey = entry.date.getFullYear()
                    targetKey += "-1-1"
                    if (!timeDict[targetKey]) {
                        docDict[targetKey] = {}
                        let tempDocs = entry.docs.filter(function(value, index, self) {
                            docDict[targetKey][value.doc] = 1
                            return index === self.findIndex((t) => (
                                t.doc === value.doc
                            ))
                        })
                        timeDict[targetKey] = {"docs": tempDocs, "count": tempDocs.length}
                    } else {
                        // zähle jedes dokument nur einmal pro zeiteinheit
                        let newDocs = entry.docs.filter(function(x) {
                            if (docDict[targetKey][x.doc]) {
                                return false
                            }
                            docDict[targetKey][x.doc] = 1
                            return true
                        })
                        timeDict[targetKey]["docs"] = timeDict[targetKey]["docs"].concat(newDocs)
                        timeDict[targetKey]["count"] = timeDict[targetKey]["docs"].length
                    }
                }
            } else if (density >= 22) {
                //quartal
                //duplikate beachten, und dass ein dokument sowohl uncertain als certain dates haben kann
                // eigentlich exisitiert der fall mit uncertain months aktuell nicht

                //TODO uncertainMonth info ist hier gar nicht gespeichert - danach nochmal prüfen, ob es den fall wirklich nicht gibt -> dann tag berechnung prüfen
                for (let entry of data) {
                    let targetKey = entry.date.getFullYear()
                    targetKey += "-" + quartalMap[entry.date.getMonth() + 1]
                    targetKey += "-1"
                    if (!timeDict[targetKey]) {
                        docDict[targetKey] = {}
                        let tempDocs = entry.docs.filter(function(value, index, self) {
                            if (docDict[targetKey][value.doc]) {
                                docDict[targetKey][value.doc]["uncertain"] = docDict[targetKey][value.doc]["uncertain"] && value.uncertainMonth
                            }
                            docDict[targetKey][value.doc] = {"uncertain": value.uncertainMonth}
                            return index === self.findIndex((t) => (
                                t.doc === value.doc
                            ))
                        })
                        let countUncertain = Object.values(docDict[targetKey]).filter(x => x.uncertain).length
                        timeDict[targetKey] = {"docs": tempDocs, "count": tempDocs.length, "uncertainMonths": countUncertain }
                    } else {
                        // zähle jedes dokument nur einmal pro zeiteinheit
                        let newDocs = entry.docs.filter(function(x) {
                            if (docDict[targetKey][x.doc]) {
                                docDict[targetKey][x.doc]["uncertain"] = docDict[targetKey][x.doc]["uncertain"] && x.uncertainMonth
                                return false
                            }
                            docDict[targetKey][x.doc] = {"uncertain": x.uncertainMonth}
                            return true
                        })
                        timeDict[targetKey]["uncertainMonths"] = Object.values(docDict[targetKey]).filter(x => x.uncertain).length
                        timeDict[targetKey]["docs"] = timeDict[targetKey]["docs"].concat(newDocs)
                        timeDict[targetKey]["count"] = timeDict[targetKey]["docs"].length

                    }
                }
            } else if (density >= 8) {
                //month
                for (let entry of data) {
                    let targetKey = entry.date.getFullYear()
                    targetKey += "-" + (entry.date.getMonth() + 1)
                    targetKey += "-1"
                    if (!timeDict[targetKey]) {
                        docDict[targetKey] = {}
                        let tempDocs = entry.docs.filter(function(value, index, self) {
                            if (docDict[targetKey][value.doc]) {
                                docDict[targetKey][value.doc]["uncertain"] = docDict[targetKey][value.doc]["uncertain"] && value.uncertainMonth
                            }
                            docDict[targetKey][value.doc] = {"uncertain": value.uncertainMonth}
                            return index === self.findIndex((t) => (
                                t.doc === value.doc
                            ))
                        })
                        let countUncertain = Object.values(docDict[targetKey]).filter(x => x.uncertain).length
                        timeDict[targetKey] = {"docs": tempDocs, "count": tempDocs.length, "uncertainMonths": countUncertain }
                    } else {
                        // zähle jedes dokument nur einmal pro zeiteinheit
                        let newDocs = entry.docs.filter(function(x) {
                            if (docDict[targetKey][x.doc]) {
                                docDict[targetKey][x.doc]["uncertain"] = docDict[targetKey][x.doc]["uncertain"] && x.uncertainMonth
                                return false
                            }
                            docDict[targetKey][x.doc] = {"uncertain": x.uncertainMonth}
                            return true
                        })
                        timeDict[targetKey]["uncertainMonths"] = Object.values(docDict[targetKey]).filter(x => x.uncertain).length
                        timeDict[targetKey]["docs"] = timeDict[targetKey]["docs"].concat(newDocs)
                        timeDict[targetKey]["count"] = timeDict[targetKey]["docs"].length
                    }
                }
            }
            data = Object.entries(timeDict).map(x => ({"date": new Date(x[0]), "docs": x[1].docs, "count": x[1].count, "uncertainDays": 0, "uncertainMonths": (x[1].uncertainMonths ? x[1].uncertainMonths : 0)}))
            return data
        },
        getStrokeWidth(density, date) {
            let date2 = this.getNextBarDate(density, date)
            date2.addDays(1)
            let strokeWidth = this.timeScale(date2) - this.timeScale(date)
            return strokeWidth
        },
        zoomPlugin(svg, zoomed) {
            const zoom = d3.zoom()
                .scaleExtent(this.zoomScaleExtent)
                .extent([[this.margin.left, 0], [this.width - this.margin.right, 0]])
                .translateExtent([
                    [this.margin.left, 0],
                    [this.width - this.margin.right, 0]
                ])
                .filter(filter)
                .on("zoom", zoomed);

            svg.call(zoom);

            function reset() {
                svg.transition()
                    .duration(750)
                    .call(zoom.transform, d3.zoomIdentity);
            }

            // prevent scrolling then apply the default filter
            function filter(event) {
                event.preventDefault();
                return (!event.ctrlKey || event.type === 'wheel') && !event.button;
            }

            return {
                zoom,
                reset
            };
        },
        getNextBarDate(density, date) {
            let date2 = new Date(date)
            if (density >= 6000) {
                // jahrhundert
                date2 = new Date(date).addYears(100).addDays(-1)
            }
            else if (density >= 1000) {
                // decade
                date2 = new Date(date).addYears(10).addDays(-1)
            } else if( density >= 100) {
                //year
                date2 = new Date(date).addYears(1).addDays(-1)
            } else if (density >= 22) {
                date2 = new Date(date).addMonths(3).addDays(-1)
            }
            else if (density >= 8) {
                //month
                date2 = new Date(date).addMonths(1).addDays(-1)
            }
            return date2
        },
        buildRectTimeline(g, data, maxValue, rScale, density) {
            // uncertain meistens leer -> wird nur gezählt wenn ein dokument zu diesem zeitpunkt kein certain date hat
            //console.log(data.filter(x => x.uncertainDays > 0 || x.uncertainMonths > 0)) //für months, quartal
            //console.log(data.map(x => x.uncertainMonths)) //für years, decades
            let vm = this

            let bars = g.selectAll("rect.bars-timeline")
                .data(data)
                .enter()
                .append("rect")
                .attr("x", d => vm.timeScale(d.date))
                .attr("width", d => Math.floor(vm.getStrokeWidth(density, d.date)))
                .attr("y", d => rScale(d.count))
                .attr("height", function(d) {
                    //TODO add a minimum height?
                    let height = vm.height-vm.margin.bottom - rScale(d.count)
                    //console.log(height, rScale(d.count))
                    return height
                })
                .attr("strokeWidth", 1)
                .attr("class", "bars-timeline")
                .attr("cursor", "pointer")
                .attr("stroke", "#55C8A9")
                .attr("stroke-opacity", 0.8)
                .attr("fill-opacity", 0.4)
                .attr("fill", "#55C8A9")

            bars.append("title")
                .text(d => d.date.toISOString().split("T")[0] + " Anzahl Dokumente: " + d.count);

            /*if (density < 100) {
               g.selectAll("rect.bars-timeline-uncertain")
                .data(data)
                .enter()
                .append("rect")
                .attr("x", d => vm.timeScale(d.date))
                .attr("width", vm.strokeWidth)
                .attr("y", d => rScale(d.count) + rScale(d.uncertainMonths))
                .attr("height", function(d) {
                  let height = vm.height - vm.margin.bottom
                  if (density >= 8) {
                    height -= rScale(d.uncertainMonths)
                  console.log(height, rScale(d.uncertainMonths))
                  } else {
                    height -= rScale(d.uncertainDays)
                    console.log(height, rScale(d.uncertainDays))
                  }
                  return height
                })
                .attr("strokeWidth", 1)
                .attr("class", "bars-timeline-uncertain")
                .attr("cursor", "pointer")
                .attr("stroke", "blue")
                .attr("stroke-opacity", 0.8)
                .attr("fill-opacity", 0.4)
                .attr("fill", "blue")
            }*/

            /*bars.on("mouseover", function(e, d) {
              console.log(d)
              vm.parseNeighbors(d.docs)
            })*/

            bars.on("click", function(e,d) {
                //setze den slider auf den bar und lade documents
                vm.moveBrushOnClick(d.date)
            })
        },
        parseNeighbors(data) {
            let personDict = {}
            let companyDict = {}
            let gebote = {}
            let verbote = {}
            let types = {}

            for (let entry of data) {
                let neighbors = entry.neighbors
                for (let n of neighbors) {
                    if (n.label === "Person") {
                        if (personDict[n.name]) {
                            personDict[n.name] += 1
                        } else {
                            personDict[n.name] = 1
                        }
                    } else if(n.label === "Company") {
                        if (companyDict[n.name]) {
                            companyDict[n.name] += 1
                        } else {
                            companyDict[n.name] = 1
                        }
                    } else {
                        if (n.type === "gebot") {
                            if (gebote[n.label]) {
                                gebote[n.label] += 1
                            } else {
                                gebote[n.label] = 1
                            }
                        } else if (n.type === "verbot") {
                            if (verbote[n.label]) {
                                verbote[n.label] += 1
                            } else {
                                verbote[n.label] = 1
                            }
                        } else {
                            if (types[n.label]) {
                                types[n.label] += 1
                            } else {
                                types[n.label] = 1
                            }
                        }
                    }
                }
            }
            console.log(Object.entries(personDict), Object.entries(companyDict), Object.entries(gebote), Object.entries(verbote), Object.entries(types))
        },
        moveBrushOnClick(date) {
            let vm = this
            let startDate = date.getFullYear()  + "-" + (date.getMonth() + 1)  + "-" + (date.getDate())
            let density = Math.abs(vm.timeScale.invert(0) - vm.timeScale.invert(1)) / vm.MS_PER_HOUR
            let date2 = vm.getNextBarDate(density, date)
            let d0 = [date, date2]
            let endDate = date2.getFullYear()  + "-" + (date2.getMonth() + 1)  + "-" + (date2.getDate())
            vm.filterRange = d0
            //TODO include other filter, restrictions and geo?
            // das sind die ids für die, die restrictions gelten
            let ids = this.applyRestrictions(this.getDates, Object.keys(this.currentFilter)).map(x => x.doc)
            vm.loadDocumentsWithDateById({to: endDate, from: startDate, ids: ids, brutzeiten: this.currentFilter["Brutzeiten"]}).then(() => {
                let docs = vm.getDocumentsWithDate
                let selectedFeatures = {}
                let layerName = "documentWithDate"
                let f = docs.layer.getSource().getFeatures()
                selectedFeatures[layerName] = {layer: docs.layer, features: f, counter: f.length };
                // wenn bar selektiert zeige sachen rechts an
                this.$parent.$parent.$refs['infoPanel'].displayFeatures(selectedFeatures)
            });

            // move visible handlers
            d3.selectAll('g.handles')
                .attr('transform', d => {
                    const x = d == 'handle--o' ? vm.timeScale(d0[0]) : vm.timeScale(d0[1]);
                    return `translate(${x}, 0)`;
                });

            // update labels
            d3.selectAll('g.handles')
                .selectAll('text')
                //.attr('dx', d0.length > 1 ? 0 : 6)
                .text((d, i) => {
                    let year;
                    if (d0.length > 1) {
                        year = d == 'handle--o' ? d0[0] : d0[1];
                    } else {
                        year = d == 'handle--o' ? d0[0] : '';
                    }
                    return year.toISOString().split("T")[0];
                })

            // update brush
            d3.select("#brush-timeline").call(vm.brush.move, [vm.timeScale(d0[0]), vm.timeScale(d0[1])]);

            // update bars
            d3.selectAll('.bars-timeline')
                .attr('opacity', function(d) {
                    if (d.date >= d0[0] && d.date <= d0[1]) {
                        return 1
                    }
                    return 0.2
                })
        },
        buildBrush(svg) {
            let vm = this
            const brushing = function(event) {
                // based on: https://bl.ocks.org/mbostock/6232537
                if (!event.selection && !event.sourceEvent) return;
                const s0 = event.selection ? event.selection : [1, 2].fill(event.sourceEvent.offsetX),
                    d0 = filteredDomain(vm.timeScale, ...s0);
                let s1 = s0;

                vm.filterRange = d0
                if (event.sourceEvent && event.type === 'end') {
                    // load document layer
                    let startDate = d0[0].getFullYear()  + "-" + (d0[0].getMonth() + 1)  + "-" + (d0[0].getDate())
                    let endDate = d0[1].getFullYear()  + "-" + (d0[1].getMonth() + 1)  + "-" + (d0[1].getDate() )
                    let ids = vm.applyRestrictions(vm.getDates, Object.keys(vm.currentFilter)).map(x => x.doc)
                    vm.loadDocumentsWithDateById({to: endDate, from: startDate, ids: ids, brutzeiten: vm.currentFilter["Brutzeiten"]}).then(() => {
                        let docs = vm.getDocumentsWithDate
                        let selectedFeatures = {}
                        let layerName = "documentWithDate"
                        let f = docs.layer.getSource().getFeatures()
                        selectedFeatures[layerName] = {layer: docs.layer, features: f, counter: f.length };
                        // wenn bar selektiert zeige sachen rechts an
                        vm.$parent.$parent.$refs['infoPanel'].displayFeatures(selectedFeatures)
                    });
                }

                // move handlers
                d3.selectAll('g.handles')
                    .attr('transform', d => {
                        const x = d == 'handle--o' ? s1[0] : s1[1];
                        return `translate(${x}, 0)`;
                    });

                // update labels
                d3.selectAll('g.handles')
                    .selectAll('text')
                    .text((d, i) => {
                        let year;
                        if (d0.length > 1) {
                            year = d == 'handle--o' ? d0[0] : d0[1];
                        } else {
                            year = d == 'handle--o' ? d0[0] : '';
                        }
                        return year.toISOString().split("T")[0];
                    })

                // update bars
                d3.selectAll('.bars-timeline')
                    .attr('opacity', function(d) {
                        if (d.date >= d0[0] && d.date <= d0[1]) {
                            return 1
                        }
                        return 0.2
                    })
            }
            const filteredDomain = function(scale, min, max) {
                let iMin = scale.invert(min)
                let iMax = scale.invert(max)
                if (iMin == iMax) {
                    iMin -= 1
                }
                return [iMin, iMax]
            }

            vm.brush = d3.brushX()
                .handleSize(8)
                .extent([[vm.margin.left, vm.legendYOffset], [this.width - this.margin.right, vm.margin.top + vm.legendYOffset]])
                .on('start brush end', brushing)

            let range = [vm.timeScale(vm.filterRange[0]), vm.timeScale(vm.filterRange[1])]

            // Append brush
            const gBrush = svg.append('g')
                .attr("id", "brush-timeline")
                .call(vm.brush)
                .call(vm.brush.move, range)

            // Custom handlers
            // Handle group
            const gHandles = gBrush.selectAll('g.handles')
                .data(['handle--o', 'handle--e'])
                .enter()
                .append('g')
                .attr('class', d => `handles ${d}`)
                .attr('fill', "#55C8A9")
                .attr('transform', d => {
                    const x = d == 'handle--o' ? vm.timeScale(vm.filterRange[0]) : vm.timeScale(vm.filterRange[1]);
                    return `translate(${x}, 0)`;
                });

            // Label
            gHandles.selectAll('text')
                .data(d => [d])
                .enter()
                .append('text')
                .style("stroke", "white")
                .style("stroke-width", "3px")
                .style("paint-order", "stroke")
                .attr('text-anchor', 'middle')
                .attr('dy', vm.legendYOffset + 15)
                .attr('dx', d => d == 'handle--o' ? 30 : - 30)
                .text(function(d) {
                    return d == 'handle--o' ? vm.filterRange[0].toISOString().split("T")[0] : vm.filterRange[1].toISOString().split("T")[0]
                })

            // Visible Line
            gHandles.selectAll('.line')
                .data(d => [d])
                .enter()
                .append('line')
                .attr('class', d => `line ${d}`)
                .attr('x1', 0)
                .attr('y1', vm.legendYOffset - 5)
                .attr('x2', 0)
                .attr('y2', vm.margin.top + vm.legendYOffset + 5)
                .attr("stroke-width", 5)
                .attr('stroke', "#55C8A9");
        }
    },
    watch: {
        mkey(val, prev) {
            if (val !== prev) {
                this.width = document.getElementById('timeline-container').offsetWidth
                //this.height = document.getElementById('timeline-container').clientHeight
                this.height = document.getElementById('map-root-1').clientHeight
                //TODO resize would be better and height is not always correct as it uses the one that was wrong computed based on the old height of the timeline
                d3.select("#timeline").remove()
                this.prepareData(this.getDates)
            }
        }
    }
}
</script>

<style scoped>
.timeline-container{
    height: 100%;
    position: relative;
    border: 1px solid black;
    box-sizing: border-box;
    background-color: white;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    overflow: hidden;
}
#timeline {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
}

svg .selection, svg .overlay {
    fill: #55C8A9 !important;
    rx: 8;
    ry: 8;
}
svg .selection {
    fill-opacity: 0.8;
    stroke: none;
}
svg .overlay {
    opacity: 0.2;
    pointer-events: none;
}
#filter-menu {
    padding-bottom: 25px;
    overflow-x: auto;
    display: inline-block;
    white-space: nowrap;
}


</style>
