<template>
    <div>
        <v-overlay absolute v-if="loading"> loading ...</v-overlay>
        <button v-if="!station_type" class="button" @click="getData">Load Data</button>
        <div class="content-header">
            <v-select v-if="!station_type"
                      label="Sensor Type"
                      variant="underlined"
                      v-model="selectedOption"
                      :items="options"
            ></v-select>
            <v-select v-if="options_units.length > 1"
                      label="1. Achse"
                      variant="underlined"
                      v-model="selectedAxis"
                      :items="options_units"
            ></v-select>
            <v-switch @change="changePointsVisibility" :v-model="showCircle" label="ShowPoints"></v-switch>
            <ul class="content-ul">
                <li>
                    <span ref="year" class="glow" style="font-size:26px">{{currentYear}}</span><br>
                    <span ref="month" style="font-size:18px">{{currentMonth}}</span><br>
                    <span ref="week" style="font-size:14px">{{currentWeek}}</span>
                    <br>
                    <br>
                    <input ref="datepicker" type="date" v-model="date" @change="dateChanged">
                </li>
            </ul>
            <v-select v-if="!station_type"
                      label="Location"
                      variant="underlined"
                      v-model="selectedLoc"
                      :items="options_loc"
            ></v-select>
            <v-select v-if="options_units.length > 1"
                      label="2. Achse"
                      variant="underlined"
                      v-model="selectedAxis2"
                      :items="options_units"
            ></v-select>
        </div>
        <div id='linechart-container'>
        </div>
    </div>
</template>

<script>
import * as d3 from 'd3'
import 'datejs'
import {mapActions, mapGetters} from "vuex";

/*
Brush & Zoom area chart block to work with mulit-line charts.
Combining d3-brush and d3-zoom to implement Focus + Context.

The focus chart is the main larger one where the zooming occurs.
The context chart is the smaller one below where the brush is used to specify a focused area.
*/

export default {
    name: 'LineChart',
    props: ["station_type", "feature"],
    data () {
        return {
            focusChartMargin: { top: 30, right: 60, bottom: 170, left: 80 },
            contextChartMargin: { top: 360, right: 60, bottom: 90, left: 80 },
            loading: false,
            MS_PER_DAY: 86400000,
            MS_PER_HOUR: 3600000,
            MS_PER_YEAR: 31556736000,
            width: 900,
            height: 500,
            showCircle: false,
            currentYear: '',
            currentMonth: '',
            currentWeek: '',
            firstDateOfWeek: '',
            lastDateOfWeek: '',
            colorScale: null,
            colors: ["#1f78b4","#33a02c","#e31a1c","#ff7f00","#6a3d9a","#ffff99","#b15928","#a6cee3","#b2df8a","#fb9a99","#fdbf6f", "#cab2d6"],
            options: ["Pegel", "Luft", "Wetter", "Niederschlag", "Solar"],
            selectedOption: null,
            selectedLoc: null,
            options_loc: [],
            options_units: [],
            selectedAxis: null,
            selectedAxis2: null,
            date: "2023-03-20",
        }
    },
    //aktuell 2 axis mit wechselnden legenden -> oder mehrere Linecharts?
    //hasStation kante (haben ein label) von warncell zu station
    //null Werte: dafür schrittweite festlegen für jeden sensor - kann anhand von Leipzig-Holzhausen und Wetter getestet werden
    //TODO bei nur einem Wert passiert aktuell nichts
    mounted () {
        let vm = this
        if (vm.station_type && vm.feature) {
            //TODO später lieber ids nehmen?
            vm.setConnection({username: "", password: ""}).then(() => {
                vm.setDate(vm.$refs.datepicker);
                vm.parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%SZ");
                switch (vm.station_type) {
                    case "uba_sensor":
                        vm.storeLoadUBAForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date,  "name":vm.feature.name}).then(() => vm.prepareUBA(vm.feature.name))
                        break
                    case "wetter_sensor":
                        vm.selectedAxis = "Temperatur"
                        vm.selectedAxis2 = "Feuchtigkeit"
                        vm.storeLoadWetterForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date,  "name":vm.feature.name}).then(() => vm.prepareWetter(vm.feature.name))
                        break
                    case "pegel_sensor":
                        vm.storeLoadPegelForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date, "name":vm.feature.name}).then(() => vm.preparePegel(vm.feature.name))
                        break
                    case "solar_sensor":
                        vm.storeLoadSolarForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date, "name":vm.feature.name}).then(() => vm.prepareSolar())
                        break
                }
            });
        }
    },
    methods: {
        ...mapActions('dwdStore', {
            setConnection: 'setConnection',
            storeLoadWetterForDate: 'loadWetterForDate',
            storeLoadUBAForDate: 'loadUBAForDate',
            storeLoadPegelForDate: 'loadPegelForDate',
            storeLoadNiederschlagForDate: 'loadNiederschlagForDate',
            storeLoadSolarForDate: 'loadSolarForDate',
        }),
        ...mapGetters('dwdStore', {
            getPegelData: 'getPegelData',
            getNiederschlagData: 'getNiederschlagData',
            getUBAData: 'getUBAData',
            getWetterData: 'getWetterData',
            getSolarData: 'getSolarData',
        }),
        getData() {
            let vm = this
            vm.setConnection({username: "", password: ""}).then(() => {
                vm.setDate(vm.$refs.datepicker);
                vm.parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%SZ");
                switch (vm.selectedOption) {
                    case "Luft":
                        vm.storeLoadUBAForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date,  "name":"Eisenhüttenstadt"}).then(() => vm.prepareUBA())
                        break
                    case "Wetter":
                        vm.selectedAxis = "Temperatur"
                        vm.selectedAxis2 = "Feuchtigkeit"
                        vm.storeLoadWetterForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date,  "name":"Leipzig/Halle"}).then(() => vm.prepareWetter())
                        break
                    case "Niederschlag":
                        vm.storeLoadNiederschlagForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date,  "name":"Leipzig/Halle"}).then(() => vm.prepareNiederschlag())
                        break
                    case "Pegel":
                        vm.storeLoadPegelForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date,  "name":"Leipzig-Thekla"}).then(() => vm.preparePegel())
                        break
                    case "Solar":
                        vm.storeLoadSolarForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date,  "name":"Leipzig-Thekla"}).then(() => vm.prepareSolar())
                        break;
                }
            });
        },
        prepareUBA(option="Eisenhüttenstadt") {
            let vm = this
            let data = vm.getUBAData()
            let names = Array.from(new Set(data.map(x => x.station_name)))

            if (data.length === 0) {
                return
            }
            /*if (data.length > 0) {
              for (let key in data[0]) {
                console.log(key, new Set(data.map(x => x[key])))
              }
            }*/


            vm.options_loc = names
            //remove duplicates
            let res = data.filter(x => x.station_name === option).filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.time === value.time
                    ))
            ).sort(function(a,b) {
                return a["time"].localeCompare(b["time"])
            })

            let stepWidth = 3600000//vm.getStepWidth(res)
            let missingDates = vm.fillData(res, stepWidth)
            res = res.concat(missingDates).sort(function(a,b) {
                return a["time"].localeCompare(b["time"])
            })

            let dates = []
            let result = {}
            let bucketNames = ["O3_value", "PM10_value", "NO2_value", "CO_value", "SO2_value", "PM10PB_value","PM10BAP_value", "CHB_value", "PM2_value", "PM10AS_value", "PM10CD_value", "PM10NI_value"] //["O3_value","PM10_value","NO2_value"];
            for (let bucket of bucketNames) {
                if (res.filter(x => x[bucket] != null).length === 0) {
                    continue
                }
                res.forEach(bucketRecord => {
                    bucketRecord["value"] = (bucketRecord[bucket] ? bucketRecord[bucket] : null)
                    dates.push(vm.parseTime(bucketRecord.time));
                });
                result[bucket] = JSON.parse(JSON.stringify(res))
            }

            let unit = "µg/m³" //res[0].unit
            //vm.options_units = [unit]
            vm.dates = dates
            // match colors to bucket name
            vm.colorScale = d3
                .scaleOrdinal()
                .domain(bucketNames)
                .range(vm.colors);

            vm.buildLineChart(result, Object.keys(result), unit, "Belastung")
        },
        prepareWetter(option="Leipzig/Halle") {
            let vm = this
            let data = vm.getWetterData()
            if (data.length === 0) {
                return
            }
            let names = Array.from(new Set(data.map(x => x.name)))
            vm.options_loc = names

            //remove duplicates
            let res = data.filter(x => x.name === option).filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.time === value.time
                    ))
            ).sort(function(a,b) {
                return a["time"].localeCompare(b["time"])
            })

            let stepWidth = 600000 //vm.getStepWidth(res)
            let missingDates = vm.fillData(res, stepWidth)
            res = res.concat(missingDates).sort(function(a,b) {
                return a["time"].localeCompare(b["time"])
            })

            let dates = []
            let result = {}
            let secondAxisValues = {}
            let unit = "°C"
            let bucketNames = ["temperature_2m","temperature_5cm","dew_point_2m"]
            let secondValues = ["rel_humidity_2m"]
            secondAxisValues["unit"] = "%"
            switch (vm.selectedAxis) {
                case "Feuchtigkeit":
                    bucketNames = ["rel_humidity_2m"]
                    unit = "%"
                    break
                case "Druck":
                    bucketNames = ["pressure"]
                    unit = "hpa"
                    break
            }
            switch (vm.selectedAxis2) {
                case "Temperatur":
                    secondValues = ["temperature_2m","temperature_5cm","dew_point_2m"]
                    secondAxisValues["unit"] = "°C"
                    break
                case "Druck":
                    secondValues = ["pressure"]
                    secondAxisValues["unit"] = "hpa"
                    break
            }
            for (let bucket of bucketNames) {
                res.forEach(bucketRecord => {
                    bucketRecord["value"] = (bucketRecord[bucket] ? bucketRecord[bucket] : null)
                    dates.push(vm.parseTime(bucketRecord.time));
                });
                result[bucket] = JSON.parse(JSON.stringify(res))
            }

            for (let bucket of secondValues) {
                res.forEach(bucketRecord => {
                    bucketRecord["value"] = (bucketRecord[bucket] ? bucketRecord[bucket] : null)
                    dates.push(vm.parseTime(bucketRecord.time));
                });
                secondAxisValues[bucket] = JSON.parse(JSON.stringify(res))

            }
            secondAxisValues["label"] = vm.selectedAxis2
            secondAxisValues["buckets"] = secondValues

            /*let bucketNames = ["temperature_2m","temperature_5cm","dew_point_2m"] //,"rel_humidity_2m", "pressure"];
            for (let bucket of bucketNames) {
              res.forEach(bucketRecord => {
                bucketRecord["value"] = (bucketRecord[bucket] ? bucketRecord[bucket] : null)
                dates.push(vm.parseTime(bucketRecord.time));
              });
              result[bucket] = JSON.parse(JSON.stringify(res))
            }

            let secondAxisValues = {}
            let secondValue = "rel_humidity_2m"
            res.forEach(bucketRecord => {
              bucketRecord["value"] = (bucketRecord[secondValue] ? bucketRecord[secondValue] : null)
              dates.push(vm.parseTime(bucketRecord.time));
            });
            secondAxisValues[secondValue] = JSON.parse(JSON.stringify(res))
            secondAxisValues["unit"] = "%"
            secondAxisValues["label"] = "Feuchtigkeit"
            secondAxisValues["buckets"] = [secondValue]

            let unit = "°C" */
            vm.options_units = ["Temperatur", "Feuchtigkeit", "Druck"]
            vm.dates = dates
            // match colors to bucket name
            vm.colorScale = d3
                .scaleOrdinal()
                .domain(bucketNames.concat(secondValues))
                .range(vm.colors);

            vm.buildLineChart(result, bucketNames, unit, "Temperatur", true, secondAxisValues)
        },
        //haufenweisen duplikate für bestimmte Tage - aber sonst auch manchmal welche
        preparePegel(option="Leipzig-Thekla") {
            let vm = this
            let data = vm.getPegelData()
            if (data.length === 0) {
                return
            }
            let names = Array.from(new Set(data.map(x => x.name)))
            vm.options_loc = names

            //remove duplicates
            let res = data.filter(x => x.name === option).filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.time === value.time
                    ))
            ).sort(function(a,b) {
                return a["time"].localeCompare(b["time"])
            })

            let stepWidth = 900000 //vm.getStepWidth(res)
            let missingDates = vm.fillData(res, stepWidth)
            res = res.concat(missingDates).sort(function(a,b) {
                return a["time"].localeCompare(b["time"])
            })

            let dates = []
            let bucketNames = ["Pegelstand"];
            res.forEach(bucketRecord => {
                dates.push(vm.parseTime(bucketRecord.time));
            });

            let result = {}
            result["Pegelstand"] = res

            let unit = res[0].unit
            //vm.options_units = [unit]
            vm.dates = dates
            // match colors to bucket name
            vm.colorScale = d3
                .scaleOrdinal()
                .domain(bucketNames)
                .range(vm.colors);

            vm.buildLineChart(result, bucketNames, unit, "Pegelstand")
        },
        prepareNiederschlag() {
            let vm = this
            let data = vm.getNiederschlagData()
            if (data.length === 0) {
                return
            }
            /* if (data.length > 0) {
                for (let key in data[0]) {
                    console.log(key, new Set(data.map(x => x[key])))
                }
            } */

            let res = data.filter(x => x.lat == 50.64935799744271).sort(function(a,b) {
                return a["time"].localeCompare(b["time"])
            })

            let dates = []
            let bucketNames = ["Niederschlag"];
            res.forEach(bucketRecord => {
                dates.push(vm.parseTime(bucketRecord.time));
            });

            let result = {}
            result["Niederschlag"] = res

            let unit = res[0].unit
            vm.dates = dates
            // match colors to bucket name
            vm.colorScale = d3
                .scaleOrdinal()
                .domain(bucketNames)
                .range(vm.colors);

            vm.buildLineChart(result, bucketNames, unit, "Niederschlag")
        },
        //TODO replace date with time in backend
        prepareSolar() {
            let vm = this
            //vm.parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%SZ");
            let data = vm.getSolarData()
            if (data.length === 0) {
                return;
            }

            let res = data.sort(function(a,b) {
                return a["time"].localeCompare(b["time"])
            })

            let stepWidth = 3600000 //vm.getStepWidth(res)
            let missingDates = vm.fillData(res, stepWidth)
            res = res.concat(missingDates).sort(function(a,b) {
                return a["time"].localeCompare(b["time"])
            })

            let dates = []
            let bucketNames = ["Scheindauer"];
            res.forEach(bucketRecord => {
                dates.push(vm.parseTime(bucketRecord.time));
            });

            let result = {}
            result["Scheindauer"] = res

            let unit = "min"
            vm.dates = dates
            // match colors to bucket name
            vm.colorScale = d3
                .scaleOrdinal()
                .domain(bucketNames)
                .range(vm.colors);
            vm.buildLineChart(result, bucketNames, unit, "Scheindauer")
        },
        getStepWidth(dates) {
            let stepWidth = Infinity
            for (let i = 0; i < dates.length -1; i++) {
                let diff = new Date(dates[i+1].time).getTime() - new Date(dates[i].time).getTime()
                if (diff > 0 && diff < stepWidth) {
                    stepWidth = diff
                }
            }
            return stepWidth
        },
        fillData(data, stepWidth) {
            let d = [],
                now = new Date(data[0].time), // first x-point
                last = new Date(data[data.length-1].time), // last x-point
                iterator = 0;
            
            while (now <= last) { // loop over all items
                // ignore offsets that are smaller than a minute e.g 23:59 instead of 00:00
                if (Math.abs(now.getTime() - new Date(data[iterator].time).getTime()) <= 60000) { //compare times
                    iterator++;
                } else {
                    // TODO ist das immer richtig oder nur aktuell? sommerzeit etc. - gibt sonst 1h offset
                    let localTime = now.getTime() + (now.getTimezoneOffset() * 60000) + (2 * 3600000) //für winterzeit -> offset winter - sommer? - war sonst ohne (2 * 3600000)
                    localTime = new Date(localTime)
                    let timeStamp =  localTime.toString("yyyy-MM-ddTHH:mm:ssZ")
                    //let timeStamp = now.toString("yyyy-MM-ddTHH:mm:ssZ")
                    d.push({"time":timeStamp, "value": null})
                }
                now = new Date(now.getTime() + stepWidth)
            }
            return d;
        },
        buildSecondAxis(focus, context, data) {
            let vm = this
            let labelName = data["label"]
            let unit = data["unit"]
            let bucketNames = data["buckets"]

            let maxYAxisValue = -Infinity;
            let minYAxisValue = 0;
            for (let key of bucketNames) {
                let maxYAxisValuePerBucket = Math.ceil(d3.max(data[key], d => d["value"]));
                maxYAxisValue = Math.max(maxYAxisValuePerBucket, maxYAxisValue);
            }

            for (let key of bucketNames) {
                let minYAxisValuePerBucket = Math.floor(d3.min(data[key].filter(x => x.value > "-273.15"), d => d["value"]));
                minYAxisValue = Math.min(minYAxisValuePerBucket, minYAxisValue);
            }


            let yFocus2 = d3.scaleLinear().range([vm.focusChartHeight, 0]);
            let yContext2 = d3.scaleLinear().range([vm.contextChartHeight, 0]);

            yFocus2.domain([minYAxisValue, maxYAxisValue]);
            yContext2.domain(yFocus2.domain());

            let yAxisFocus = d3.axisRight(yFocus2).tickFormat(d => d + " " + unit);

            focus
                .append("g")
                .attr("class", "y-axis")
                .attr("transform", "translate(" + (vm.chartWidth) + ",0)")
                .call(yAxisFocus);

            vm.yFocus2 = yFocus2
            vm.yContext2 = yContext2
            // create a line for focus chart
            vm.lineFocus2 = d3
                .line()
                .x(d => vm.xFocus(vm.parseTime(d.time)))
                .y(d => vm.yFocus2(d.value))
                .defined(function (d) { return d.value !== null; });

            // create line for context chart
            let lineContext = d3
                .line()
                .x(d => vm.xContext(vm.parseTime(d.time)))
                .y(d => vm.yContext2(d.value))
                .defined(function (d) { return d.value !== null; });

            // go through data and create/append lines to both charts
            for (let key of bucketNames) {
                let bucket = data[key];
                vm.focusChartLines
                    .append("path")
                    .datum(bucket)
                    .attr("class", "line-second context-line")
                    .attr("fill", "none")
                    .attr("stroke", d => vm.colorScale(key))
                    .attr("stroke-width", 1.5)
                    .attr("stroke-opacity", 1)
                    .attr("d", vm.lineFocus2)
                    .on("mouseover", function(event, d) {
                        d3.selectAll(".context-line").attr("stroke-opacity", 0.1)
                        d3.select(this).attr("stroke-opacity", 1)
                    })
                    .on("mouseout", function(event) {
                        d3.selectAll(".context-line").attr("stroke-opacity", 1)
                    })

                context
                    .append("path")
                    .datum(bucket)
                    .attr("class", "line")
                    .attr("fill", "none")
                    .attr("stroke", d => vm.colorScale(key))
                    .attr("stroke-width", 1.5)
                    .attr("d", lineContext);


                //missing values
                let filteredData = bucket.filter(vm.lineFocus2.defined());
                vm.focusChartLines
                    .append("path")
                    .datum(filteredData)
                    .attr("class", "line-second context-line gap-line")
                    .attr("fill", "none")
                    .attr("stroke", d => vm.colorScale(key))
                    .style("stroke-dasharray", "5")
                    .attr("d", vm.lineFocus2)

                vm.focusChartLines.selectAll(".point-" + key)
                    .data(filteredData)
                    .enter()
                    .append("circle")
                    .attr("class", "points point-" + key)
                    .attr("fill", d => vm.colorScale(key))
                    .attr("fill-opacity", (vm.showCircle ? 1 : 0))
                    .attr("r", 2)
                    .attr("cx", d => vm.xFocus(vm.parseTime(d.time)))
                    .attr("cy", d => vm.yFocus2(d.value))
                    .append("title")
                    .text(d => d.time + ": " + d.value)


                //missing values
                context
                    .append("path")
                    .datum(filteredData)
                    .attr("class", "line gap-line")
                    .attr("fill", "none")
                    .attr("stroke", d => vm.colorScale(key))
                    .style("stroke-dasharray", "5")
                    .attr("d", lineContext);


                // This places the labels to the right of each line
                vm.focusChartLines.append("text")
                    .datum(bucket)
                    .attr('class', 'label')
                    // place the ticks to the right of the chart
                    .attr('x', vm.chartWidth)
                    .attr("y", function(d) {
                        return vm.yFocus2(vm.getLastValue(d))
                    })
                    .attr("text-anchor", "end")
                    .attr('dy', '0.75em')
                    .style("stroke-width", "3px")
                    .style("paint-order", "stroke")
                    .style('fill', d => vm.colorScale(key))
                    .style("stroke", "white")
                    .style('font-family', 'sans-serif')
                    .style('font-size', 12)
                    .text(d => key)

            }

            // focus chart y label
            focus
                .append("text")
                .attr("text-anchor", "middle")
                .attr("transform", "translate(" + (vm.chartWidth + vm.focusChartMargin.right - 20) + "," + vm.focusChartHeight / 2 + ")rotate(-270)")
                .style("font-size", "18px")
                .text(labelName);

        },
        prepareAxis(focus, context, data, bucketNames, label) {
            let vm = this
            let maxYAxisValue = -Infinity;
            let minYAxisValue = 0;
            for (let key of bucketNames) {
                let maxYAxisValuePerBucket = Math.ceil(d3.max(data[key], d => d["value"]));
                maxYAxisValue = Math.max(maxYAxisValuePerBucket, maxYAxisValue);
            }

            for (let key of bucketNames) {
                // -273,15 -> remove temperatur outlier
                let minYAxisValuePerBucket = Math.floor(d3.min(data[key].filter(x => x.value > "-273.15"), d => d["value"]));
                minYAxisValue = Math.min(minYAxisValuePerBucket, minYAxisValue);
            }

            // set the height of both y axis
            let yFocus = d3.scaleLinear().range([vm.focusChartHeight, 0]);
            let yContext = d3.scaleLinear().range([vm.contextChartHeight, 0]);

            // set the width of both x axis
            let xFocus = d3.scaleTime().range([0, vm.chartWidth]);
            let xContext = d3.scaleTime().range([0, vm.chartWidth]);

            //"%Y-%m-%dT%H:%M:%SZ"
            // create both x axis to be rendered
            vm.xAxisFocus = d3
                .axisBottom(xFocus)
                .ticks(10)
                .tickFormat(d3.timeFormat("%d.%m %H:%M:%S"));
            let xAxisContext = d3
                .axisBottom(xContext)
                .ticks(10)
                .tickFormat(d3.timeFormat("%d.%m %H:%M:%S"));

            // create the one y axis to be rendered
            let yAxisFocus = d3.axisLeft(yFocus).tickFormat(d => d + " " + label);

            // add data info to axis
            xFocus.domain(d3.extent(vm.dates));
            yFocus.domain([minYAxisValue, maxYAxisValue]);
            xContext.domain(d3.extent(vm.dates));
            yContext.domain(yFocus.domain());

            // add axis to focus chart
            focus
                .append("g")
                .attr("class", "x-axis")
                .attr("transform", "translate(0," + vm.focusChartHeight + ")")
                .call(vm.xAxisFocus);
            focus
                .append("g")
                .attr("class", "y-axis")
                .call(yAxisFocus);

            context
                .append("g")
                .attr("class", "x-axis")
                .attr("transform", "translate(0," + vm.contextChartHeight + ")")
                .call(xAxisContext);

            vm.xFocus = xFocus
            vm.yFocus = yFocus
            vm.xContext = xContext
            vm.yContext = yContext

            let insertLinebreaks = function (d) {
                d = d3.timeFormat("%d.%m %H:%M:%S")(d)
                let el = d3.select(this);
                let words = d.split(' ');
                el.text('');

                for (let i = 0; i < words.length; i++) {
                    let tspan = el.append('tspan').text(words[i]);
                    if (i > 0) {
                        tspan.attr('x', 0).attr('dy', '15');
                    }
                }
            };

            focus.selectAll('.x-axis g text').each(insertLinebreaks);
            context.selectAll('.x-axis g text').each(insertLinebreaks);

        },
        createBrushZoom(focus, context, svg) {
            let vm = this

            //maybe refactor inserLineBreaks
            let insertLinebreaks = function (d) {
                d = d3.timeFormat("%d.%m %H:%M:%S")(d)
                let el = d3.select(this);
                let words = d.split(' ');
                el.text('');

                for (let i = 0; i < words.length; i++) {
                    let tspan = el.append('tspan').text(words[i]);
                    if (i > 0) {
                        tspan.attr('x', 0).attr('dy', '15');
                    }
                }
            }

            const brushed = function(event) {
                if (event.type === "zoom" || !event.sourceEvent){
                    return // ignore brush-by-zoom
                }
                let s = event.selection || vm.xContext.range();
                vm.xFocus.domain(s.map(vm.xContext.invert, vm.xContext));
                vm.focusChartLines.selectAll(".line").attr("d", vm.lineFocus);
                vm.focusChartLines.selectAll(".line-second").attr("d", vm.lineFocus2);
                focus.select(".x-axis").call(vm.xAxisFocus);
                focus.selectAll('.x-axis g text').each(insertLinebreaks);
                vm.focusChartLines.selectAll(".points").attr("cx", d => vm.xFocus(vm.parseTime(d.time)))
                //svg.select(".zoom").call(zoom.transform, d3.zoomIdentity.scale(vm.chartWidth / (s[1] - s[0])).translate(-s[0], 0));
                brushHandle
                    .attr("display", null)
                    .attr("transform", (d, i) => "translate(" + [s[i], -vm.contextChartHeight- 20] + ")");
            }

            /*const zoomed = function(event) {
              if (event.type === "brush" || !event.sourceEvent) {
                return; // ignore zoom-by-brush
              }
              let t = event.transform;
              vm.xFocus.domain(t.rescaleX(vm.xContext).domain());
              vm.focusChartLines.selectAll(".line").attr("d", vm.lineFocus);
              focus.select(".x-axis").call(vm.xAxisFocus);
              let brushSelection = vm.xFocus.range().map(t.invertX, t);
              context.select(".brush").call(brush.move, brushSelection);
              brushHandle
                .attr("display", null)
                .attr("transform", (d, i) => "translate(" + [brushSelection[i], -vm.contextChartHeight -20] + ")");
            }*/

            // build brush
            let brush = d3
                .brushX()
                .extent([
                    [0, -10],
                    [vm.chartWidth, vm.contextChartHeight],
                ])
                .on("brush end", brushed);

            // build zoom for the focus chart
            // as specified in "filter" - zooming in/out can be done by pinching on the trackpad while mouse is over focus chart
            // zooming in can also be done by double clicking while mouse is over focus chart
            /*let zoom = d3
              .zoom()
              .scaleExtent([1, Infinity])
              .translateExtent([
                [0, 0],
                [vm.chartWidth, vm.focusChartHeight],
              ])
              .extent([
                [0, 0],
                [vm.chartWidth, vm.focusChartHeight],
              ])
              .on("zoom", zoomed)
              .filter((event) => event.ctrlKey || event.type === "dblclick" || event.type === "mousedown");   */


            // add brush to context chart
            let contextBrush = context
                .append("g")
                .attr("class", "brush")
                .call(brush);

            // style brush resize handle
            let brushHandlePath = d => {
                let e = +(d.type === "e"),
                    x = e ? 1 : -1,
                    y = vm.contextChartHeight + 10;
                return (
                    "M" +
                    0.5 * x +
                    "," +
                    y +
                    "A6,6 0 0 " +
                    e +
                    " " +
                    6.5 * x +
                    "," +
                    (y + 6) +
                    "V" +
                    (2 * y - 6) +
                    "A6,6 0 0 " +
                    e +
                    " " +
                    0.5 * x +
                    "," +
                    2 * y +
                    "Z" +
                    "M" +
                    2.5 * x +
                    "," +
                    (y + 8) +
                    "V" +
                    (2 * y - 8) +
                    "M" +
                    4.5 * x +
                    "," +
                    (y + 8) +
                    "V" +
                    (2 * y - 8)
                );
            };

            let brushHandle = contextBrush
                .selectAll(".handle--custom")
                .data([{ type: "w", start: 0}, { type: "e", start: vm.chartWidth}])
                .enter()
                .append("path")
                .attr("class", "handle--custom")
                .attr("stroke", "#000")
                .attr("cursor", "ew-resize")
                .attr("d", brushHandlePath)
                .attr("transform", (d, i) => "translate(" + [d.start, -vm.contextChartHeight -20] + ")");

            // overlay the zoom area rectangle on top of the focus chart
            /*svg
              .append("rect")
              .attr("cursor", "move")
              .attr("fill", "none")
              .attr("pointer-events", "all")
              .attr("class", "zoom")
              .attr("width", vm.chartWidth)
              .attr("height", vm.focusChartHeight)
              .attr("transform", "translate(" + vm.focusChartMargin.left + "," + vm.focusChartMargin.top + ")")
              .call(zoom);*/

            contextBrush.call(brush.move, [0, vm.chartWidth]);
        },
        buildLineChart(data, bucketNames, label="%", labelName="Value", secondAxis=false, secondAxisValues={}) {
            let vm = this
            d3.select("#line-chart").remove()

            let svg = d3.select("#linechart-container")
                .append("svg")
                .attr("id", "line-chart")
                .attr("width", vm.width)
                .attr("height", vm.height)

            // width of both charts
            vm.chartWidth = vm.width - vm.focusChartMargin.left - vm.focusChartMargin.right;
            // height of either chart
            vm.focusChartHeight = vm.height - vm.focusChartMargin.top - vm.focusChartMargin.bottom;
            vm.contextChartHeight = vm.height - vm.contextChartMargin.top - vm.contextChartMargin.bottom;

            svg.append("svg")
                .attr("width", vm.chartWidth + vm.focusChartMargin.left + vm.focusChartMargin.right)
                .attr("height", vm.focusChartHeight + vm.focusChartMargin.top + vm.focusChartMargin.bottom)
                .append("g")
                .attr("transform", "translate(" + vm.focusChartMargin.left + "," + vm.focusChartMargin.top + ")");

            /* eslint-disable */
            // clip is created so when the focus chart is zoomed in the data lines don't extend past the borders
            let clip = svg.append("defs")
                .append("svg:clipPath")
                .attr("id", "clip")
                .append("svg:rect")
                .attr("width", vm.chartWidth)
                .attr("height", vm.focusChartHeight)
                .attr("x", 0)
                .attr("y", 0);

            // append the clip
            vm.focusChartLines = svg.append("g")
                .attr("class", "focus")
                .attr("transform", "translate(" + vm.focusChartMargin.left + "," + vm.focusChartMargin.top + ")")
                .attr("clip-path", "url(#clip)");

            /* eslint-enable */

            // create focus chart
            let focus = svg.append("g")
                .attr("class", "focus")
                .attr("transform", "translate(" + vm.focusChartMargin.left + "," + vm.focusChartMargin.top + ")");

            // create context chart
            let context = svg.append("g")
                .attr("class", "context")
                .attr("transform", "translate(" + vm.contextChartMargin.left + "," + (vm.contextChartMargin.top + 50) + ")");

            vm.prepareAxis(focus, context, data, bucketNames, label)

            // create a line for focus chart
            vm.lineFocus = d3
                .line()
                .x(d => vm.xFocus(vm.parseTime(d.time)))
                .y(d => vm.yFocus(d.value))
                .defined(function (d) { return d.value !== null; });

            // create line for context chart
            let lineContext = d3
                .line()
                .x(d => vm.xContext(vm.parseTime(d.time)))
                .y(d => vm.yContext(d.value))
                .defined(function (d) { return d.value !== null; });

            // go through data and create/append lines to both charts
            for (let key of bucketNames) {               
                let bucket = data[key];
                vm.focusChartLines
                    .append("path")
                    .datum(bucket)
                    .attr("class", "line context-line")
                    .attr("fill", "none")
                    .attr("stroke", d => vm.colorScale(key))
                    .attr("stroke-width", 1.5)
                    .attr("stroke-opacity", 1)
                    .attr("d", vm.lineFocus)
                    .on("mouseover", function(event, d) {
                        d3.selectAll(".context-line").attr("stroke-opacity", 0.1)
                        d3.select(this).attr("stroke-opacity", 1)
                    })
                    .on("mouseout", function(event) {
                        d3.selectAll(".context-line").attr("stroke-opacity", 1)
                    })

                //missing values
                let filteredData = bucket.filter(vm.lineFocus.defined());
                vm.focusChartLines
                    .append("path")
                    .datum(filteredData)
                    .attr("class", "line context-line gap-line")
                    .attr("fill", "none")
                    .attr("stroke", d => vm.colorScale(key))
                    .style("stroke-dasharray", "5")
                    .attr("d", vm.lineFocus)


                vm.focusChartLines.selectAll(".point-" + key)
                    .data(filteredData)
                    .enter()
                    .append("circle")
                    .attr("class", "points point-" + key)
                    .attr("fill", d => vm.colorScale(key))
                    .attr("fill-opacity", (vm.showCircle ? 1 : 0))
                    .attr("r", 2)
                    .attr("cx", d => vm.xFocus(vm.parseTime(d.time)))
                    .attr("cy", d => vm.yFocus(d.value))
                    .append("title")
                    .text(d => d.time + ": " + d.value)

                context.append("path")
                    .datum(bucket)
                    .attr("class", "line")
                    .attr("fill", "none")
                    .attr("stroke", d => vm.colorScale(key))
                    .attr("stroke-width", 1.5)
                    .attr("d", lineContext);

                //missing values
                context.append("path")
                    .datum(filteredData)
                    .attr("class", "line gap-line")
                    .attr("fill", "none")
                    .attr("stroke", d => vm.colorScale(key))
                    .style("stroke-dasharray", "5")
                    .attr("d", lineContext);


                //TODO wo platzieren? oder lieber legende?
                // This places the labels to the right of each line
                vm.focusChartLines.append("text")
                    .datum(bucket)
                    .attr('class', 'label')
                    // place the ticks to the right of the chart
                    .attr('x', vm.chartWidth)
                    .attr("y", function(d) {
                        return vm.yFocus(vm.getLastValue(d))
                    })
                    .attr("text-anchor", "end")
                    .attr('dy', '0.75em')
                    .style("stroke", "white")
                    .style("stroke-width", "3px")
                    .style("paint-order", "stroke")
                    .style('fill', d => vm.colorScale(key))
                    .style('font-family', 'sans-serif')
                    .style('font-size', 12)
                    .text(d => key)
            }

            // focus chart x label
            focus.append("text")
                .attr("transform", "translate(" + vm.chartWidth / 2 + " ," + (vm.focusChartHeight + vm.focusChartMargin.top + 25) + ")")
                .style("text-anchor", "middle")
                .style("font-size", "18px")
                .text("Time");
            // focus chart y label
            focus.append("text")
                .attr("text-anchor", "middle")
                .attr("transform", "translate(" + (-vm.focusChartMargin.left + 20) + "," + vm.focusChartHeight / 2 + ")rotate(-90)")
                .style("font-size", "18px")
                .text(labelName);

            if (secondAxis) {
                vm.buildSecondAxis(focus, context, secondAxisValues)
            }
            vm.createBrushZoom(focus, context, svg)
        },
        //TODO collision detection with other labels?
        getLastValue(d) {
            for (let i = d.length-1; i >= 0; i--) {
                if (d[i].value !== null) {
                    return d[i].value
                }
            }
            return 0
        },
        changeAxis() {
            let vm = this
            let result = {}

            let option = vm.selectedLoc
            if (vm.feature) {
                option = vm.feature.name
            }
            let data = vm.getWetterData()

            //remove duplicates
            let res = data.filter(x => x.name === option).filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.time === value.time
                    ))
            ).sort(function(a,b) {
                return a["time"].localeCompare(b["time"])
            })

            let stepWidth = vm.getStepWidth(res)
            let missingDates = vm.fillData(res, stepWidth)
            res = res.concat(missingDates).sort(function(a,b) {
                return a["time"].localeCompare(b["time"])
            })

            let dates = []
            let secondAxisValues = {}
            let unit = "°C"
            let bucketNames = ["temperature_2m","temperature_5cm","dew_point_2m"]
            let secondValues = ["rel_humidity_2m"]
            secondAxisValues["unit"] = "%"
            switch (vm.selectedAxis) {
                case "Feuchtigkeit":
                    bucketNames = ["rel_humidity_2m"]
                    unit = "%"
                    break
                case "Druck":
                    bucketNames = ["pressure"]
                    unit = "hpa"
                    break
            }
            switch (vm.selectedAxis2) {
                case "Temperatur":
                    secondValues = ["temperature_2m","temperature_5cm","dew_point_2m"]
                    secondAxisValues["unit"] = "°C"
                    break
                case "Druck":
                    secondValues = ["pressure"]
                    secondAxisValues["unit"] = "hpa"
                    break
            }
            for (let bucket of bucketNames) {
                res.forEach(bucketRecord => {
                    bucketRecord["value"] = (bucketRecord[bucket] ? bucketRecord[bucket] : null)
                    dates.push(vm.parseTime(bucketRecord.time));
                });
                result[bucket] = JSON.parse(JSON.stringify(res))
            }

            for (let bucket of secondValues) {
                res.forEach(bucketRecord => {
                    bucketRecord["value"] = (bucketRecord[bucket] ? bucketRecord[bucket] : null)
                    dates.push(vm.parseTime(bucketRecord.time));
                });
                secondAxisValues[bucket] = JSON.parse(JSON.stringify(res))

            }
            secondAxisValues["label"] = vm.selectedAxis2
            secondAxisValues["buckets"] = secondValues

            vm.dates = dates
            // match colors to bucket name
            vm.colorScale = d3
                .scaleOrdinal()
                .domain(bucketNames.concat(secondValues))
                .range(vm.colors);
            vm.buildLineChart(result, bucketNames, unit, vm.selectedAxis, true, secondAxisValues)
        },
        changePointsVisibility() {
            let vm = this
            vm.showCircle = !vm.showCircle
            d3.select("#linechart-container").selectAll(".points").attr("fill-opacity", (vm.showCircle ? 1 : 0))
        },
        dateChanged(event){
            let vm = this
            vm.setDate(event.target);
            let option = vm.selectedLoc
            if (vm.feature) {
                option = vm.feature.name
            }
            let caseOption = vm.selectedOption
            if (vm.station_type) {
                caseOption = vm.station_type
            }

            switch (caseOption) {
                case "Luft":
                    vm.storeLoadUBAForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date, "name":option}).then(() => vm.prepareUBA(option))
                    break
                case "uba_sensor":
                    vm.storeLoadUBAForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date, "name":option}).then(() => vm.prepareUBA(option))
                    break
                case "Wetter":
                    vm.storeLoadWetterForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date,  "name":option}).then(() => vm.prepareWetter(option))
                    break
                case "wetter_sensor":
                    vm.storeLoadWetterForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date,  "name":option}).then(() => vm.prepareWetter(option))
                    break
                case "Pegel":
                    vm.storeLoadPegelForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date, "name":option}).then(() => vm.preparePegel(option))
                    break
                case "pegel_sensor":
                    vm.storeLoadPegelForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date, "name":option}).then(() => vm.preparePegel(option))
                    break
                case "Solar":
                    vm.storeLoadSolarForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date, "name":option}).then(() => vm.prepareSolar())
                    break
                case "solar_sensor":
                    vm.storeLoadSolarForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date, "name":option}).then(() => vm.prepareSolar())
                    break
            }
        },
        setDate(picker){
            let vm = this
            const date = new Date(picker.value);
            vm.selectedWeekDay = date.getDay() > 0 ? date.getDay() : 7;
            const yearText = date.getFullYear().toString();
            const monthText = date.toLocaleString('default', {month: 'long'});

            vm.$refs.month.classList.remove('glow');
            vm.$refs.month.offsetHeight; // trigger animation...

            const week = vm.getWeekOfMonth(picker.value);
            if(week.mo.day.toString().length < 2){
                week.mo.day = "0" + week.mo.day;
            }
            if(week.mo.month.toString().length < 2){
                week.mo.month = "0" + week.mo.month;
            }
            if(week.su.day.toString().length < 2){
                week.su.day = "0" + week.su.day;
            }
            if(week.su.month.toString().length < 2){
                week.su.month = "0" + week.su.month;
            }
            vm.firstDateOfWeek = `${week.mo.year}-${week.mo.month}-${week.mo.day}`;
            vm.lastDateOfWeek = `${week.su.year}-${week.su.month}-${week.su.day}`;
            const weekText = `(${week.mo.day}.${week.mo.month} - ${week.su.day}.${week.su.month})`;
            if(vm.currentWeek !== weekText || vm.currentMonth !== monthText || vm.currentYear !== yearText) {
                vm.currentWeek = weekText;
                //vm.loadWeekData();
            }

            vm.currentYear = yearText;
            vm.currentMonth = monthText;
            vm.$refs.month.classList.add('glow');
        },
        getWeekOfMonth(dateString){
            const date = new Date(dateString);
            // days: 0-Sun - 6-Sat
            let day = date.getDay() || 7;
            let mon = {day: date.getDate(),  month: date.getMonth() + 1, year: date.getFullYear()};
            let sun = {day: date.getDate(),  month: date.getMonth() + 1, year: date.getFullYear()};

            if( day !== 1 ) {
                const monDate = new Date(dateString);
                monDate.setHours(-24 * (day - 1));
                mon = {day: monDate.getDate(),  month: monDate.getMonth() + 1, year: monDate.getFullYear()};
            }
            if( day !== 7 ) {
                date.setHours(+24 * (7 - day));
                sun = {day: date.getDate(),  month: date.getMonth() + 1, year: date.getFullYear()};
            }

            return {mo: mon, su: sun};
        },
    },
    watch: {
        selectedLoc(val, prev) {
            let vm = this
            if (val != prev) {
                switch (vm.selectedOption) {
                    case "Luft":
                        vm.storeLoadUBAForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date, "name":val}).then(() => vm.prepareUBA(val))
                        break
                    case "Wetter":
                        vm.storeLoadWetterForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date,  "name":val}).then(() => vm.prepareWetter(val))
                        break
                    case "Pegel":
                        vm.storeLoadPegelForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date, "name":val}).then(() => vm.preparePegel(val))
                        break
                    case "Solar":
                        vm.storeLoadSolarForDate({"first_date":vm.firstDateOfWeek, "last_date": vm.lastDateOfWeek, "date": vm.date, "name":val}).then(() => vm.prepareSolar())
                        break
                }
            }
        },
        selectedAxis(val, prev) {
            let vm = this
            if (prev && val !== prev && val !== vm.selectedAxis2) {
                vm.changeAxis()
            }
        },
        selectedAxis2(val, prev) {
            let vm = this
            if (prev && val !== prev && val !== vm.selectedAxis) {
                vm.changeAxis()
            }
        },
    }
}

</script>

<style scoped>
.content-header {
    background-color: white;
    color: var(--color-text);
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    user-select: none;
}
.content-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.glow {
    animation: glow 1.3s linear forwards;
}
@keyframes glow {
    40% {
        text-shadow: 0 0 4px #000000;
    }
}


</style>
