<template>
    <div>
        <div :id="'map-root-' + mkey"  class="map-root" ref="map-root">
            <MapLayerControl></MapLayerControl>
            <div v-if="showSolarLegend" class="solar_legend">
                <img  src="SolarLegend.png">
            </div>
        </div>
        <div :id="'popup-' + mkey" class="ol-popup">
            <a href="#" class="ol-popup-closer" @click="closePopup"></a>
            <div :id="'popup-content-' + mkey">
                <v-select v-model="selectedType" :items="layerNames" label="Type" outlined></v-select>
                <button class="button" @click="savePolygon">Save Polygon</button>
            </div>
        </div>
        <MapChildSatellites v-if="map && displaySatellites" :map="map"></MapChildSatellites>
    </div>
</template>

<script>
import View from "ol/View";
import Map from 'ol/Map'
import Overlay from 'ol/Overlay';
import Tile from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ'
import Attribution from 'ol/control/Attribution'
import ZoomSlider from 'ol/control/ZoomSlider'
import Zoom from 'ol/control/Zoom'
import {Vector} from "ol/source";
import {Vector as VectorLayer} from "ol/layer/";
import WKT from 'ol/format/WKT';

import * as jsts from 'jsts/dist/jsts.min.js'

import * as CypherQueries from "../modules/CypherQueries";
import Draw from 'ol/interaction/Draw';
import {Vector as VectorSource} from 'ol/source';
import {mapActions, mapGetters} from 'vuex'

import 'ol/ol.css'
import {OverviewMap, ScaleLine} from "ol/control";
import MapChildSatellites from "./MapChildSatellites";
//import {MapLayerControl} from "../modules/MapLayerControl";
import MapLayerControl from "./MapLayerControl";
import * as MapData from "../modules/MapData";
import {Circle, Style, Fill, Stroke} from "ol/style";
import {Feature} from "ol";
import LinearRing from 'ol/geom/LinearRing';
import {LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon, GeometryCollection} from "ol/geom";
import {_openlayers} from "@/OnlyLocalFiles/logins";

export default {
    name: "MapContainer",
    components: {MapLayerControl, MapChildSatellites},
    props: ["mkey"],
    data: () => ({
        map: null,
        displaySatellites: false,
        selectionMarker: null,
        draw: null,
        drawLayer: null,
        drawSource: null,
        drawPolygonLayer: null,
        overlay: null,
        drawnCoordinates: null,
        selectedType: null,
        layerNames: [], // TODO use store? Sollte gefiltert werden, nicht alle diese Layer sind geeigent (z.B. dwd nicht)!
        lidarBorderLayer: null,
        showSolarLegend: false,
    }),
    mounted(){
        let resizeTimer = null;
        new ResizeObserver(() => {
            // Performance!
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(()=>{
                window.dispatchEvent(new Event('resize'));
            }, 100);
        }).observe(this.$refs['map-root']);

        // overlay for popup
        this.overlay = new Overlay({
          element: document.getElementById('popup-' + this.mkey),
          autoPan: {
            animation: {
              duration: 250,
            },
          },
        });

        this.selectionMarker = this.createSelectionMarker();
        this.drawLayer = this.createDrawLayer();
        //2nd draw layer for polygon drawing - prevent interference with the other vector layer
        this.drawPolygonLayer = this.createPolygonDrawLayer();
        this.map = this.createMap();


        /*
        let bla = 0;
        const styles = [
            new Style({
                fill: new Fill({
                    color: "#fb8c007A",
                }),
                stroke: new Stroke({
                    color: "#000000",
                    width: 1,
                }),
            }),
            new Style({
                fill: new Fill({
                    color: "#FFFFFF7A",
                }),
                stroke: new Stroke({
                    color: "#000000",
                    width: 1,
                }),
            }),
            new Style({
                fill: new Fill({
                    color: "#e539357A"
                }),
                stroke: new Stroke({
                    color: "#000000",
                    width: 1,
                }),
            }),
            new Style({
                fill: new Fill({
                    color: "#e539357A"
                }),
                stroke: new Stroke({
                    color: "#cd7300",
                    width: 3,
                }),
            }),
            new Style({
                fill: new Fill({
                    color: "#880e4f7A"
                }),

            }),
            new Style({
                fill: new Fill({
                    color: "rgba(255,255,255,0.48)"
                }),

            }),
            new Style({
                fill: new Fill({
                    color: "#ffeb3b7A"
                }),

            })
        ];*/

        this.onPointerMoveFnc = function(event){
            if (!this.isDrawMode) {
                this.handleSelection(event)
            }
        }.bind(this);

        this.map.on('click', this.onPointerMoveFnc);
        this.map.addLayer(MapData.addSelectionLayer(this.uid));

        // add event listener for recieving data from potree for map manipulation
        window.addEventListener("message", (evt) => {
            if(evt.data.command === "Potree_EarthControls_moved"){
                // console.info("Received message from Potree!");
                // console.info(evt.data.latitude);
                // console.info(evt.data.longitude);
                const point = new Point([evt.data.longitude,evt.data.latitude]);
                point.transform('EPSG:4326','EPSG:3857');
                // now set the new view center, but keep the zoom ratio intact
                const view = new View({
                    zoom: this.map.getView().values_.zoom,
                    center: point.getFlatCoordinates(),
                    constrainResolution: true
                });
                view.on('change:center', function(evt){
                    this.setViewCenter(evt.target.getCenter());
                }.bind(this));
                this.map.setView(view);


            }
        });

        // remove current drawing when leaving the map
        this.map.getViewport().addEventListener('mouseout', function(){
            if(this.draw) {
                this.draw.abortDrawing();
            }
        }.bind(this), false);

        this.triggerDrawMode(this.isDrawMode)
    },
    destroyed() {
        MapData.removeSelectionLayer(this.uid);
    },
    computed: {
        ...mapGetters({
            isDrawMode: 'componentsStore/getDrawMode',
            getView: 'mapStore/getView',
            isIntersectMode: 'componentsStore/getPolygonIntersect',
            getLidarBorder: 'mapStore/getLidarBorder',
        }),
        /*layerNames() { //not possible because layerData has not been loaded at this point
            let layers = CypherQueries.getLayerData();
            if (!layers || !layers["map"]) {
                return []
            }
            return Object.keys(layers["map"])
        }*/
    },
    methods: {
        ...mapActions({
            setView: 'mapStore/setView',
            setViewCenter: 'mapStore/setViewCenter',
            setMap: 'mapStore/setMap',
            savePolygonToDB: "neo4jStore/savePolygonInDB",
            setLoading: "neo4jStore/setLoading",
            addFeatureToSelection: "mapStore/addFeatureToSelection",
            clearSelectedFeatures: "mapStore/clearSelectedFeatures"
        }),
        createSelectionMarker(){
            return new VectorLayer({
                source: new Vector({
                    features: [],
                    wrapX: false,
                    zIndex: 200,
                })
            });
        },
        createDrawLayer() {
            return new VectorLayer({
              source: new VectorSource({wrapX: false})
            });
        },
        createPolygonDrawLayer() { 
            this.drawSource = new VectorSource({wrapX: false})
            return new VectorLayer({
              source: this.drawSource,
            });
        },        
        closePopup() {
            //close the popup
            this.overlay.setPosition(undefined);
            // not follow the link
            this.drawLayer.getSource().clear();
            return false;
        },
        // TODO add delete polygon (only the ones drawn)
        // TODO add extra legend entry to select/deselect all drawn polygon 
        savePolygon() {
            this.setLoading(true);
            this.savePolygonToDB({layerName: this.selectedType, wkt: this.drawnCoordinates}).then(data => {
                // close the popup
                this.overlay.setPosition(undefined);
                this.setLoading(false);
                this.drawLayer.getSource().clear();
                // select feature
                const selection = {};
                selection[this.selectedType] = {layer: data.layer, features: [data.feature], counter: 1};
                MapData.setSelectedFeatures(selection);
                // TODO: eleganter lösen (ohne Parents)!
                this.$parent.$parent.$refs['infoPanel'].displayFeatures(selection);
            });
        },
        computePolygonIntersection(polygon) {
            const parser =  new jsts.io.OL3Parser()
            parser.inject(
              Point,
              LineString,
              LinearRing,
              Polygon,
              MultiPoint,
              MultiLineString,
              MultiPolygon,
              GeometryCollection
            );
            let mapLayers = this.map.getAllLayers().filter(layer => layer.get('name') && !layer.get('name').startsWith('___'))
            let selectedFeatures = {};
            for (let l of mapLayers) {
              const layerName = l.get('name');
              let features = l.getSource().getFeatures();
              for (var i = 0 ; i < features.length; i++){
                //extent uses bb -> TODO check if faster without
                if(polygon.intersectsExtent( features[i].getGeometry().getExtent() )){
                    // check if they really intersect or only the bb
                    let polygon1Jsts = parser.read(polygon);
                    let polygon2Jsts = parser.read(features[i].getGeometry()); 
                    let intersect = polygon1Jsts.intersects(polygon2Jsts)
                    if (intersect) {
                        if(selectedFeatures[layerName]){
                            selectedFeatures[layerName].features.push(features[i]);
                            selectedFeatures[layerName].counter += 1;
                        } else {
                            selectedFeatures[layerName] = {layer: l, features: [features[i]], counter: 1};
                        }
                    }
                }
              }
            }
            return selectedFeatures
        },
        computePolygonContains(polygon) {
            const parser =  new jsts.io.OL3Parser()
            parser.inject(
              Point,
              LineString,
              LinearRing,
              Polygon,
              MultiPoint,
              MultiLineString,
              MultiPolygon,
              GeometryCollection
            );

            let polygon1Jsts = parser.read(polygon);
            let mapLayers = this.map.getAllLayers().filter(layer => layer.get('name') && !layer.get('name').startsWith('___'))
            let selectedFeatures = {};
            for (let l of mapLayers) {
              const layerName = l.get('name');
              let features = l.getSource().getFeatures();
              for (var i = 0 ; i < features.length; i++){
                let polygon2Jsts = parser.read(features[i].getGeometry()); 
                let containsPolygon = polygon1Jsts.contains(polygon2Jsts)
                if(containsPolygon){
                    if(selectedFeatures[layerName]){
                        selectedFeatures[layerName].features.push(features[i]);
                        selectedFeatures[layerName].counter += 1;
                    } else {
                        selectedFeatures[layerName] = {layer: l, features: [features[i]], counter: 1};
                    }
                }
              }
            }
            return selectedFeatures
        },
        //TODO add clear button?
        addInteraction() {
            this.draw = new Draw({
              source: this.drawSource,
              type: "Polygon",
            });

            this.draw.on('drawend', function(event) {
              let polygon = event.feature.getGeometry();
              let coords = polygon.getLastCoordinate()
              let ip = polygon.getInteriorPoint().getCoordinates()

              let layers = CypherQueries.getLayerData();
              this.layerNames = Object.keys(layers["map"])

              //create WKT String
              const format = new WKT();
              this.drawnCoordinates =  format.writeGeometry(polygon.clone().transform("EPSG:3857", "EPSG:4326")); //polygon.getCoordinates()

              // Polygon multi-select
              // TODO Updating selected features in real time? -> http://dafyddprys.github.io/2016/02/10/select-features-in-openLayers-using-polygon-draw.html
              let selectedFeatures = {}
                if (this.isIntersectMode) {
                    selectedFeatures = this.computePolygonIntersection(polygon)
                } else {
                    selectedFeatures = this.computePolygonContains(polygon)
                }
              //TODO wie mit clicked coordinates umgehen?
              MapData.setClickedCoordinates(polygon.getFirstCoordinate()); //getLastCoordinate()
              MapData.setSelectedFeatures(selectedFeatures);

              //create popup
              this.overlay.setPosition(coords);
              // TODO: eleganter lösen (ohne Parents)!
              this.$parent.$parent.$refs['infoPanel'].displayFeatures(selectedFeatures);
            }.bind(this));

            this.map.addInteraction(this.draw);
            //TODO Undo last point button? this.draw.removeLastPoint();
        },
        handleSelection(event) {
            const selectedFeatures = {};
            this.clearSelectedFeatures();
            this.map.forEachFeatureAtPixel(event.pixel,
                (feature, layer) => {
                    const layerName = layer.get('name');
                    if(selectedFeatures[layerName]){
                        selectedFeatures[layerName].features.push(feature);
                        selectedFeatures[layerName].counter += 1;
                    } else {
                        selectedFeatures[layerName] = {layer: layer, features: [feature], counter: 1};
                    }
                    this.addFeatureToSelection(feature);
                },
                { layerFilter: (layer) => {
                        return (layer.get('name') && !layer.get('name').startsWith('___'));
                    }, hitTolerance: 6 }
            );

            MapData.setClickedCoordinates(event.coordinate);
            MapData.setSelectedFeatures(selectedFeatures);

            const pointer = new Feature();
            pointer.setGeometry(new Point([event.coordinate[0], event.coordinate[1]]));
            pointer.setStyle(new Style({
                image: new Circle({
                    radius: 10,
                    fill: null,
                    stroke: new Stroke({
                        color: '#000000',
                        lineDash: [6, 6],
                        width: 2
                    })
                })
            }));
            this.selectionMarker.getSource().clear();
            this.selectionMarker.getSource().addFeature(pointer);

            // TODO: eleganter lösen (ohne Parents)!
            this.$parent.$parent.$refs['infoPanel'].displayFeatures(selectedFeatures);
        },
        createMap(){
            if(this.getView === null){
                const view = new View({
                  zoom: 9,
                  center: [1320058.4144450529, 6674947.781448813],
                  constrainResolution: true
                });
                view.on('change:center', function(evt){
                    this.setViewCenter(evt.target.getCenter());
                }.bind(this));
                this.setView(view);
                this.setViewCenter(view.getCenter());
            }

            // TODO: lidarBorderLayer in mapStore? -> Single layer, not multiple ones (Layer, Feature, EventListener)
            this.lidarBorderLayer = new VectorLayer({
                source: new Vector({
                    features: [],
                    wrapX: false,
                }),
                name: '___lidarBorder',
                defaultStyle: new Style({
                    fill: new Fill({
                        color: "rgba(255,98,26,0)"
                    }),
                    stroke: new Stroke({
                        color: '#0c18ab',
                        width: 1
                    })
                }),
            });

            let map = new Map({
                target: this.$refs['map-root'],
                layers: [
                    // adding a background tiled layer
                    new Tile({
                        source: new XYZ({
                            url: _openlayers.url,
                            wrapX: false
                        })
                    }),
                    this.selectionMarker,
                    // shows polygon after drawend
                    this.drawLayer,
                    this.drawPolygonLayer,
                    this.lidarBorderLayer
                ],
                overlays: [this.overlay],
                // the map view will initially show the whole world
                view: this.getView,
                controls: [
                    new Attribution({collapsible: true}),
                    new ZoomSlider(),
                    new Zoom(),
                    new ScaleLine(),
                    new OverviewMap({
                        layers: [
                            new Tile({
                                source: new XYZ({
                                    url: _openlayers.url,
                                    wrapX: false
                                }),
                            }),
                        ],
                        view: new View({
                            center: [1320058.4144450529, 6674947.781448813],
                            //projection: 'EPSG:4326',
                            //center: [14.0197, 51.536539],
                        })
                    }),
                    //new MapLayerControl(),
                ]
            });

            //TODO only one ref map
            this.setMap(map)

            return map
        },
        triggerDrawMode(val) {
            if (val) {
                this.addInteraction()
                this.selectionMarker.getSource().clear();
                //this.map.removeLayer(this.uid)
                //MapData.removeSelectionLayer(this.uid);
            } else {
                this.map.removeInteraction(this.draw);
                //this.map.addLayer(MapData.addSelectionLayer(this.uid));
            }
        }
    },
    watch: {
        isDrawMode(val) {
            // is triggered whenever the store state changes
            this.triggerDrawMode(val)
        },
        getLidarBorder(val){
            const wkt = "POLYGON((" + val + "))";
            const feature = new WKT().readFeature(wkt, {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857',
            });

            feature.setStyle(this.lidarBorderLayer.get('defaultStyle'));
            //feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');

            this.lidarBorderLayer.getSource().clear();
            this.lidarBorderLayer.getSource().addFeature(feature);
        }
    }
}
</script>

<style scoped>
    .map-root{
        height: 100%;
        position: relative;
        border: 1px solid black;
        box-sizing: border-box;
        background-color: white;
    }
    .ol-popup {
        position: absolute;
        background-color: white;
        box-shadow: 0 1px 4px rgba(0,0,0,0.2);
        padding: 15px;
        border-radius: 10px;
        border: 1px solid #cccccc;
        bottom: 12px;
        left: -50px;
        min-width: 280px;
      }
      .ol-popup:after, .ol-popup:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      .ol-popup:after {
        border-top-color: white;
        border-width: 10px;
        left: 48px;
        margin-left: -10px;
      }
      .ol-popup:before {
        border-top-color: #cccccc;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
      }
      .ol-popup-closer {
        text-decoration: none;
        position: absolute;
        top: 2px;
        right: 8px;
      }
      .ol-popup-closer:after {
        content: "✖";
      }
      .solar_legend{
          position: absolute;
          right: 10px;
          bottom: 10px;
          z-index: 1000;
          margin: 0;
          padding: 0;
      }
</style>