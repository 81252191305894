
async function searchInDocuments(searchStr, from, size){
    //const url = "http://seneca.informatik.uni-leipzig.de:9200/mitteldt/_search";
    const url = "https://elastic.vda-group.de/mitteldt/_search";
    //const url = "http://localhost:9200/mitteldt/_search";

    const details = {
        "from": from,
        "size": size,
        "query":
            {
                "bool":
                    {
                        "must":
                            {
                                "query_string":
                                    {
                                        "fields": ["text"],
                                        "query": searchStr
                                    }

                            }
                    }
            },
        "_source": ["filename", "page"],
        "highlight": {
            "fields": {
                "text": {}
            },
            "boundary_scanner": "word",
            "type": "plain",
            "fragment_size": 300,
            "pre_tags": "<mark>",
            "post_tags": "</mark>"
        }
    };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': "application/json",
        },
        body: JSON.stringify(details)
    };

    return await fetch(url, options).then(async response => {
        return await response.json();
    }).catch(e => {
        return {error: e};
    });
}

export {searchInDocuments}