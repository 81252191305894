<template>
    <button class="button parentButton" @click="triggerEvent" :disabled="isLoading || isDisabled">
        <span>{{caption}}</span>
        <span class="loading" :class="{loadingActive: isLoading}"></span>
    </button>
</template>

<script>

export default {
    name: "LoadingButton",
    props: {
        isLoading: {
            type: Boolean,
            required: true,
        },
        caption: {
            type: String,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    methods: {
        triggerEvent() {
            this.$emit('onLoadingButtonClicked');
        }
    }
}

</script>

<style scoped>
.loading{
    width: 0;
    /*width: 15px;*/
    height: 15px;
    box-sizing: border-box;
    animation: ex 2s linear infinite;
    display: inline-block;
}
.loadingActive {
    left: 5px;
    position: relative;
    width: 15px;
    border: 2px solid black;
    border-left-color: transparent;
    border-radius: 50%;
    transition-duration: 0.3s;
    animation: loading 2s linear infinite;
}
@keyframes loading {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.parentButton{
    width: fit-content;
    display: inline-flex;
    /*gap: 5px;*/
    align-items: center;
}
.button:disabled {
    background-color: gray;
    pointer-events: none;
}
</style>