<template>
    <div class="mainDiv">
        <div class="divButtons">
            <button class="button" @click="displayMap">Toggle Map</button>
            <button class="button" @click="displaySelection">Show Selection</button>
            <button class="button" @click="toogleClassView">Switch Classification</button>
        </div>
        <!-- src="http://localhost:3000/dynamic/mitteldeutschland/dsm/2019_class?colormap=spectral&photostakenin=2019&map" -->
        <!-- v-bind:src='mainURL + "/dynamic/mitteldeutschland/dsm/2019_class?colormap=spectral&photostakenin=2019&map"' -->
        <iframe id="potree" class="iframe"
                v-bind:src='mainURL + "/dynamic/mitteldeutschland/dsm/2019_class?colormap=spectral&photostakenin=2019&map"'
                ref="iframe"
                @load="onLoad"

        >
        </iframe>
    </div>
</template>

<script>
import * as MapData from "../modules/MapData";
import {Feature} from "ol";
import {Point} from "ol/geom";
import {mapGetters} from "vuex";
export default {
    name: "LidarMapContainer",
    data: () => ({
        iframe: null,
        mapIsShown: false,
        classView: false,
        mainURL: "https://potree.vda-group.de",
        //mainURL: "http://localhost:3000",
    }),
    computed: {
        ...mapGetters({
            selectedFeatures: 'mapStore/getSelectedFeatures',
            viewCenter: 'mapStore/getViewCenter',
        }),
        getSelectedFeatures(){
            return this.selectedFeatures;
        },
        getViewCenter(){
            return this.viewCenter;
        }
    },
    watch: {
        getSelectedFeatures(nValue, oValue) {
            const neu = Object.keys(nValue);
            const alt = Object.keys(oValue);
            this.clearSelection(alt.filter(value => !neu.includes(value)));
            this.displaySelection(nValue);
        },
        getViewCenter(nValue, oValue){
            this.moveMapToPosition(nValue);
        },
    },
    methods: {
        onLoad(){
            this.iframe = this.$refs.iframe;
            //this.setColorMap("SPECTRAL");
            //this.setColorMap("GRAYSCALE");
        },
        setColorMap(map){
            const contentWindow = this.iframe.contentWindow;
            contentWindow.postMessage({
                command: "colormap",
                gradient: map,
            }, this.mainURL);
        },
        displayMap(){
            if(!this.iframe){
                return
            }
            const contentWindow = this.iframe.contentWindow;
            if(this.mapIsShown) {
                contentWindow.postMessage({command: "remove_map",}, this.mainURL);
                this.mapIsShown = false;
            } else {
                contentWindow.postMessage({command: "map",}, this.mainURL);
                this.mapIsShown = true;
            }
        },
        moveMapToPosition(position){
            if(!this.iframe){
                return;
            }
            const point = new Point(position);
            point.transform('EPSG:3857', 'EPSG:4326');
            const contentWindow = this.iframe.contentWindow;
            contentWindow.postMessage({
                /*
                command: "coordinates",
                mapOrigin: [point.flatCoordinates[1], point.flatCoordinates[0]],
                id: "13",
                jump: true,
                 */
                command: "set_camera",
                x: point.flatCoordinates[1],//flatCoordinates[0],
                y: point.flatCoordinates[0],//flatCoordinates[1],
                z: 0.5,
                fixedAngle: true,
            }, this.mainURL);
            /*
            contentWindow.postMessage({
                command: "remove_coordinates",
                id: "13",
            }, "http://localhost:3000");
            contentWindow.postMessage({
                command: "set_camera",
                z: 0.5,
            }, "http://localhost:3000");
             */
        },
        displaySelection: function () {
            if (!this.iframe) {
                return;
            }
            const contentWindow = this.iframe.contentWindow;
            const data = MapData.getSelectedFeatures();

            Object.keys(data).forEach(key => {
                const rgb = data[key].layer.getProperties().defaultColor.replaceAll("rgba(", "").replaceAll(")", "").replaceAll(" ", "").split(",");
                rgb[0] = parseInt(rgb[0]).toString(16);
                rgb[1] = parseInt(rgb[1]).toString(16);
                rgb[2] = parseInt(rgb[2]).toString(16);
                rgb[0].length === 1 ? rgb[0] = "0" + rgb[0] : null;
                rgb[1].length === 1 ? rgb[1] = "0" + rgb[1] : null;
                rgb[2].length === 1 ? rgb[2] = "0" + rgb[2] : null;
                const color = "#" + rgb[0] + rgb[1] + rgb[2];
                data[key].features.forEach(f => {
                    const feature = new Feature(f.getGeometry().clone()); // Feature nicht benötigt, Geometry reicht aus!
                    feature.getGeometry().transform('EPSG:3857', 'EPSG:4326');

                    const coords = feature.getGeometry().getCoordinates();

                    contentWindow.postMessage({
                        command: "coordinates",
                        mapOrigin: coords,
                        id: f.getId(),
                        color: color,
                        lineWidth: 5,
                        lineHeight: 350,
                        dynamic: false,
                    }, this.mainURL);
                });
            });
        },
        clearSelection(oldIDs){
            if(!this.iframe){
                return;
            }
            const contentWindow = this.iframe.contentWindow;
            oldIDs.forEach(id => {
                contentWindow.postMessage({
                    command: "remove_coordinates",
                    id: id,
                }, this.mainURL);
            });
        },
        toogleClassView(){
            if(!this.iframe){
                return;
            }
            const contentWindow = this.iframe.contentWindow;
            if(this.classView){
                contentWindow.postMessage({
                    command: "regular",
                }, this.mainURL);
            } else {
                contentWindow.postMessage({
                    command: "classification",
                    reduced: false,
                }, this.mainURL);
            }
            this.classView = !this.classView;
        }
        /*bla(arr){
            if (arr instanceof Array && arr[0] instanceof Array) {
                arr.forEach(a => {
                    this.bla(a);
                });
            } else {
                const long = arr[0];
                arr[0] = arr[1];
                arr[1] = long;
            }
        },
        */
    }
}
</script>

<style scoped>
.mainDiv{
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    box-sizing: border-box;
    background-color: white;
}
.divButtons{
    position: absolute;
    right: 15px;
    bottom: 15px;
    z-index: 100;
}
.iframe{
    height: 100%;
    width: 100%;
}
</style>