<template>
    <div class="splitPanel" ref="parent">
        <div class="first" ref="first">
            <slot  name="first"></slot>
        </div>
        <div class="separator" ref="separator"></div>
        <div class="second" ref="second">
            <slot name="second"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "SplitPanel",
    mounted(){
        (function(){
            const parent = this.$refs['parent'];
            const first  = this.$refs['first'];
            const second = this.$refs['second'];
            const separator = this.$refs['separator'];
            let firstW = null;
            let secondW = null;
            let mousePosX = 0;
            let parentW = parent.clientWidth;

            const minWidthFirst = parseInt(window.getComputedStyle(first).getPropertyValue('min-width').replace("px", ""));
            const minWidthSecond = parseInt(window.getComputedStyle(second).getPropertyValue('min-width').replace("px", ""));

            separator.onmousedown = onMouseDown;
            new ResizeObserver(onResize.bind(this)).observe(parent);

            function onMouseDown(e){
                firstW = first.clientWidth;
                secondW = second.clientWidth;
                mousePosX = e.clientX;

                document.onmousemove = onMouseMove;
                document.onmouseup = () => {
                    document.onmousemove = document.onmouseup = null;
                }
            }

            function onMouseMove(e){
                let diff = mousePosX - e.clientX;
                const firstWidth = firstW - diff;
                const secondWidth = secondW + diff;

                if(firstWidth >= minWidthFirst && secondWidth >= minWidthSecond){
                    first.style.width = (100*firstWidth)/parentW + '%';
                    second.style.width = 'calc(' + parentW + 'px - ' + (100*firstWidth)/parentW + '% - ' + separator.clientWidth + 'px)';
                }

                const rect = separator.getBoundingClientRect();
                const spaceLeft = first.getBoundingClientRect().left;
                const mid = (rect.left - spaceLeft) + rect.width / 2;
                separator.dataset.mid = (mid * 100 / parentW).toString();
            }

            function onResize(){
                parentW = parent.clientWidth;

                if(separator.dataset && separator.dataset.mid){
                    const mid = separator.dataset.mid;
                    const sepW = separator.clientWidth;

                    let firstWidth = ((mid * parentW) / 100 - sepW / 2);
                    let secondWidth = (((100 - mid) * parentW) / 100 - sepW / 2);

                    if(secondWidth < minWidthSecond){
                        secondWidth = minWidthSecond;
                        firstWidth -= minWidthSecond - secondWidth;
                    }

                    if(firstWidth < minWidthFirst){
                        firstWidth = minWidthFirst;
                        secondWidth -= minWidthFirst - firstWidth;
                    }

                    first.style.width = firstWidth + 'px';
                    second.style.width = secondWidth + 'px';
                }
            }

        }).call(this);
    }
}
</script>

<style scoped>
.splitPanel {
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    height: 100%;
}

.separator {
    cursor: col-resize;
    mask-image: url("../../public/splitter.svg");
    background-color: var(--color-primary);
    mask-repeat: no-repeat;
    mask-position: center;

    width: 10px;
    max-width: 10px;
    min-width: 10px;
    height: 100%;

    /* Prevent the browser's built-in drag from interfering */
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.separator:hover {
    background-color: var(--color-secondary);
}

.first {
    width: 100%;
    min-width: 100px;
}

.second {
    min-width: 100px;
    width: 560px;
    box-sizing: border-box;
    height: 100%;
}

</style>