<template>
    <div>
        <button class="button" @click="sendMessageToIFrame">Send Message to IFrame</button>
        <iframe class="iframe"
                v-bind:src='"http://localhost:8080/IFrameTestPage.html"'
                ref="iframe"
                @load="onLoad"

        >
        </iframe>
        <br>
        <br>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "IFrameTest",
    components: {},
    data: () => ({
        iframe: null,
    }),
    computed: {

    },
    mounted() {
        // TODO: Zentrale Stelle für 'message'-Events wäre gut, da so übersichtlicher, wer alles darauf reagiert! (z.B. main.js?)
        // Listener nur ein einziges Mal hinzufügen!
        // API darf keine commands haben, die gleich heißen! (suff- oder präfixe nutzen?)
        window.addEventListener("message", (evt) => {
            if(evt.data.command === "msgReceived"){
                console.info("Nachricht vom Child: " + evt.data.text);
            } else if(evt.data.command === "setLidarBorder"){
                this.setLidarBorder(evt.data.border);
            }
        });
    },
    methods: {
        ...mapActions('mapStore', [
            'setLidarBorder'
        ]),
        onLoad(){
            this.iframe = this.$refs.iframe;
        },
        sendMessageToIFrame(){
            const contentWindow = this.iframe.contentWindow;
            contentWindow.postMessage({
                command: "addTextMSG",
                text: "Das ist eine Nachricht!",
            });
        },
    },
}
</script>

<style scoped>
.iframe{
    height: 100%;
    width: 100%;
    border: none;
}
</style>